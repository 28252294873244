import { useEffect, useState } from 'react'
import { useConnectWallet } from '@web3-onboard/react'
import type {
  TokenSymbol
} from '@web3-onboard/common'
import injectedModule from '@web3-onboard/injected-wallets'
import { Button } from 'react-bootstrap'
import { useMainContext } from '../../context/mainContext'
import axios from "axios";
import { toast } from "react-toastify";

interface Account {
  address: string,
  balance: Record<TokenSymbol, string> | null,
  ens: { name: string | undefined, avatar: string | undefined }
}

const injected = injectedModule()

const wallets =[injected];

const ConnectWallet: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const { setSigninModal, setSignupModal, userSignIn, setBalance } = useMainContext();
  const [account, setAccount] = useState<Account | null>(null)

  let linkData: any;

    let link = window.location.href;
    let url = new URL(link);
    let params = new URLSearchParams(url.search);
    let ref_id = params.get('ref_id');
    let campaignId = params.get('campaign_id');
    let param1 = params.get('param1');
    let param2 = params.get('param2');
    let param3 = params.get('param3');
    
    const _linkParams = JSON.parse(window.localStorage.getItem('linkParams')!);
    if(_linkParams?.ref_id !== "" && _linkParams?.ref_id !==null) {
        linkData = _linkParams;
    }


  // useEffect(() => {
  //   if (wallet?.provider) {

  //     const { name, avatar } = wallet?.accounts[0].ens ?? {}
  //     setAccount({
  //       address: wallet.accounts[0].address,
  //       balance: wallet.accounts[0].balance,
  //       ens: { name, avatar: avatar?.url }
  //     })
  //   }
  // }, [wallet]);

  useEffect(() => {
    const token = window.localStorage.getItem('token');
    console.log(wallet)
    if (wallet?.provider && connecting) {
      console.log("---------------------------------------------------------------------------------------------------")
      const { name, avatar } = wallet?.accounts[0].ens ?? {}
      setAccount({
        address: wallet.accounts[0].address,
        balance: wallet.accounts[0].balance,
        ens: { name, avatar: avatar?.url }
      })
      MMSignIn();
      return
    } 

    if(!token) {
      disconnect({ label: "MetaMask" });    
    }
  }, [wallet]);

  const MMSignIn = async () => {
    const body = {
      wallet: wallet?.accounts[0].address,
      ref_id: ref_id === null ? linkData?.ref_id : ref_id,
            campaign_id: campaignId === null ? linkData?.campaignId : campaignId,
            param1: param1 === null ? linkData?.param1 : param1,
            param2: param2 === null ? linkData?.param2 : param2,
            param3: param3 === null ? linkData?.param3 : param3,
    };
    await axios.post(`${process.env.REACT_APP_API_URL}/client/auth/metamask/`, body)
      .then(function (response) {

        if (response.data.error_code === 0) {
          toast.success("Welcome to BitNSwap!");
          userSignIn(response.data.token, response.data.information);
          
          setBalance(response.data.balances)
          setSigninModal(false);
          setSignupModal(false)
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  if (wallet?.provider && account) {
    return (
      <Button disabled={connecting} onClick={() => { disconnect({ label: wallet.label }) }} className='bg-transparent border-0 py-0'>{children}</Button>
    )
  }

  return (
    <Button disabled={connecting} onClick={() => { connect() }} className='bg-transparent border-0 py-0'>{children}</Button>
  )
}

export default ConnectWallet;