import { Button, Card, Col, Container, Form, Row, Table, InputGroup } from "react-bootstrap";
import { lamboPath } from "../../assets";
import { useEffect, useState } from "react";
import IncrementalCounter from "../../components/flip";

const LamboJackpotPage: React.FunctionComponent = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup: remove event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowWidth])
    return (
        <div className="LamboJackpotPage">
            <Container fluid>
                <Card className="bg-primary-2 border-white mb-3">
                    <Card.Body>
                        <div className="fs-3 text-center text-secondary-1">
                            A LUCKY WINNER WILL DRIVE HOME A LAMBORGHINI WHEN THIS COUNTDOWN ENDS!
                        </div>
                        <hr />
                        <Row className="align-items-center">
                            <Col xs={12} lg={5} className="mx-auto">
                                {/* <Image src={LamboJackpotImage1} width="100%" alt="banner" /> */}
                                <video autoPlay muted loop className="w-100 rounded-5 border border-white">
                                    <source src={lamboPath} type="video/mp4" />
                                </video>
                            </Col>
                            <Col xs={12} lg={5} className="mb-3 mx-auto">
                                <Card.Subtitle className="section-title my-2 text-center">Golden Ticket Contest</Card.Subtitle>
                                <Card.Title className="section-title fs-1 text-center">Win a Lambo</Card.Title>
                                <Card.Subtitle className="section-title fs-6 mb-3 text-center">$200,0000 IN BSP</Card.Subtitle>
                                <IncrementalCounter value={0} digits={7} />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Row>
                    <Col xs={12} lg={4} className="mb-2">
                        <Card className="bg-primary-2 border-white mb-3">
                            <Card.Body>
                                <Card.Title className="section-title text-center mb-2">How To Win</Card.Title>
                                <hr />
                                <Row>
                                    <Col xs={4} lg={4} className="my-1 text-center">
                                        <Card.Title>1. PLAY THE GAME</Card.Title>
                                    </Col>
                                    <Col xs={4} lg={4} className="my-1 text-center">
                                        <Card.Title>2. WAGER YOUR BEST</Card.Title>
                                    </Col>
                                    <Col xs={4} lg={4} className="my-1 text-center">
                                        <Card.Title>3. GOLDEN TICKETS</Card.Title>
                                    </Col>
                                </Row>
                                <hr />
                                <div className="border border-white rounded-3 bg-primary-2">
                                    <Table className="mb-0" responsive>
                                        <thead>
                                            <tr>
                                                <th className="bg-transparent border-white text-center">Your Tickets</th>
                                                <th className="bg-transparent border-white text-center">TOTAL TICKETS</th>
                                                <th className="bg-transparent border-white text-center">WIN CHANCE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">0</td>
                                                <td className="bg-transparent border-white text-center">797,623</td>
                                                <td className="bg-transparent border-white text-center">0.00%</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <hr />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} lg={4} className="mb-2">
                        <Card className="bg-primary-2 border-white mb-3">
                            <Card.Body>
                                <div className="section-title text-center">BUY GOLDEN TICKETS</div>
                                <hr />
                                <Row className="align-items-end">
                                    <Col xs={12} lg={12} className="mb-3">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="text-white">NO. OF TICKETS</Form.Label>
                                            {/* <Form.Control type="text" placeholder="Enter no. of ticket" className="rounded-5 border-white border bg-primary-1 text-white" /> */}
                                            <InputGroup className="rounded-5 border-white border bg-primary-1 text-white overflow-hidden">
                                                <Form.Control className="border-0 bg-primary-1 text-white" placeholder="Enter no. of ticket" />
                                                <Button variant="outline-secondary" className="text-white">5</Button>
                                                <Button variant="outline-secondary" className="text-white">10</Button>
                                                <Button variant="outline-secondary" className="text-white">MAX</Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={12} className="mb-3 d-flex align-items-center justify-content-between">
                                        <Card.Subtitle className="text-secondary">WINNING PERCENT</Card.Subtitle>
                                        <Card.Subtitle className="text-secondary-1">10%</Card.Subtitle>
                                    </Col>
                                    <Col xs={12} lg={12} className="mb-3 d-flex align-items-center justify-content-between">
                                        <Card.Subtitle className="text-secondary">PRICE PER TICKET</Card.Subtitle>
                                        <Card.Subtitle className="text-secondary-1">$ 25.00 BSP</Card.Subtitle>
                                    </Col>
                                    <Col xs={12} lg={12} className="mb-3 d-flex align-items-center justify-content-between">
                                        <Card.Subtitle className="text-secondary">TOTAL AMOUNT</Card.Subtitle>
                                        <Card.Subtitle className="text-secondary-1">$ 0.00 BSP</Card.Subtitle>
                                    </Col>
                                    <Col xs={12} lg={12} className="mb-3">
                                        <Button className="w-100 rounded-5 border-0 bg-secondary-1 text-primary-1 text-uppercase">Buy Ticket</Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} lg={4} className="mb-2">
                        <Card className="bg-primary-2 border-white mb-3">
                            <Card.Body>
                                <Card.Title className="section-title text-center mb-2">PREVIOUS ROUND WINNERS</Card.Title>
                                <hr />
                                <Row>
                                    <Col xs={12} lg={12} className="my-1 text-center">
                                        <Card.Title>TOTAL TICKETS: 1,564,201</Card.Title>
                                    </Col>
                                </Row>
                                <hr />
                                <div className="border border-white rounded-3 bg-primary-2">
                                    <Table className="mb-0" responsive>
                                        <thead>
                                            <tr>
                                                <th className="bg-transparent border-white text-center">USER ID</th>
                                                <th className="bg-transparent border-white text-center">AMOUNT WON</th>
                                                <th className="bg-transparent border-white text-center">USER TICKETS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">13517688</td>
                                                <td className="bg-transparent border-white text-center">$ 7.44407638</td>
                                                <td className="bg-transparent border-white text-center">266,282</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <hr />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default LamboJackpotPage;