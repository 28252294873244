import React, { useState, useEffect, useRef } from "react";
import { Card, Container, Tabs, Tab, Image, InputGroup, Form, Row, Col, Table, Button } from "react-bootstrap";
import { AvatarImage1, BspIcon } from "../../../../assets";

const AviatorGame: React.FunctionComponent = () => {

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
    const [curvePoints, setCurvePoints] = useState<{ x: number; y: number }[]>([]);
    const [rocketPosition, setRocketPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [crashed, setCrashed] = useState<boolean>(false);
    const [betAmount, setBetAmount] = useState<any>(0);
    const [profitsTaken, setProfitsTaken] = useState<boolean>(false);
    const [gameLoop, setGameLoop] = useState<NodeJS.Timeout | null>(null);
    const [balance, setBalance] = useState<number>(1000);

    useEffect(() => {
        const canvas = canvasRef.current;

        if (canvas) {
            const context = canvas.getContext('2d');
            context!.strokeStyle = 'white';
            context!.lineWidth = 1;
            setCtx(context);
        }
    }, []);

    const updateCurve = () => {
        if (ctx) {
            ctx.clearRect(0, 0, canvasRef.current?.width || 0, canvasRef.current?.height || 0);
            ctx.beginPath();

            for (let i = 0; i < curvePoints.length; i++) {
                const { x, y } = curvePoints[i];
                ctx.lineTo(x, y);
            }

            ctx.stroke();

            if (!crashed) {
                const lastPoint = curvePoints[curvePoints.length - 1];
                // debugger

                if (curvePoints.length >= 2) {
                    const secondLastPoint = curvePoints[curvePoints.length - 2];
                    const deltaX = lastPoint.x - secondLastPoint.x;
                    const deltaY = lastPoint.y - secondLastPoint.y;
                    const angle = Math.atan2(deltaY, deltaX) + Math.PI / 4;

                    ctx.save();
                    ctx.translate(lastPoint.x, lastPoint.y);
                    ctx.rotate(angle);

                    ctx.font = '30px Arial';
                    ctx.fillText('🚀', 0, -10);

                    setRocketPosition({ x: lastPoint.x, y: lastPoint.y });

                    ctx.restore();
                } else {
                    ctx.font = '30px Arial';
                    // debugger
                    ctx.fillText('🚀', lastPoint.x, lastPoint.y - 10);

                    setRocketPosition({ x: lastPoint.x, y: lastPoint.y });
                }
            } else {
                const crashPosition = { x: rocketPosition.x, y: rocketPosition.y - 10 };
                const crashAngle =
                    Math.atan2(
                        rocketPosition.y - curvePoints[curvePoints.length - 2].y,
                        rocketPosition.x - curvePoints[curvePoints.length - 2].x
                    ) +
                    Math.PI / 4;

                ctx.save();
                ctx.translate(crashPosition.x, crashPosition.y);
                ctx.rotate(crashAngle);

                ctx.font = '30px Arial';
                ctx.fillText('💥', 0, 0);

                ctx.restore();
            }
        }
    };

    const updateCurvePoints = () => {
        const lastPoint = curvePoints[curvePoints.length - 1];
        const newX = lastPoint.x + 1;
        const newY = canvasRef.current ? canvasRef.current.height - Math.pow(newX, 1.75) / 100 : 0;

        // document.getElementById('currentMultiplier')?.innerText = (curvePoints.length / 100).toFixed(2) + 'x';

        setCurvePoints((prevPoints) => [...prevPoints, { x: newX, y: newY }]);
    };

    const startGame = () => {
        // debugger
        setCurvePoints([{ x: 0, y: canvasRef.current ? canvasRef.current.height : 0 }]);
        updateCurve();
    };

    const getRandomCrashTime = (min: number, max: number): number => {
        // return Math.random() * (max - min) + min * 1000;
        return 1000;
    };

    const crashCurve = (betAmount: number) => {
        if (gameLoop) {
            clearInterval(gameLoop);
        }

        const crashValue = curvePoints.length / 100;
        // document.getElementById('crashedAt')?.innerText = `Multiplier at crash: ${crashValue.toFixed(2)}`;
        setCrashed(true);

        const crash = document.createElement('p');
        crash.innerText = crashValue.toFixed(2);
        crash.style.padding = '0';
        crash.style.margin = '0';
        crash.style.color = profitsTaken ? 'lime' : 'red';

        document.getElementById('lastCrashes')?.appendChild(crash);

        updateCurve();
    };

    const handleBetSubmit = () => {
        if (balance > +betAmount) {
            setBalance((prevBalance) => prevBalance - +betAmount);
            // document.getElementById('balance')?.innerText = balance + '$';

            setCrashed(false);
            setProfitsTaken(false);
            startGame();

            const loop = setInterval(() => {
                updateCurvePoints();
                updateCurve();
            }, 16);

            setGameLoop(loop);

            setBetAmount(+betAmount);

            setTimeout(() => {
                crashCurve(+betAmount);
            }, getRandomCrashTime(1, 5.5));
        }
    };

    const handleTakeProfits = () => {
        if (!crashed && !profitsTaken) {
            setProfitsTaken(true);
            const multiplier = curvePoints.length / 100;
            const profit = betAmount * multiplier - betAmount;
            setBalance((prevBalance) => +(betAmount * multiplier + prevBalance).toFixed(2));
            // document.getElementById('balance')?.innerText = balance + '$';
        } else if (profitsTaken) {
            console.log('Profits already taken');
        }
    };

    return (
        <div className="AviatorGame">
            <Container fluid>
                <Card className="bg-primary-2 border-white">
                    <Card.Body>
                        <Row>
                            <Col sm={12} md={7} className="mb-3">
                                <canvas ref={canvasRef} width={500} height={500} />
                                <Row>
                                    <Col sm={12} md={6} className="mb-3 mx-auto">
                                        <Button className="bg-secondary-1 border-0 rounded-5 w-100 text-primary-1" onClick={handleBetSubmit}>Bet</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Amount</Form.Label>
                                            <InputGroup className="bg-primary-1 border-white border rounded-2 overflow-hidden p-1">
                                                <Form.Control className="bg-primary-1 border-0" value={betAmount} onChange={(e) => setBetAmount(e.target.value)}/>
                                                <Button variant="outline-secondary" className="px-2 mx-1 rounded-2 border-white">/2</Button>
                                                <Button variant="outline-secondary" className="px-2 rounded-2 border-white">x2</Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Auto cash out</Form.Label>
                                            <InputGroup className="bg-primary-1 border-white border rounded-2 overflow-hidden p-1">
                                                <Form.Control className="bg-primary-1 border-0" />
                                                <Button variant="outline-secondary" className="px-3 mx-1 rounded-2 border-white">-</Button>
                                                <Button variant="outline-secondary" className="px-3 rounded-2 border-white">+</Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12} md={5} className="mb-3">
                                <div className="bg-primary-1 border border-white rounded-3 overflow-hidden">
                                    <Table className="mb-0">
                                        <thead>
                                            <tr>
                                                <th className="bg-transparent border-white">PLAYER</th>
                                                <th className="bg-transparent border-white">CASH OUT</th>
                                                <th className="bg-transparent border-white">AMOUNT</th>
                                                <th className="bg-transparent border-white">PROFIT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="bg-transparent border-white align-middle">
                                                    <span className="d-flex align-items-center">
                                                        <Image src={AvatarImage1} width={30} alt="avatar" className="me-2" />
                                                        <span>Plyer Name</span>
                                                    </span>
                                                </td>
                                                <td className="bg-transparent border-white align-middle">
                                                    betting
                                                </td>
                                                <td className="bg-transparent border-white align-middle">
                                                    <span className="d-flex align-items-center">
                                                        <Image src={BspIcon} width={30} alt="avatar" className="me-2" />
                                                        <span>$23.21</span>
                                                    </span>
                                                </td>
                                                <td className="bg-transparent border-white align-middle">
                                                    betting
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default AviatorGame;