import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { MysteryBoxBg, MysteryBoxImage } from "../../assets";
import { CrashGameCardData } from "../../utils/data";
import { CrashGameCard, MysterBoxCard } from "../cards/cards";
import { NavLink } from "react-router-dom";

export const MysteryBoxCarousel: React.FunctionComponent = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <Carousel className="mysterybox-carousel" responsive={responsive} autoPlay={true} autoPlaySpeed={1000} infinite>
            <MysterBoxCard bg={MysteryBoxBg} image={MysteryBoxImage}/>
            <MysterBoxCard bg={MysteryBoxBg} image={MysteryBoxImage}/>
            <MysterBoxCard bg={MysteryBoxBg} image={MysteryBoxImage}/>
            <MysterBoxCard bg={MysteryBoxBg} image={MysteryBoxImage}/>
            <MysterBoxCard bg={MysteryBoxBg} image={MysteryBoxImage}/>
            <MysterBoxCard bg={MysteryBoxBg} image={MysteryBoxImage}/>
            <MysterBoxCard bg={MysteryBoxBg} image={MysteryBoxImage}/>
            <MysterBoxCard bg={MysteryBoxBg} image={MysteryBoxImage}/>
            <MysterBoxCard bg={MysteryBoxBg} image={MysteryBoxImage}/>
            <MysterBoxCard bg={MysteryBoxBg} image={MysteryBoxImage}/>
        </Carousel>
    )
}

export const CrashGameCardCarousel: React.FunctionComponent = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={1500} infinite>
            {CrashGameCardData.map((item, index) => (
                <NavLink to={item.path} className="nav-link" key={index}>
                    <CrashGameCard bg={item.bg} title={item.title} image={item.image} key={index} />
                </NavLink>
            ))}
        </Carousel>
    )
}