import React from "react";
import { Button } from "react-bootstrap";

export const OutlineButton: React.FunctionComponent<{title: string}> = ({title}) => {
    return(
        <Button className="outline-btn rounded-5 px-4 border border-1 border-white mx-2">{title}</Button>
    )
}

export const MainButton: React.FunctionComponent<{title: string}> = ({title}) => {
    return(
        <Button className="main-btn rounded-5 px-4 border border-1">{title}</Button>
    )
}