import React, { useEffect, useRef, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Button, Card, Col, Container, Dropdown, Image, Overlay, OverlayTrigger, ProgressBar, Row, Tooltip } from "react-bootstrap";
import { socket } from '../../utils/socket';
import axios from 'axios';
import { useMainContext } from '../../context/mainContext';

import { DownIcon, DownVectorIcon, StarIcon, UpIcon, UpVectorIcon } from "../../assets";
import { toast } from 'react-toastify';

interface DataItem {
    h: string;
    t: string;
    v: string;
}

interface ChartDataItem {
    date: number;
    value: number;
    bullet?: boolean;
    range?: boolean;
}

interface PayoutProps {
    id: string;
    lose: string;
    win: string;
}

interface AssetListItemProps {
    assetId: string;
    assetCode: string;
    label: string;
    ico_1: string;
    is_default: string;
    position: string;
    payouts: PayoutProps[];
}

interface DurationItemProps {
    id: number;
    label: string;
    duration: string;
    duration_minimun: string;
    is_default: string;
}

type Props = {
    onData: (data: string) => void;
};


const PredictionTradingChart: React.FunctionComponent<Props> = ({ onData }) => {
    const chartRef = useRef<am5.Root>();
    const [assetsLiveRate, setAssetsLiveRate] = useState<{ [key: string]: string }>({});
    const [assetList, setAssetList] = useState<any>({});
    const [selectAsset, setSelectAsset] = useState<AssetListItemProps | null>(null);
    const [chartData, setChartData] = useState<any | null>(null);

    const [endTradeTime, setEndendTradeTime] = useState<any | null>(null);
    const { token, userInfo } = useMainContext();
    const [durationList, setDurationList] = useState<DurationItemProps[] | null>(null);
    const [selectDuration, setSelectDuration] = useState<DurationItemProps | null>(null);
    const [time, setTime] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(100);
    const [status, setStatus] = useState<boolean>(false);
    const [price, setPrice] = useState<number>(0);
    const [favAsset, setFavAsset] = useState<string[]>([]);
    const [upAmount, setUpAmount] = useState<number>(0);
    const [downAmount, setDownAmount] = useState<number>(0);
    const [amount, setAmount] = useState<number>(0);
    const [openTradingList, setOpenTradingList] = useState<any | null>(null);
    const [winner, setWinner] = useState<string>("");


    const fetchData = async () => {
        try {
            const headers = { contentType: 'application/json', Authorization: token, token: token };
            await axios.get(`${process.env.REACT_APP_API_URL}/trade/get-datetime-endtrade/`, { headers })
                .then(function (response) {
                    setEndendTradeTime(response.data);
                    // window.localStorage.setItem("endTime", JSON.stringify(response.data.datetimeEndTrade.timestamp))
                })
                .catch(function (error) {
                    console.log(error);
                });
            await axios.get(`${process.env.REACT_APP_API_URL}/trade/durations/list/`, { headers })
                .then(function (response) {
                    setDurationList(response.data.records);
                    const defaultDuration = (response.data.records).find((row: any) => row.is_default === "1");
                    setSelectDuration(defaultDuration);
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {

        }
    };

    const setFavAssetList = () => {
        let favList: any = window.localStorage.getItem("favAsset");

        if (favList) {
            let _favList = JSON.parse(favList);

            const frequencyCount = _favList.reduce((acc: any, variable: any) => {
                acc[variable] = (acc[variable] || 0) + 1;
                return acc;
            }, {});

            const frequencyArray = Object.entries(frequencyCount);

            // Sort the frequencies in descending order
            frequencyArray.sort((a: any, b: any) => b[1] - a[1]);

            // Get the top 4 most common assets
            const top4Variables = frequencyArray.slice(0, 4).map(entry => entry[0]);

            // Display favorite badge for the top 4 assets
            const assetListWithFav = Object.entries(assetList).map((asset: any) => {
                if (top4Variables.includes(asset.assetCode)) {
                    return { ...asset, isFavorite: true };
                } else {
                    return { ...asset, isFavorite: false };
                }
            });


            console.log("assetListWithFav", assetListWithFav)
            setFavAsset(top4Variables);
        }

    }

    const getTradingEndTime = async () => {
        try {
            const headers = { contentType: 'application/json', Authorization: token, token: token };
            await axios.get(`${process.env.REACT_APP_API_URL}/trade/get-datetime-endtrade/`, { headers })
                .then(function (response) {
                    setEndendTradeTime(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    }

    const handleImageError = (event: any) => {
        event.target.src = 'https://api.dev.bitnswap.com/design/img/generic.svg';
    };

    const selectAssetList = (assetId: string, assetCode: string, label: string, ico_1: string, is_default: string, payouts: PayoutProps[], position: string) => {
        setSelectAsset({ assetId, assetCode, label, ico_1, is_default, payouts, position });
        // console.log({ assetId, assetCode, label, ico_1, is_default, payouts, position })
        let favList = window.localStorage.getItem("favAsset");

        if (favList) {
            const _favList = JSON.parse(favList)
            _favList.push(assetCode);
            window.localStorage.setItem("favAsset", JSON.stringify(_favList))
            setFavAssetList();
        }
        else {
            window.localStorage.setItem("favAsset", JSON.stringify([assetCode]))
        }
        socket.emit("subscribe-asset", { assetId, assetCode });
        socket.on("asset-history", (response: any) => {
            // console.log("ddd----dddd", response)

            const chartData: any =

                response.datas.map((row: DataItem): ChartDataItem => ({
                    date: parseInt(row.t) * 1000, // Convert Unix timestamp to milliseconds
                    value: parseFloat(row.v) // Parse value to float
                }));

            const _data: any = chartData.slice(-1000);
            setChartData(_data as any);
        });

        return () => {
            socket.off("asset-history");
        };
    };

    const getAssetsLiveRate = () => {
        socket.on("asset-live-rate", (data: any) => {
            const parsedData = JSON.parse(data);
            delete parsedData['jsonbegin'];
            delete parsedData['jsonend'];
            setAssetsLiveRate(parsedData);
            window.localStorage.setItem("live-rate", parsedData)
        });

        return () => {
            socket.off("asset-live-rate");
        };
    }

    const getAssetsLiveListChange = () => {
        socket.emit("start", "start");
        socket.on("assets-live-list-change", (data: any) => {
            console.log("assets-live-list-change", data)
            setAssetList(data)
            const defaultAsset: any = Object.entries(data).find((row: any) => row[1].assetCode === "btcusdt");
            selectAssetList(defaultAsset[1]?.assetId, defaultAsset[1]?.assetCode, defaultAsset[1]?.label, defaultAsset[1]?.ico1, defaultAsset[1]?.isDefault, [{ id: defaultAsset[1]?.payouts[0].id, win: defaultAsset[1]?.payouts[0].win, lose: defaultAsset[1]?.payouts[0].loss }], defaultAsset[1]?.position);
            // console.log(defaultAsset);
        });

        return () => {
            socket.off("assets-live-list-change");
        };
    }

    const startTrading = (type: string) => {
        if (!token) {
            toast.error("You can trade after logging into this platform");
            return
        }
        const params = {
            asset_id: parseInt(selectAsset?.assetId as string),
            client_id: parseInt(userInfo.id as string),
            amount: parseFloat(amount as any),
            currentRate: parseFloat(assetsLiveRate[selectAsset?.assetCode as string] as any),
            choice: type,
            duration: parseInt(selectDuration?.duration as any),
            payout_id: parseInt(selectAsset?.payouts[0].id as any),
            currency: "USD"
        };

        socket.emit("take-trade-binary", params);
        console.log("params", params)
    };

    const getOpenTrade = () => {
        // socket.on("open-positions-change", (response: any) => {
        //     console.log("open-positions-change", response);
        //     setOpenTradingList(response);
        // });


        // return () => {
        //     socket.off("open-positions-change");
        // };

    }

    const calcPotentialAmount = () => {

    }

    useEffect(() => {
        onData(openTradingList)
    }, [openTradingList]);

    useEffect(() => {
        // Calculate the end time based on selected duration
        setDisabled(false);
        const adonisTime: number = endTradeTime?.datetimeEndTrade.timestamp[selectDuration?.duration as string];

        // Set up the interval to update the time every second
        const intervalId = setInterval(() => {
            const currentTimestamp: number = Math.floor(Date.now() / 1000);
            let _time = adonisTime - currentTimestamp;
            if (!isNaN(_time)) {
                if (_time >= 0) {
                    if (_time <= parseInt(selectDuration?.duration_minimun as string)) {
                        setDisabled(true);
                    }
                    if (_time === 0) {
                        getTradingEndTime();
                        setDisabled(false);
                    }
                    if (_time >= 0) {
                        setTime(_time);
                        let percent: number = (_time / parseInt(selectDuration?.duration as string) * 100);
                        setProgress(percent);
                        return;
                    }
                } else {
                    clearInterval(intervalId); // Clear the interval when the time is negative
                }

            }
        }, 1000);

        // Clean up the interval when the component unmounts or when selectDuration changes
        return () => {
            clearInterval(intervalId);
        };
    }, [selectDuration, endTradeTime]);

    useEffect(() => {
        fetchData();
        getAssetsLiveRate();
        getAssetsLiveListChange();
        getOpenTrade();
    }, []);


    useEffect(() => {
        if (!chartData) return;
        let root = am5.Root.new("chartdiv");
        chartRef.current = root;

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let data: any = chartData;

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            focusable: true,
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
            paddingLeft: 0
        }));

        let easing = am5.ease.linear;

        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.5,
            groupData: false,
            extraMax: 0.15,
            extraMin: -0.3,
            baseInterval: {
                timeUnit: "millisecond",
                count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
                cellStartLocation: 0.2,
                cellEndLocation: 0.8
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                extraTooltipPrecision: 1,
                renderer: am5xy.AxisRendererY.new(root, {
                    minGridDistance: 30
                })
            })
        );

        yAxis.onPrivate("selectionMax", function (value: any, target: any) {
            localStorage.setItem("max", value);
        })
        yAxis.onPrivate("selectionMin", function (value: any, target: any) {
            localStorage.setItem("min", value)
        })

        let series = chart.series.push(am5xy.SmoothedXYLineSeries.new(root, {
            name: "Series 1",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{valueY}"
            })
        }));

        // series.fills.template.set("fillGradient", am5.LinearGradient.new(root, {
        //     stops: [{
        //       opacity: 0.5
        //     }, {
        //       opacity: 0
        //     }],
        //     rotation: 90
        //   }));

        series.fills.template.setAll({
            visible: true,
            fillOpacity: 0
        });

        series.strokes.template.setAll({
            strokeWidth: 2
        });

        // /////////////////////////////
        let rangeDataItem: any = yAxis.makeDataItem({ value: price, endValue: 0 });
        yAxis.createAxisRange(rangeDataItem);

        rangeDataItem.get("grid").setAll({
            stroke: am5.color(0xffffff),
            strokeOpacity: 1,
            strokeDasharray: [5]
        });

        let container = am5.Container.new(root, {
            centerX: am5.p0,
            centerY: am5.p0,
            draggable: true,
            layout: root.verticalLayout
        })

        // restrict from being dragged vertically




        let rangeDataItemUp: any = yAxis.makeDataItem({ value: price, endValue: price === 0 ? 99999 : price * 2 });
        let rangeDataItemDown: any = yAxis.makeDataItem({ value: price, endValue: 0 });
        yAxis.createAxisRange(rangeDataItemUp);
        yAxis.createAxisRange(rangeDataItemDown);

        rangeDataItemUp.get("grid").setAll({
            stroke: am5.color(0x00ff33),
            strokeOpacity: 0,
            strokeDasharray: [5]
        });

        rangeDataItemUp.get("axisFill").setAll({
            fill: am5.color(0x00ff33),
            fillOpacity: 0.25,
            visible: true
        });

        rangeDataItemDown.get("grid").setAll({
            stroke: am5.color(0x00ff33),
            strokeOpacity: 0,
            strokeDasharray: [5]
        });

        rangeDataItemDown.get("axisFill").setAll({
            fill: am5.color(0xff4949),
            fillOpacity: 0.25,
            visible: true
        });


        series.events.on("datavalidated", () => {
            let lastDataItem: any = series.dataItems[series.dataItems.length - 1];
            let lastValue = lastDataItem.get("valueY");
            rangeDataItem.set("value", lastValue);
            rangeDataItemUp.set("value", lastValue);
            rangeDataItemDown.set("value", lastValue);
            updateLabel(lastValue);
            yAxis.topGridContainer.children.push(container);
            setPrice((prevPrice: number) => {
                if (prevPrice > lastValue) {
                    setStatus(false);
                } else {
                    setStatus(true);
                }

                return lastValue
            });



            container.adapters.add("y", function () {
                let max = localStorage.getItem("max");
                let min = localStorage.getItem("min");
                let rate = chart.plotContainer.height() / (Number(max) - Number(min));
                return (Number(max) - lastValue) * rate - 15;
            });

            container.adapters.add("x", function () {
                return am5.percent(72.5);
            });

        })



        let background = am5.RoundedRectangle.new(root, {
            fill: am5.color(0xffffff),
            fillOpacity: 1,
            strokeOpacity: 1,            
            cursorOverStyle: "ns-resize",
            stroke: am5.color(0xffffff),
            cornerRadiusBL: 100,
            cornerRadiusTL: 100
        })

        container.set("background", background);

        function updateLabel(value?: any) {
            let y = container.y();
            let position = yAxis.toAxisPosition(y / chart.plotContainer.height());

            if (value == null) {
                value = yAxis.positionToValue(position);
            }

            label.set("text", root.numberFormatter.format(value, "#.00"));
            // label.set("html", "<div style=\"text-align: center; font-weight: bold;\">{root.numberFormatter.format(value, '#.00')}</div>")

            rangeDataItem.set("value", value);
        }

        let label = container.children.push(am5.Label.new(root, {
            paddingTop: 5,
            paddingBottom: 5
        }))


        ///////////////////////////////

        xAxis.get("renderer").labels.template.setAll({
            fill: root.interfaceColors.get("alternativeText")
        });
        yAxis.get("renderer").labels.template.setAll({
            fill: root.interfaceColors.get("alternativeText")
        });
        root.interfaceColors.set("grid", am5.color(0xffffff));

        data[data?.length - 1].bullet = true;
        series.data.setAll(data);

        series.bullets.push(function (root, series, dataItem: any) {
            if (dataItem.dataContext.bullet) {
                let container = am5.Container.new(root, {});
                let circle0 = container.children.push(am5.Circle.new(root, {
                    radius: 5,
                    fill: am5.color(0xffffff)
                }));
                let circle1 = container.children.push(am5.Circle.new(root, {
                    radius: 5,
                    fill: am5.color(0xffffff)
                }));

                circle1.animate({
                    key: "radius",
                    to: 20,
                    duration: 1000,
                    easing: am5.ease.out(am5.ease.cubic),
                    loops: Infinity
                });
                circle1.animate({
                    key: "opacity",
                    to: 0,
                    from: 1,
                    duration: 1000,
                    easing: am5.ease.out(am5.ease.cubic),
                    loops: Infinity
                });

                return am5.Bullet.new(root, {
                    locationX: undefined,
                    sprite: container
                });
            }
        });

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis
        }));
        cursor.lineY.set("visible", false);

        let pre_t = 0

        function addData(v: any, t: any, trade:any = null) {
            // console.log("Added T: ", t * 1000);
            if (t === pre_t || t < pre_t) {
                return
            }
            pre_t = t;

            console.log("addData-t", t)

            let newData = {
                date: t * 1000,
                value: v,
                trade
            };

            let lastDataItem: any = series.dataItems[series.dataItems.length - 1];

            let lastValue = lastDataItem.get("valueY");
            // series.data.push({
            //     date: t * 1000,
            //     value: v
            // });

            series.data.push(newData);


            let newDataItem = series.dataItems[series.dataItems.length - 1];
            newDataItem.animate({
                key: "valueYWorking",
                to: v,
                from: lastValue,
                duration: 500,
                easing: easing
            });

            if (lastDataItem.bullets && lastDataItem.bullets.length > 0) {
                newDataItem.bullets = [];
                newDataItem.bullets[0] = lastDataItem.bullets[0];
                if (newDataItem.bullets[0]?.get("sprite")) {
                    newDataItem.bullets[0].get("sprite").dataItem = newDataItem;
                }
            }
            lastDataItem.dataContext.bullet = false;
            lastDataItem.bullets = [];

            let animation = newDataItem.animate({
                key: "locationX",
                to: 0.125,
                from: -0.125,
                duration: 500,
                easing: easing
            });
            if (animation) {
                let tooltip = xAxis.get("tooltip");
                if (tooltip && !tooltip.isHidden()) {
                    animation.events.on("stopped", function () {
                        xAxis.updateTooltip();
                    });
                }
            }
        }
        let last_t = 0;
        let last_y = 0;

        socket.on("asset-history-last-value", (response: any) => {

            let step = 8;

            let delta_y = (response.graph.v - last_y) / step;
            let delta_t = (response.graph.t - last_t) / step;

            if (last_t > 0) {
                for (let i = 1; i <= step; i++) {
                    let y = last_y + delta_y * i;
                    let t = last_t + delta_t * i;
                    setTimeout(() => addData(y, t), delta_t * 1000 * i);
                }
            }


            last_y = response.graph.v;
            last_t = response.graph.t;
        });

        socket.on("open-positions-change", (response: any) => {
            console.log("open-positions-change=====================", response);
            console.log("WOW=====================", response[`${selectDuration?.duration}`][0].begin_rate, response[`${selectDuration?.duration}`][0].dateTimestamp / 1000, {type: response[`${selectDuration?.duration}`][0].choice});
            addData(response[`${selectDuration?.duration}`][0].begin_rate, response[`${selectDuration?.duration}`][0].dateTimestamp / 1000, {type: response[`${selectDuration?.duration}`][0].choice})
            setOpenTradingList(response);
        });
        

        chart.appear(1000, 100);

        return () => {
            root.dispose();
            socket.off("asset-history-last-value");
            socket.off("open-positions-change");
        };
    }, [chartData]);

    useEffect(() => {
        setWinner("")
        setInterval(() => {
            // debugger
            
            if(winner === "UP") {
                setWinner("DOWN");
                return;
            }
            setWinner("UP");
        }, 5000);
    }, [])

    const TradeHistory = () => {
        return (
            <div className='d-flex w-100'>
                {openTradingList && openTradingList[`${selectDuration?.duration === null ? 60 : selectDuration?.duration}`]?.reverse()?.slice(-6)?.map((row: any, i: number) => {
                    if (row.choice === 'PUT') {
                        return (
                            <Button className='down-trade-btn ms-2 border-0 px-2 text-center'><Image src={DownVectorIcon} height={25} width={25} alt='vector' /></Button>
                        )
                    } else {
                        return (
                            <Button className='up-trade-btn ms-2 border-0 px-2 text-center'><Image src={UpVectorIcon} height={25} width={25} alt='vector' /></Button>
                        )
                    }
                })}
            </div>
        )
    }

    useEffect(() => {
        TradeHistory();
    }, [startTrading]);



    return (
        <Container fluid>
            <Row>
                <Col xs={12} sm={8}>
                    <Card className="border-white bg-primary-2 mb-3 h-100">
                        <Card.Body>
                            <div className="FastAndFuriousChart">
                                <Row className='mb-3'>
                                    <Col xs={3} lg={4}>
                                        <Row>
                                            <Col sm={6} className="d-none d-lg-block">
                                                <div className="fast-chart-info mb-3">
                                                    <label>YOUR INVESTMENT</label>
                                                    <div className="fast-chart-info-value">{amount}</div>
                                                </div>
                                                <div className="fast-chart-info mb-3">
                                                    <label>POTENTIAL RETURN</label>
                                                    <div className="fast-chart-info-value">190.0</div>
                                                </div>

                                            </Col>
                                            <Col sm={12} lg={4}>
                                                <div className="d-flex align-items-center h-100  text-center ">
                                                    <div className="fast-chart-info mb-3">
                                                        {/* <label >DOWN POOL PAYOUT</label> */}
                                                        <div className="fast-chart-info-value down-pool text-center fs-1 d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => startTrading("PUT")}><span className='me-2'>1.9x</span> <Image src={DownIcon} height={20} alt="coin" className="mx-2 " onError={handleImageError} /></div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={6} lg={4} className="d-lg-flex align-items-center">
                                        <div className="mx-auto mb-2">
                                            <Dropdown data-bs-theme="dark" className="text-center fast-chart-coin-dropdown d-flex align-items-center justify-content-center">
                                                {selectAsset === null ? (
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="d-flex align-items-center p-0 bg-transparent border border-white rounded-5 pt-1 ps-3 pe-2 fs-4">Select Asset</Dropdown.Toggle>
                                                ) : (
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="asset-list-selector d-flex align-items-center p-0 bg-transparent border border-0 rounded-5 py-2 px-2">
                                                        <div className="position-relative">
                                                            <div className="bg-white rounded-circle d-flex align-items-center justify-content-center text-center asset-list-selector-img" style={{ width: 130, height: 130 }}>
                                                                <Image className="rounded-circle" src={`https://api.dev.bitnswap.com/design/img/${selectAsset?.ico_1 === "" ? "generic.svg" : ("/assets/" + selectAsset?.ico_1)}`} width={110} height={110} alt="coin" onError={handleImageError} />
                                                            </div>
                                                            <div className="position-absolute asset-list-component w-100">
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <Image src={status === true ? UpIcon : DownIcon} height={20} alt="coin" className="mx-2 " onError={handleImageError} />
                                                                    <div className="selected-asset-price" style={{ color: status === true ? "#72f238" : "#ff4949" }}>{price.toLocaleString()}</div>
                                                                    <Image src={status === true ? UpIcon : DownIcon} height={20} alt="coin" className="mx-2" onError={handleImageError} />
                                                                </div>
                                                            </div>
                                                            <div className="position-absolute w-100 selected-asset-label">{selectAsset.label}</div>
                                                        </div>
                                                    </Dropdown.Toggle>
                                                )}

                                                <Dropdown.Menu className="bg-primary-1">
                                                    {Object.entries(assetList).filter((asset: any) => !favAsset.includes(asset.assetCode)).map((item: any, index: any) => {
                                                        return (
                                                            <Dropdown.Item className="d-flex align-items-center justify-content-between" key={index} >
                                                                <div className="d-flex align-items-center me-2" onClick={() => selectAssetList(item[1].assetId, item[1].assetCode, item[1].label, item[1].ico1, item[1].isDefault, item[1].payouts, item[1].position,)}>
                                                                    <div className="me-2">
                                                                        <Image src={`https://api.dev.bitnswap.com/design/img/${item[1].ico1 === "" ? "generic.svg" : ("/assets/" + item[1].ico1)}`} width={30} height={30} alt="coin" className="me-2 rounded-circle" onError={handleImageError} />
                                                                    </div>
                                                                    <div className="text-start">
                                                                        <div className="token_name">{item[1].label} </div>
                                                                    </div>
                                                                </div>
                                                                <div >
                                                                    {favAsset?.map((fav, j) => (
                                                                        <Image className={`${fav !== item[1]?.assetCode && "d-none"}`} src={StarIcon} height={20} />
                                                                    ))}
                                                                </div>
                                                            </Dropdown.Item>
                                                        )
                                                    })}

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                    <Col xs={3} lg={4}>
                                        <Row>
                                            <Col sm={6}>
                                                <div className="d-flex align-items-center justify-content-end h-100  text-center ">
                                                    <div className="fast-chart-info mb-3">
                                                        {/* <label>UP POOL PAYOUT</label> */}
                                                        <div className="fast-chart-info-value up-pool fs-1 text-center  d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => startTrading("CALL")}><Image src={UpIcon} height={20} alt="coin" className="mx-2 " onError={handleImageError} /> <span className='ms-2'>2.5x</span> </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm={6} className="d-none d-lg-block">
                                                <div className="fast-chart-info mb-3 text-end">
                                                    <label>YOUR INVESTMENT</label>
                                                    <div className="fast-chart-info-value">{amount}</div>
                                                </div>
                                                <div className="fast-chart-info mb-3 text-end">
                                                    <label>POTENTIAL RETURN</label>
                                                    <div className="fast-chart-info-value">190.0</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center justify-content-between">
                                    <Col xs={4} lg={1}>
                                        <div className="fast-chart-info text-center text-md-start">
                                            <div className="fast-chart-info-value">{Math.floor(time / 60) <= 9 ? ("0" + Math.floor(time / 60)) : (Math.floor(time / 60))}:{Math.floor(time % 60) <= 9 ? ("0" + Math.floor(time % 60)) : (Math.floor(time % 60))}</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} lg={10}>
                                        <ProgressBar animated variant={`${time <= parseInt(selectDuration?.duration_minimun as string) ? "danger" : "success"}`} now={progress} />
                                    </Col>
                                    <Col xs={4} lg={1}>
                                        <Dropdown data-bs-theme="dark" className="text-center fast-chart-coin-dropdown d-flex align-items-center justify-content-end">
                                            <Dropdown.Toggle className="bg-transparent border border-0 fs-5">
                                                {selectDuration?.label}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {durationList?.sort().map((item, index) => (
                                                    <Dropdown.Item className="text-center text-uppercase" key={index} onClick={() => setSelectDuration(item)}>{item.label}</Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={12} className="mb-3">
                                        <div className="position-relative border border-white rounded-3 border-2 overflow-hidden pb-4">
                                            <div id="chartdiv" style={{ width: "100%", height: "350px" }} className="fast-chart p-0"></div>
                                            <div className='position-absolute bottom-0 end-0'>
                                                <div className='mb-1 mx-3'>
                                                    <TradeHistory />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col xs={6} lg={2} className="d-none d-lg-block"><Button className="w-100 fast-chart-down-btn text-white border-0 py-3 rounded-5" disabled={disabled} onClick={() => startTrading("PUT")}>DOWN</Button></Col>
                                    <Col xs={12} lg={8} className="mb-2">
                                        <Row>
                                            <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 1} disabled={disabled} onClick={() => setAmount(1)}>1</Button></Col>
                                            <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 5} disabled={disabled} onClick={() => setAmount(5)}>5</Button></Col>
                                            <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 10} disabled={disabled} onClick={() => setAmount(10)}>10</Button></Col>
                                            <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 100} disabled={disabled} onClick={() => setAmount(100)}>100</Button></Col>
                                            <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 999} disabled={disabled} onClick={() => setAmount(999)}>Max</Button></Col>
                                            <Col xs={2} className="d-none d-lg-inline"><Button className="w-100 my-2 bg-transparent rounded-5  fast-chart-sm-button" active={amount === 0} disabled={disabled} onClick={() => setAmount(0)}>Reset</Button></Col>
                                            <Col xs={2} className="d-lg-none d-inline"><Button className="w-100 my-2 bg-transparent rounded-5  fast-chart-sm-button" active={amount === 0} disabled={disabled} onClick={() => setAmount(0)}>Del</Button></Col>
                                        </Row>
                                    </Col>
                                    <Col xs={6} lg={2} className="d-lg-none d-block"><Button className="w-100 fast-chart-down-btn rounded-5 text-white border-0 py-3" disabled={disabled} onClick={() => startTrading("PUT")}>DOWN</Button></Col>
                                    <Col xs={6} lg={2}><Button className="w-100 fast-chart-up-btn text-white py-3 rounded-5" disabled={disabled} onClick={() => startTrading("CALL")}>UP</Button></Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} sm={4}>
                    <Card className="border-white bg-primary-2 mb-3 h-100">
                        <Card.Body>
                            <Row>
                                <Col sm={winner === "UP" ? 12 : 6} className={`down-players bg-success ${winner === "DOWN" && 'd-none'}`}>1</Col>
                                <Col sm={winner === "DOWN" ? 12 : 6} className={`up-players bg-danger ${winner === "UP" && 'd-none'}`}>1</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default PredictionTradingChart;