import React from "react";
import { Card, Image } from "react-bootstrap";

interface CrashGameCardProps {
    bg: string;
    image: string;
    title: string;
}

interface MysteryBoxCardProps {
    bg: string;
    image: string;
}

export const CrashGameCard: React.FunctionComponent<CrashGameCardProps> = (props) => {
    return(
        <Card className="CrashGameCard border-white mx-2 my-2" style={{backgroundImage: `url(${props.bg})`, backgroundPosition:'center', backgroundSize:'cover'}}>
            <span></span>
            <Card.Body className="text-center">
                <Image src={props.image} width="80%" alt="game-avatar" className="mb-3"/>
                <Card.Title className="mb-3">{props.title}</Card.Title>
                <Card.Text>BITNSWAP ORIGINALS</Card.Text>
            </Card.Body>
        </Card>
    )
}

export const MysterBoxCard: React.FunctionComponent<MysteryBoxCardProps> = (props) => {
    return(
        <Card className="MysteryBoxCard border-0 bg-transparent m-3 p-3" >
            <Card.Body className=" rounded-2">
                <Image src={props.image} width="100%" alt="mysterbox"/>
            </Card.Body>
        </Card>
    )
}


