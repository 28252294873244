import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Header } from "./header";
import { Sidebar } from "./sidebar";
import { Footer } from "./footer";
import { useMainContext } from "../../context/mainContext";
import { toast } from "react-toastify";

export const MainLayout: React.FunctionComponent = () => {
    return (
        <>
            <Header />
            <div className="d-flex flex-row">
                <Sidebar/>
                <div className="main-content position-relative z-1 py-3">
                    <Outlet/>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export const PrivateRoute = () => {
    const {token} = useMainContext();
    if(!token) {
        toast.error("You must log in to this platform!");
        return (<Navigate to="/"/>)
    }
    return <Outlet/>
}