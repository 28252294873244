import ReactApexChart from "react-apexcharts";
import { Card, Col, Container, Row, Form, InputGroup, Button } from "react-bootstrap";

function DiceGame() {
    const chartOption = {
        options: {
            chart: {
              width: 350,
              type: 'donut',
            },
            dataLabels: {
              enabled: false
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  show: false
                }
              }
            }],
            legend: {
                show: true,
                showForSingleSeries: false,
                showForNullSeries: true,
              position: 'right',
              offsetY: 0,
              height: 230,
            }
          }
    };

    const chartData = [44, 55];
    return (
        <div className="DiceGame">
            <Container fluid>
                <Card className="border-white bg-primary-2 mb-3">
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col sx={12} lg={6} className="mx-auto">
                                    <ReactApexChart type="donut" height={350} options={chartOption as any} series={chartData}/>

                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={8} md={8} lg={8}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                placeholder="Recipient's username"
                                                aria-label="Recipient's username with two button addons"
                                            />
                                            <Button variant="outline-secondary">Button</Button>
                                            <Button variant="outline-secondary">Button</Button>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={4}>

                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default DiceGame;