import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Row, Tabs, Tab, Button, InputGroup, Form } from "react-bootstrap";
import { TradingChart } from "../../components/charts/tradingCharts";
import { MainButton } from "../../components/buttons/buttons";
import { ActiveTradeTable } from "../../components/tables/tables";
import { socket } from "../../utils/socket";

interface AssetData {
    [key: string]: string;
}

const TradingPage: React.FunctionComponent = () => {
    const [rangeValue, setRangeValue] = useState<number>(50);
    const [assetsLiveRate, setAssetsLiveRate] = useState<{ [key: string]: string }>({});
    const [previousData, setPreviousData] = useState<{ [key: string]: string }>({});
    const [assetsLiveList, setAssetsLiveList] = useState({});

    useEffect(() => {

        const handleAssetLiveRate = (data: any) => {
            const parsedData = JSON.parse(data);
            delete parsedData['jsonbegin'];
            delete parsedData['jsonend'];
            setAssetsLiveRate(parsedData);
        };

        const handleAssetsLiveListChange = (data: any) => {
            setAssetsLiveList(data);
        };

        const handleAssetsListChange = (data: any) => {
            // 
        };

        socket.on("asset-live-rate", handleAssetLiveRate);
        socket.on("assets-live-list-change", handleAssetsLiveListChange);
        socket.on("assets-list-change", handleAssetsListChange);

        // 
        return () => {
            socket.off("asset-live-rate", handleAssetLiveRate);
            socket.off("assets-live-list-change", handleAssetsLiveListChange);
            socket.off("assets-list-change", handleAssetsListChange);

            socket.disconnect(); 
        };
    }, []);

    useEffect(() => {
        setPreviousData(assetsLiveRate);
    }, [assetsLiveRate]);

    const rateChanges = useMemo(() => {
        // Compare the new and previous rates
        const changes: { [key: string]: string } = {};
        for (const symbol in assetsLiveRate) {
            if (previousData[symbol] !== undefined) {
                const newRate = parseFloat(assetsLiveRate[symbol]);
                const oldRate = parseFloat(previousData[symbol]);
                if (!isNaN(newRate) && !isNaN(oldRate)) {
                    changes[symbol] = newRate > oldRate ? 'green' : newRate < oldRate ? 'red' : '';
                }
            }
        }
        return changes;
    }, [assetsLiveRate, previousData]);

    return (
        <div className="TradingPage">
            <Container fluid>
                <Card className="border-white bg-primary-2 mb-3">
                    <Card.Body>
                        <Row>
                            <Col sm={12} lg={9}>
                                <TradingChart assetlist={assetsLiveList}/>
                            </Col>
                            <Col sm={12} lg={3}>
                                <Tabs
                                    defaultActiveKey="manual"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 border-0"
                                    fill
                                >
                                    <Tab eventKey="manual" title="manual">
                                        <Container>
                                            <div className="group-title mb-2">will the price go up or down?</div>
                                            <Row className="mb-3">
                                                <Col sm={6} md={6}>
                                                    <Button className="up-btn rounded-5 w-100 mb-2">UP</Button>
                                                </Col>
                                                <Col sm={6} md={6}>
                                                    <Button className="down-btn rounded-5 w-100 mb-2">down</Button>
                                                </Col>
                                            </Row>
                                            <div className="group-title mb-2">wager</div>
                                            <Row className="mb-3">
                                                <Col sm={12} md={12}>
                                                    <InputGroup className="border border-1 rounded-5 border-white overflow-hidden bg-transparent p-1">
                                                        <Button variant="outline-secondary" className="rounded-5 px-3 py-0">1/2</Button>
                                                        <Form.Control className="bg-transparent border-0 text-center" disabled value={10.00} />
                                                        <Button variant="outline-secondary" className="rounded-5 px-3 py-0">x2</Button>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <div className="group-title mb-2">payout multiplier</div>
                                            <Row className="mb-3">
                                                <Col sm={7} md={7}>
                                                    <InputGroup className="border rounded-5 border-white">
                                                        <InputGroup.Text id="basic-addon1" className="bg-transparent border-0">x</InputGroup.Text>
                                                        <Form.Control
                                                            className="bg-transparent border-0 text-center"
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            value={rangeValue}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                                <Col sm={5} md={5} className="bust_price">
                                                    <div className="text-end">BUST PRICE:</div>
                                                    <div className="text-end">{(rangeValue * 2248.94).toFixed(2)}</div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} md={12} className="mb-3 payout_range_body">
                                                    <input type="range" min="1" max="100" step={1} value={rangeValue} onChange={(e) => setRangeValue(parseFloat(e.target.value))} className="payout_range" id="myRange"></input>
                                                    <div className="d-flex align-items-center justify-content-between mt-1">
                                                        <span>x1 ∙ <strong className="safe_color">SAFE</strong></span>
                                                        <span><strong className="wild_color">WILD</strong> ∙ x1000</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} md={12}>
                                                    <MainButton title="place trade" />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Tab>
                                </Tabs>
                            </Col>
                            <Col sm={12} lg={12} >
                                <Card className="border-white border bg-primary-1 px-3">
                                    <Card.Body className="overflow-hidden">
                                        <div className="horizontal-scrolling-items">
                                            {/* {assetsLiveRate.map((item, index) => (
                                                <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> {item.symbol} </strong><span className="text-success">{item.rate}</span></span></div>
                                            ))} */}
                                            {Object.entries(assetsLiveRate).map(([symbol, rate]) => (
                                                <div className="horizontal-scrolling-items__item me-5 text-uppercase" key={symbol}><span><span className={`me-1 ${rateChanges[symbol] === 'red' ? 'text-danger' : 'text-success'}`}>{rateChanges[symbol] === "red" ? "↘" : "↗" }</span><strong className="text-white"> {symbol} </strong><span className={`me-1 ${rateChanges[symbol] === 'red' ? 'text-danger' : 'text-success'}`}>{rate}</span></span></div>
                                            ))}

                                            {/* <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-danger">↘</span><strong> BSP </strong><span className="text-danger">4.4624</span></span></div>
                                            <div className="horizontal-scrolling-items__item me-5"><span><span className="me-1 text-success">↗</span><strong> BSP </strong><span className="text-success">4.4624</span></span></div> */}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Tabs
                    defaultActiveKey="active_trade"
                    id="uncontrolled-tab-example"
                    className="mb-3 border-0"
                >
                    <Tab eventKey="active_trade" title="ACTIVE Trade">
                        <ActiveTradeTable />
                    </Tab>
                    <Tab eventKey="closed_trade" title="CLOSED Trade">
                        <ActiveTradeTable />
                    </Tab>
                    <Tab eventKey="leaderboard" title="LEADERBOARD">
                        <ActiveTradeTable />
                    </Tab>
                </Tabs>
            </Container>
        </div>
    )
}

export default TradingPage;