import ColorLogo from './images/color-logo.svg';
import WhiteLogo from './images/white-logo.svg';
import SymbolLogo from './images/symbol-logo.svg';
import WavaImage1 from './images/banners/wave-1.png';
import WavaImage2 from './images/banners/wave-2.png';
import MainCardBg1 from './images/banners/trade.jpg';
import MainCardBg2 from './images/banners/crash.jpg';
import MainCardBg3 from './images/banners/defi.jpg';
import MainCardBg4 from './images/banners/nft-game.jpg';
import DefiCardIcon1 from './images/banners/defi-1.svg';
import DefiCardIcon2 from './images/banners/defi-2.svg';
import DefiCardIcon3 from './images/banners/defi-3.svg';
import MysteryBoxImage from './images/banners/mysterybox.svg';
import MysteryBoxBg from './images/banners/mysterybox-bg.svg';
import CarouselImage1 from './images/carousels/1.jpg';
import GameFiCardIcon1 from './images/banners/gamefi-1.svg';
import GameFiCardIcon2 from './images/banners/gamefi-2.svg';
import GameFiCardIcon3 from './images/banners/gamefi-3.svg';
import LamboImage from './images/banners/lambo.png';
import AvatarImage1 from './images/avatars/1.png';
import AvatarImage2 from './images/avatars/2.png';
import AvatarImage3 from './images/avatars/3.png';
import AvatarImage4 from './images/avatars/4.png';
import AvatarImage5 from './images/avatars/5.png';
import AvatarImage6 from './images/avatars/6.png';
import AvatarImage7 from './images/avatars/7.png';
import AvatarImage8 from './images/avatars/8.png';
import AvatarImage9 from './images/avatars/9.png';
import AvatarImage10 from './images/avatars/10.png';
import UsdtIcon from './images/icons/coins/usdt.svg';
import BnbIcon from './images/icons/coins/bnb.svg';
import BananaTIcon from './images/icons/coins/banana.svg';
import BTCIcon from './images/icons/coins/btc.svg';
import DaiIcon from './images/icons/coins/dai.svg';
import EthIcon from './images/icons/coins/eth.svg';
import LooksTIcon from './images/icons/coins/looks.svg';
import MaticTIcon from './images/icons/coins/matic.svg';
import NDaiTIcon from './images/icons/coins/ndai.svg';
import NUsdcIcon from './images/icons/coins/nusdc.svg';
import WEtchIcon from './images/icons/coins/weth.svg';
import BspIcon from './images/icons/coins/bsp.svg';
import XBspIcon from './images/icons/coins/xbsp.svg';
import GamefiBg1 from './images/banners/gamefi-1.png';
import GamefiBg2 from './images/banners/gamefi-2.png';
import GamefiBg3 from './images/banners/gamefi-3.png';
import GamefiBg4 from './images/banners/gamefi-4.png';
import GamefiBg5 from './images/banners/gamefi-5.png';
import GamefiBg6 from './images/banners/gamefi-6.png';
import GameCardImage1 from './images/banners/gamefi/dice.png';
import GameCardImage2 from './images/banners/gamefi/mine.png';
import GameCardImage3 from './images/banners/gamefi/snake.png';
import GameCardImage4 from './images/banners/gamefi/tetris.png';
import GameCardImage5 from './images/banners/gamefi/limbo.png';
import GameCardImage6 from './images/banners/gamefi/aviator.png';
import GameCardImage7 from './images/banners/gamefi/degen.png';
import HomeIcon from './images/icons/home.svg';
import TradeIcon from './images/icons/trade.svg';
import DefiIcon from './images/icons/defi.svg';
import CrashGameIcon from './images/icons/crash_game.svg';
import NFTGameIcon from './images/icons/nft_game.svg';
import MysteryBoxIcon from './images/icons/mystery_box.svg';
import HomeMainCardBg from './images/backgrounds/home.jpg';
import AppleIcon from './images/icons/apple.svg';
import FacebookIcon from './images/icons/facebook.svg';
import MetamaskIcon from './images/icons/metamask.svg';
import GoogleIcon from './images/icons/google.svg';
import ModalBannerImage from './images/banners/modal-banner.jpg';
import USFlagIcon from './images/icons/flags/us.svg';
import USDIcon from './images/icons/coins/usd.svg';
import EURIcon from './images/icons/coins/eur.svg';
import FOUNDIcon from './images/icons/coins/found.svg';
import WENIcon from './images/icons/coins/wen.svg';
import ClipBoardIcon from './images/icons/clipboard.svg';
import NonstopayImage from './images/icons/providers/nonstopay.svg';
import SimplexImage from './images/icons/providers/simplex.svg';
import QRCodeImage from './images/banners/qr.png';
import NFTImage1 from './images/banners/nfts/1.png';
import NFTImage2 from './images/banners/nfts/2.png';
import JackPortBanner from './images/banners/jackpot.png';
import DailyJackpotBanner from './images/banners/daily-jackpot.jpg';
import SpintBanner from './images/banners/spin.png';
import ChartButtonImage1 from './images/icons/trade/chart-1.svg';
import ChartButtonImage2 from './images/icons/trade/chart-2.svg';
import ChartButtonImage3 from './images/icons/trade/chart-3.svg';
import ExperienceIcon from './images/icons/experience.svg';
import CheckIcon from './images/icons/check.svg';
import SearchIcon from './images/icons/search.svg';
import GameIcon from './images/icons/game_icon.svg';
import UpIcon from './images/icons/up.svg'
import DownIcon from './images/icons/down.svg';
import CloseIcon from './images/icons/close.svg';
import FreeSpinLayout from './images/games/spin.png';
import ReferralBanner from './images/banners/referral.svg';
import ReferralRewardBanner from './images/banners/referral-reward.svg';
import ShareIcon from './images/icons/share.svg';
import VisaCardIcon from './images/icons/visa.svg';
import MasterCardIcon from './images/icons/mastercard.svg';
import SpinBg from './images/backgrounds/spin.jpg';

import DegenSlotNFT1 from './images/games/degen/nfts/1.png';
import DegenSlotNFT2 from './images/games/degen/nfts/2.png';
import DegenSlotNFT3 from './images/games/degen/nfts/3.png';
import DegenSlotNFT4 from './images/games/degen/nfts/4.png';
import DegenSlotNFT5 from './images/games/degen/nfts/5.png';
import DegenSlotNFT6 from './images/games/degen/nfts/6.png';
import DegenSlotNFT7 from './images/games/degen/nfts/7.png';
import DegenSlotNFT8 from './images/games/degen/nfts/8.png';
import DegenSlotNFT9 from './images/games/degen/nfts/9.png';
import DegenSlotNFT10 from './images/games/degen/nfts/10.png';
import FreeSpinTitle from './images/banners/free-spin-title.png';
import LamboJackpotImage from './images/banners/lambo-banner.webp';
import LamboJackpotImage1 from './images/banners/lambo-banner-1.webp';
import DailyJackpotSymbol from './images/banners/daily-jackpot-badge.svg';
import MenuIcon from './images/icons/menu.svg';
import ProfileIcon from './images/icons/profile.svg';
import DefaultAvatar from './images/avatars/default.png';
import BankRollImg from './images/banners/bankroll.png';
import GiftBanner from './images/banners/gift.png';
import BoostBanner from './images/banners/boost.png';
import InsuranceBanner from './images/banners/insurance.png';
import StarIcon from './images/icons/star.svg';
import UpVectorIcon from './images/icons/up-vector.svg';
import DownVectorIcon from './images/icons/down-vector.svg';


export {
    ColorLogo,
    WhiteLogo,
    SymbolLogo,
    WavaImage1,
    WavaImage2,
    MainCardBg1,
    MainCardBg2,
    MainCardBg3,
    MainCardBg4,
    DefiCardIcon1,
    DefiCardIcon2,
    DefiCardIcon3,
    MysteryBoxImage,
    MysteryBoxBg,
    CarouselImage1,
    GameFiCardIcon1,
    GameFiCardIcon2,
    GameFiCardIcon3,
    LamboImage,
    AvatarImage1,
    AvatarImage2,
    AvatarImage3,
    AvatarImage4,
    AvatarImage5,
    AvatarImage6,
    AvatarImage7,
    AvatarImage8,
    AvatarImage9,
    AvatarImage10,
    UsdtIcon,
    BnbIcon,
    BananaTIcon,
    BTCIcon,
    DaiIcon,
    EthIcon,
    LooksTIcon,
    MaticTIcon,
    NDaiTIcon,
    NUsdcIcon,
    WEtchIcon,
    BspIcon,
    XBspIcon,
    GamefiBg1,
    GamefiBg2,
    GamefiBg3,
    GamefiBg4,
    GamefiBg5,
    GamefiBg6,
    GameCardImage1,
    GameCardImage2,
    GameCardImage3,
    GameCardImage4,
    GameCardImage5,
    GameCardImage6,
    GameCardImage7,
    HomeIcon,
    TradeIcon,
    DefiIcon,
    CrashGameIcon,
    NFTGameIcon,
    MysteryBoxIcon,
    HomeMainCardBg,
    AppleIcon,
    FacebookIcon,
    MetamaskIcon,
    GoogleIcon,
    ModalBannerImage,
    USFlagIcon,
    USDIcon,
    EURIcon,
    FOUNDIcon,
    WENIcon,
    ClipBoardIcon,
    NonstopayImage,
    SimplexImage,
    QRCodeImage,
    NFTImage1,
    NFTImage2,
    JackPortBanner,
    SpintBanner,
    ChartButtonImage1,
    ChartButtonImage2,
    ChartButtonImage3,
    ExperienceIcon,
    CheckIcon,
    SearchIcon,
    GameIcon,
    UpIcon,
    DownIcon,
    CloseIcon,
    DegenSlotNFT1,
    DegenSlotNFT2,
    DegenSlotNFT3,
    DegenSlotNFT4,
    DegenSlotNFT5,
    DegenSlotNFT6,
    DegenSlotNFT7,
    DegenSlotNFT8,
    DegenSlotNFT9,
    DegenSlotNFT10,
    FreeSpinLayout,
    ReferralBanner,
    ReferralRewardBanner,
    ShareIcon,
    VisaCardIcon,
    MasterCardIcon,
    DailyJackpotBanner,
    LamboJackpotImage,
    LamboJackpotImage1,
    DailyJackpotSymbol,
    SpinBg,
    FreeSpinTitle,
    MenuIcon,
    ProfileIcon,
    DefaultAvatar,
    BankRollImg,
    GiftBanner,
    BoostBanner,
    InsuranceBanner,
    StarIcon,
    UpVectorIcon,
    DownVectorIcon
}

export const videoPath = require('./images/backgrounds/home.mp4');
export const lamboPath = require('./images/backgrounds/lambo.mp4');