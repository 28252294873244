import { io } from "socket.io-client";

const SERVER_URL = 'https://api.dev.bitnswap.com:3103';
const NAMESPACE = '/front-end';

const token = window.localStorage.getItem('token');
console.log("==================================================", token)

const socketOptions: any = {
    autoConnect: true,
    reconnectionDelayMax: 1000,
    extraHeaders : {
        token: `${token}`
    }
};

export const socket = io(SERVER_URL + NAMESPACE, socketOptions);