import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Image, Row, ProgressBar } from "react-bootstrap";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import axios from "axios";
import { socket } from "../../utils/socket";
import { useMainContext } from "../../context/mainContext";
import { toast } from "react-toastify";
import { DownIcon, StarIcon, UpIcon } from "../../assets";

export interface ActiveTradeListItemProps {
    account_type: string;
    amount_bet: number;
    assetCode: string;
    asset_id: number;
    begin_rate: number;
    choice: string;
    client_firstname: string;
    client_id: number;
    client_lastname: string;
    currency: string;
    dashboard: number;
    date: string
    dateTimestamp: number;
    date_close: string;
    duration: number;
    id: number;
    info?: string;
    label: string;
    leverage: number;
    maxSecondsEnd: number;
    multiplication: number;
    origin: string;
    payout_lose: number;
    payout_win: number;
    pnl_ac: number;
    profitLossPts?: any;
    risk_applied: number;
    status: string;
    stopLossRate?: any;
    stopLoss_rate?: any;
    swap: number;
    takeProfit_rate?: any;
    volume: number;
}

interface DurationItemProps {
    id: number;
    label: string;
    duration: string;
    duration_minimun: string;
    is_default: string;
}

interface ChartDataItem {
    date: number;
    value: number;
    bullet?: boolean;
    range?: boolean;
}

interface PayoutProps {
    id: string;
    lose: string;
    win: string;
}

interface AssetListItemProps {
    assetId: string;
    assetCode: string;
    label: string;
    ico_1: string;
    ico_2: string;
    is_default: string;
    position: string;
    payouts: PayoutProps[];
}

interface DataItem {
    h: string;
    t: string;
    v: string;
}

const API_URL = process.env.REACT_APP_API_URL;


// const CryptoPredictionChart: React.FunctionComponent<{ handleActiveTradeTableData: (data: ActiveTradeListItemProps[]) => void }> = (props) => {
const CryptoPredictionChart: React.FunctionComponent = () => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const { token, userInfo } = useMainContext();
    const [durationList, setDurationList] = useState<DurationItemProps[] | null>(null);
    const [endTradeTime, setEndendTradeTime] = useState<any | null>(null);
    const [selectDuration, setSelectDuration] = useState<DurationItemProps | null>(null);
    const [chartData, setChartData] = useState<any | null>(null);
    const [selectAsset, setSelectAsset] = useState<AssetListItemProps | null>(null);
    const [assetLastValue, setAssetLastValue] = useState<any | null>(null);
    const [assetsLiveRate, setAssetsLiveRate] = useState<{ [key: string]: string }>({});
    const [assetList, setAssetList] = useState<any>({});
    const [amount, setAmount] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    const [status, setStatus] = useState<boolean>(false);
    const [time, setTime] = useState<number>(0);
    const [progress, setProgress] = useState<number>(100);
    const [favAsset, setFavAsset] = useState<string[] | null>(null);


    const fetchData = async () => {
        try {
            const headers = { contentType: 'application/json', Authorization: token, token: token };
            await axios.get(`${process.env.REACT_APP_API_URL}/trade/get-datetime-endtrade/`, { headers })
                .then(function (response) {
                    setEndendTradeTime(response.data);
                    // window.localStorage.setItem("endTime", JSON.stringify(response.data.datetimeEndTrade.timestamp))
                })
                .catch(function (error) {
                    console.log(error);
                });
            await axios.get(`${process.env.REACT_APP_API_URL}/trade/durations/list/`, { headers })
                .then(function (response) {
                    setDurationList(response.data.records);
                    const defaultDuration = (response.data.records).find((row: any) => row.is_default === "1");
                    setSelectDuration(defaultDuration);
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {

        }
    };

    const setFavAssetList = () => {
        let favList: any = window.localStorage.getItem("favAsset");
        if (favList) {
            let _favList = JSON.parse(favList);
            const frequencyCount = _favList.reduce((acc: any, variable: any) => {
                acc[variable] = (acc[variable] || 0) + 1;
                return acc;
            }, {});
            const frequencyArray = Object.entries(frequencyCount);
            frequencyArray.sort((a: any, b: any) => b[1] - a[1]);
            const top4Variables = frequencyArray.slice(0, 4).map(entry => entry[0]);

            setFavAsset(top4Variables);

            // const sortedKeys = Object.keys(assetList).sort((a:any, b:any) => {
            //     const indexA = top4Variables.indexOf(assetList[a].assetCode);
            //     const indexB = top4Variables.indexOf(assetList[b].assetCode);
            //     if (indexA === -1 && indexB === -1) return a - b; // If both keys not found in first array, keep their original order
            //     if (indexA === -1) return 1; // If only A is not found, move it down
            //     if (indexB === -1) return -1; // If only B is not found, move it down
            //     return indexA - indexB; // Otherwise, sort based on their index in first array
            // });

            // const sortedSecond:any = {};
            // sortedKeys.forEach(key => {
            //     sortedSecond[key] = assetList[key];
            // });

            // console.log("================00000000000000000000000================",sortedSecond);
        }

    }

    const selectAssetList = (assetId: string, assetCode: string, label: string, ico_1: string, ico_2: string, is_default: string, payouts: PayoutProps[], position: string) => {
        setSelectAsset({ assetId, assetCode, label, ico_1, ico_2, is_default, payouts, position });
        console.log({ assetId, assetCode, label, ico_1, ico_2, is_default, payouts, position })
        let favList = window.localStorage.getItem("favAsset");

        if (favList) {
            const _favList = JSON.parse(favList)
            _favList.push(assetCode);
            window.localStorage.setItem("favAsset", JSON.stringify(_favList))
            setFavAssetList();
        }
        else {
            window.localStorage.setItem("favAsset", JSON.stringify([assetCode]))
        }
        socket.emit("subscribe-asset", { assetId, assetCode });
        socket.on("asset-history", (response: any) => {
            console.log("ddd----dddd", response)

            const chartData: any =

                response.datas.map((row: DataItem): ChartDataItem => ({
                    date: parseInt(row.t) * 1000, // Convert Unix timestamp to milliseconds
                    value: parseFloat(row.v) // Parse value to float
                }));

            const _data: any = chartData.slice(-150);
            setChartData(_data as any);
        });

        return () => {
            socket.off("asset-history", (response: any) => {
                console.log("off-asset-history", response);
            });
        };
    };

    const getTradingEndTime = async () => {
        try {
            const headers = { contentType: 'application/json', Authorization: token, token: token };
            await axios.get(`${process.env.REACT_APP_API_URL}/trade/get-datetime-endtrade/`, { headers })
                .then(function (response) {
                    setEndendTradeTime(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    }

    const handleImageError = (event: any) => {
        event.target.src = 'https://api.dev.bitnswap.com/design/img/generic.svg';
    };

    const startTrading = (type: string) => {
        if (!token) {
            toast.error("You can trade after logging into this platform");
            return
        }
        const params = {
            asset_id: parseInt(selectAsset?.assetId as string),
            client_id: parseInt(userInfo.id as string),
            amount: parseFloat(amount as any),
            currentRate: parseFloat(assetsLiveRate[selectAsset?.assetCode as string] as any),
            choice: type,
            duration: parseInt(selectDuration?.duration as any),
            payout_id: parseInt(selectAsset?.payouts[0].id as any),
            currency: "USD"
        };

        socket.emit("take-trade-binary", params);
        console.log("params", params)
    };

    const getAssetsLiveRate = () => {
        socket.on("asset-live-rate", (data: any) => {
            const parsedData = JSON.parse(data);
            delete parsedData['jsonbegin'];
            delete parsedData['jsonend'];
            setAssetsLiveRate(parsedData);
            window.localStorage.setItem("live-rate", parsedData)
        });
    }

    const getAssetsLiveListChange = () => {
        socket.on("assets-live-list-change", (data: any) => {
            setAssetList(data)
            const defaultAsset: any = Object.entries(data).find((row: any) => row[1].assetCode === "btcusdt");
            selectAssetList(defaultAsset[1]?.assetId, defaultAsset[1]?.assetCode, defaultAsset[1]?.label, defaultAsset[1]?.ico_1, defaultAsset[1]?.ico_2, defaultAsset[1]?.is_default, [{ id: defaultAsset[1]?.payouts[0].id, win: defaultAsset[1]?.payouts[0].win, lose: defaultAsset[1]?.payouts[0].loss }], defaultAsset[1]?.position);
        });
    }

    const getOpenTradeList = () => {
        socket.on("open-positions-change", (data: any) => {
            console.log("open-positions-change")
            // props.handleActiveTradeTableData(data[selectDuration?.duration === undefined ? "900" : selectDuration?.duration])
        })
    }

    useEffect(() => {
        getAssetsLiveRate();
        getAssetsLiveListChange();
        getOpenTradeList();
        return () => {
            // Clean up socket listeners when component unmounts
            socket.off("asset-live-rate");
            socket.off("assets-live-list-change");
            socket.off("open-positions-change");
        };
    }, []);

    useEffect(() => {
        // Calculate the end time based on selected duration
        setDisabled(false);
        const adonisTime: number = endTradeTime?.datetimeEndTrade.timestamp[selectDuration?.duration as string];

        // Set up the interval to update the time every second
        const intervalId = setInterval(() => {
            const currentTimestamp: number = Math.floor(Date.now() / 1000);
            let _time = adonisTime - currentTimestamp;
            if (!isNaN(_time)) {
                if (_time >= 0) {
                    if (_time <= parseInt(selectDuration?.duration_minimun as string)) {
                        setDisabled(true);
                    }
                    if (_time === 0) {
                        getTradingEndTime();
                        setDisabled(false);
                    }
                    if (_time >= 0) {
                        setTime(_time);
                        let percent: number = (_time / parseInt(selectDuration?.duration as string) * 100);
                        setProgress(percent);
                        return;
                    }
                } else {
                    clearInterval(intervalId); // Clear the interval when the time is negative
                }

            }
        }, 1000);

        // Clean up the interval when the component unmounts or when selectDuration changes
        return () => {
            clearInterval(intervalId);
        };
    }, [selectDuration, endTradeTime]);

    useEffect(() => {
        fetchData();
    }, []);



    useEffect(() => {
        let root = am5.Root.new("chartdiv");
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let value = 100;

        function generateChartData(): ChartDataItem[] {
            let chartData: ChartDataItem[] = [];
            let firstDate = new Date();
            firstDate.setDate(firstDate.getDate() - 1000);
            firstDate.setHours(0, 0, 0, 0);

            for (let i = 0; i < 50; i++) {
                let newDate = new Date(firstDate);
                newDate.setSeconds(newDate.getSeconds() + i);

                value += (Math.random() < 0.5 ? 1 : -1) * Math.random() * 10;

                chartData.push({
                    date: newDate.getTime(),
                    value: value,
                });
            }
            return chartData;
        }

        let data = generateChartData();


        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            focusable: true,
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: false,
        }));

        let easing = am5.ease.linear;

        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.5,
            groupData: false,
            extraMax: 0.15,
            extraMin: -0.3,
            baseInterval: {
                timeUnit: "second",
                count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
                cellStartLocation: 0.2,
                cellEndLocation: 0.8
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                extraTooltipPrecision: 1,
                renderer: am5xy.AxisRendererY.new(root, {
                    minGridDistance: 30
                })
            })
        );

        let series = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
            name: "Series 1",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{valueY}"
            })
        }));

        series.strokes.template.setAll({
            strokeWidth: 2,
            stroke: am5.color(0xffffff),
            shadowColor: am5.color(0x000000),
            shadowBlur: 10,
            shadowOffsetX: 10,
            shadowOffsetY: 10,
            shadowOpacity: 0.7
        });

        series.fills.template.setAll({
            visible: false,
            fillOpacity: 1,
        });

        series.data.setAll(chartData === null ? data : chartData as any);
        // series.data.setAll(data);

        xAxis.get("renderer").labels.template.setAll({
            fill: root.interfaceColors.get("alternativeText")
        });
        yAxis.get("renderer").labels.template.setAll({
            fill: root.interfaceColors.get("alternativeText")
        });
        root.interfaceColors.set("grid", am5.color(0xffffff));


        series.bullets.push(function (root, series, dataItem: any) {
            if (dataItem.dataContext.bullet) {
                let container = am5.Container.new(root, {});
                let circle0 = container.children.push(am5.Circle.new(root, {
                    radius: 5,
                    fill: am5.color(0xffffff)
                }));
                let circle1 = container.children.push(am5.Circle.new(root, {
                    radius: 5,
                    fill: am5.color(0xffffff)
                }));

                circle1.animate({
                    key: "radius",
                    to: 20,
                    duration: 1000,
                    easing: am5.ease.out(am5.ease.cubic),
                    loops: Infinity
                });
                circle1.animate({
                    key: "opacity",
                    to: 0,
                    from: 1,
                    duration: 500,
                    easing: am5.ease.out(am5.ease.cubic),
                    loops: Infinity
                });

                return am5.Bullet.new(root, {
                    locationX: undefined,
                    sprite: container
                })
            }
        })

        let rangeDataItem: any = yAxis.makeDataItem({ value: price, endValue: 0 });
        yAxis.createAxisRange(rangeDataItem);

        rangeDataItem.get("grid").setAll({
            stroke: am5.color(0xffffff),
            strokeOpacity: 1,
            strokeDasharray: [5]
        });

        ////////////////////////////////////////////////
        var rangeDataItemUp: any;
        var rangeDataItemDown: any;

        series.events.on("datavalidated", () => {
            let lastDataItem: any = series.dataItems[series.dataItems.length - 1];
            let lastValue = lastDataItem.get("valueY");
            rangeDataItem.set("value", lastValue);
            setPrice((prevPrice: number) => {
                if (prevPrice > lastValue) {
                    setStatus(false);
                } else {
                    setStatus(true);
                }

                return lastValue
            });



            yAxis.axisRanges.removeValue(rangeDataItemUp);

            rangeDataItemUp = yAxis.makeDataItem({
                value: lastValue,
                endValue: lastValue * 2
            });

            yAxis.createAxisRange(rangeDataItemUp);

            rangeDataItemUp.get("grid").setAll({
                stroke: am5.color(0x00ff33),
                strokeOpacity: 0.4,
                strokeDasharray: [3]
            });

            rangeDataItemUp.get("axisFill").setAll({
                fill: am5.color(0x00ff33),
                fillOpacity: 0.25,
                visible: true
            });
            yAxis.axisRanges.removeValue(rangeDataItemDown);

            rangeDataItemDown = yAxis.makeDataItem({
                value: 0,
                endValue: lastValue
            });

            yAxis.createAxisRange(rangeDataItemDown);

            rangeDataItemDown.get("grid").setAll({
                stroke: am5.color(0x00ff33),
                strokeOpacity: 0.5,
                strokeDasharray: [3]
            });

            rangeDataItemDown.get("axisFill").setAll({
                fill: am5.color(0xff4949),
                fillOpacity: 0.25,
                visible: true
            });
        })
        ///////////////////////////////////////////////


        let range: any = series.createAxisRange(rangeDataItem);

        // range.strokes.template.setAll({
        //     stroke: am5.color(0xff4949),
        //     strokeWidth: 3
        // });


        range.fills.template.setAll({
            fill: am5.color(0xff4949),
            fillOpacity: 0.5,
            visible: false
        });

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis
        }));
        cursor.lineY.set("visible", true);

        setInterval(() => {
            addData();
        }, 300)

        function addData() {
            socket.on("asset-history-last-value", (response: any) => {
                console.log("asset-history-last-value", response)
                let lastDataItem: any = series.dataItems[series.dataItems.length - 1];
                let lastValue = lastDataItem.get("valueY");
                let lastDate = new Date(lastDataItem.get("valueX") as any);
                let time = am5.time.add(new Date(lastDate), "second", 1).getTime();

                series.data.pop(); // Remove the last element
                series.data.shift(); // Remove the first element

                series.data.push(
                    {
                        date: time,
                        value: lastValue,
                        bullet: false // Set bullet property to false for the new data item
                    }
                );
                series.data.push(
                    {
                        date: parseInt(response.graph.t) * 1000,
                        value: response.graph.v,
                        bullet: true // Set bullet property to true for the new data item
                    }
                );


                let newDataItem: any = series.dataItems[series.dataItems.length - 1];
                newDataItem.animate({
                    key: "valueYWorking",
                    to: response.graph.v,
                    from: lastValue,
                    duration: 300,
                    easing: easing
                });

                let animation = newDataItem.animate({
                    key: "locationX",
                    to: 0.5,
                    from: -0.5,
                    duration: 500
                });
                if (animation) {
                    let tooltip = xAxis.get("tooltip");
                    if (tooltip && !tooltip.isHidden()) {
                        animation.events.on("stopped", function () {
                            xAxis.updateTooltip();
                        })
                    }
                }


            });

            return () => {
                socket.off("asset-history-last-value", (response: any) => {
                    console.log("asset-history-last-value", response);
                });
            };
        }

        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [chartData, selectAsset]);

    return (
        <div className="FastAndFuriousChart">
            <Row>
                <Col xs={6} lg={4}>
                    <Row>
                        <Col sm={6} className="d-none d-lg-block">
                            <div className="fast-chart-info mb-3">
                                <label>YOUR INVESTMENT</label>
                                <div className="fast-chart-info-value">{amount}</div>
                            </div>
                            <div className="fast-chart-info mb-3">
                                <label>POTENTIAL RETURN</label>
                                <div className="fast-chart-info-value">190.0</div>
                            </div>

                        </Col>
                        <Col sm={12} lg={4}>
                            <div className="d-flex align-items-center h-100  text-center ">
                                <div className="fast-chart-info mb-3">
                                    {/* <label >DOWN POOL PAYOUT</label> */}
                                    <div className="fast-chart-info-value down-pool text-center fs-1">1.9x</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={4} className="d-none d-lg-flex align-items-center">
                    <div className="mx-auto mb-2">
                        <Dropdown data-bs-theme="dark" className="text-center fast-chart-coin-dropdown d-flex align-items-center justify-content-center">
                            {selectAsset === null ? (
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="d-flex align-items-center p-0 bg-transparent border border-white rounded-5 pt-1 ps-3 pe-2 fs-4">Select Asset</Dropdown.Toggle>
                            ) : (
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="asset-list-selector d-flex align-items-center p-0 bg-transparent border border-0 rounded-5 py-2 px-2">
                                    <div className="position-relative">
                                        <div className="bg-white rounded-circle d-flex align-items-center justify-content-center text-center asset-list-selector-img" style={{ width: 130, height: 130 }}>
                                            <Image className="rounded-circle" src={`https://api.dev.bitnswap.com/design/img/${selectAsset?.ico_1 === "" ? "generic.svg" : ("/assets/" + selectAsset?.ico_1)}`} width={110} height={110} alt="coin" onError={handleImageError} />
                                        </div>
                                        <div className="position-absolute asset-list-component w-100">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <Image src={status === true ? UpIcon : DownIcon} height={20} alt="coin" className="mx-2 " onError={handleImageError} />
                                                <div className="selected-asset-price" style={{ color: status === true ? "#72f238" : "#ff4949" }}>{price.toLocaleString()}</div>
                                                <Image src={status === true ? UpIcon : DownIcon} height={20} alt="coin" className="mx-2" onError={handleImageError} />
                                            </div>
                                        </div>
                                        <div className="position-absolute w-100 selected-asset-label">{selectAsset.label}</div>
                                    </div>
                                </Dropdown.Toggle>
                            )}

                            <Dropdown.Menu className="bg-primary-1">
                                {Object.entries(assetList).map((item: any, index: any) => {
                                    return (
                                        <Dropdown.Item className="d-flex align-items-center justify-content-between" key={index} >
                                            <div className="d-flex align-items-center me-2" onClick={() => selectAssetList(item[1].assetId, item[1].assetCode, item[1].label, item[1].ico1, item[1].ico2, item[1].isDefault, item[1].payouts, item[1].position,)}>
                                                <div className="me-2">
                                                    <Image src={`https://api.dev.bitnswap.com/design/img/${item[1].ico1 === "" ? "generic.svg" : ("/assets/" + item[1].ico1)}`} width={30} height={30} alt="coin" className="me-2 rounded-circle" onError={handleImageError} />
                                                </div>
                                                <div className="text-start">
                                                    <div className="token_name">{item[1].label} </div>
                                                </div>
                                            </div>
                                            <div >
                                                {favAsset?.map((fav, j) => (
                                                    <Image className={`${fav !== item[1]?.assetCode && "d-none"}`} src={StarIcon} height={20} />
                                                ))}
                                            </div>
                                        </Dropdown.Item>
                                    )
                                })}

                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>
                <Col xs={6} lg={4}>
                    <Row>
                        <Col sm={6}>
                            <div className="d-flex align-items-center justify-content-end h-100  text-center ">
                                <div className="fast-chart-info mb-3">
                                    {/* <label>UP POOL PAYOUT</label> */}
                                    <div className="fast-chart-info-value up-pool fs-1">2.5x</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} className="d-none d-lg-block">
                            <div className="fast-chart-info mb-3 text-end">
                                <label>YOUR INVESTMENT</label>
                                <div className="fast-chart-info-value">{amount}</div>
                            </div>
                            <div className="fast-chart-info mb-3 text-end">
                                <label>POTENTIAL RETURN</label>
                                <div className="fast-chart-info-value">190.0</div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={4} className="d-lg-none d-inline">
                    <div className="mx-auto mb-3">
                        <Dropdown data-bs-theme="dark" className="text-center fast-chart-coin-dropdown d-flex align-items-center justify-content-center">
                            {selectAsset === null ? (
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="d-flex align-items-center p-0 bg-transparent border border-white rounded-5 pt-1 ps-3 pe-2 fs-4">Select Asset</Dropdown.Toggle>
                            ) : (
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="asset-list-selector d-flex align-items-center p-0 bg-transparent border border-0 rounded-5 py-2 px-2">
                                    <div className="position-relative">
                                        <div className="bg-white rounded-circle d-flex align-items-center justify-content-center text-center asset-list-selector-img" style={{ width: 130, height: 130 }}>
                                            <Image className="rounded-circle" src={`https://api.dev.bitnswap.com/design/img/${selectAsset?.ico_1 === "" ? "generic.svg" : ("/assets/" + selectAsset?.ico_1)}`} width={110} height={110} alt="coin" onError={handleImageError} />
                                        </div>
                                        <div className="position-absolute asset-list-component w-100">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <Image src={status === true ? UpIcon : DownIcon} height={20} alt="coin" className="mx-2 " onError={handleImageError} />
                                                <div className="selected-asset-price" style={{ color: status === true ? "#72f238" : "#ff4949" }}>{price.toLocaleString()}</div>
                                                <Image src={status === true ? UpIcon : DownIcon} height={20} alt="coin" className="mx-2" onError={handleImageError} />
                                            </div>
                                        </div>
                                    </div>
                                </Dropdown.Toggle>
                            )}

                            <Dropdown.Menu className="bg-primary-1">
                                {/* {Object.entries(assetList).map((item: any, index: any) => {
                                    return (
                                        <Dropdown.Item className="d-flex align-items-center justify-content-between" key={index} >
                                            <div className="d-flex align-items-center me-2" onClick={() => selectAssetList(item[1].assetId, item[1].assetCode, item[1].label, item[1].ico1, item[1].ico2, item[1].isDefault, item[1].payouts, item[1].position,)}>
                                                <div className="me-2">
                                                    <Image src={`https://api.dev.bitnswap.com/design/img/${item[1].ico1 === "" ? "generic.svg" : ("/assets/" + item[1].ico1)}`} width={30} height={30} alt="coin" className="me-2 rounded-circle" onError={handleImageError} />
                                                </div>
                                                <div className="text-start">
                                                    <div className="token_name">{item[1].label}</div>
                                                </div>
                                            </div>
                                            <div >
                                                {favAsset?.map((fav, j) => (
                                                    <Image className={`${fav !== item[1]?.assetCode && "d-none"}`} src={StarIcon} height={20} />
                                                ))}
                                            </div>
                                        </Dropdown.Item>
                                    )
                                })} */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
            <Row className="align-items-center justify-content-between">
                <Col xs={12} lg={1}>
                    <div className="fast-chart-info text-center text-md-start">
                        <div className="fast-chart-info-value">{Math.floor(time / 60) <= 9 ? ("0" + Math.floor(time / 60)) : (Math.floor(time / 60))}:{Math.floor(time % 60) <= 9 ? ("0" + Math.floor(time % 60)) : (Math.floor(time % 60))}</div>
                    </div>
                </Col>
                <Col xs={8} lg={10}>
                    <ProgressBar animated variant={`${time <= parseInt(selectDuration?.duration_minimun as string) ? "danger" : "success"}`} now={progress} />
                </Col>
                <Col xs={3} lg={1}>
                    <Dropdown data-bs-theme="dark" className="text-center fast-chart-coin-dropdown d-flex align-items-center justify-content-end">
                        <Dropdown.Toggle className="bg-transparent border border-0 fs-5">
                            {selectDuration?.label}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {durationList?.sort().map((item, index) => (
                                <Dropdown.Item className="text-center text-uppercase" key={index} onClick={() => setSelectDuration(item)}>{item.label}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={12} className="mb-3">
                    <div className="position-relative border border-white rounded-3 border-2 overflow-hidden">
                        <div id="chartdiv" style={{ width: "100%", height: "350px" }} className="fast-chart  p-0"></div>
                        <div className="position-absolute end-0 bottom-0 h-100 w-25">
                            <div className="d-flex align-items-center justify-content-center h-100 chart-multiplier rw-wrapper">
                                <div className="rw-words">
                                    <span>
                                        2.5x
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col xs={6} lg={2} className="d-none d-lg-block"><Button className="w-100 fast-chart-down-btn text-white border-0 py-3 rounded-5" disabled={disabled} onClick={() => startTrading("PUT")}>DOWN</Button></Col>
                <Col xs={12} lg={8} className="mb-2">
                    <Row>
                        <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 1} disabled={disabled} onClick={() => setAmount(1)}>1</Button></Col>
                        <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 5} disabled={disabled} onClick={() => setAmount(5)}>5</Button></Col>
                        <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 10} disabled={disabled} onClick={() => setAmount(10)}>10</Button></Col>
                        <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 100} disabled={disabled} onClick={() => setAmount(100)}>100</Button></Col>
                        <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 999} disabled={disabled} onClick={() => setAmount(999)}>Max</Button></Col>
                        <Col xs={2} className="d-none d-lg-inline"><Button className="w-100 my-2 bg-transparent rounded-5  fast-chart-sm-button" active={amount === 0} disabled={disabled} onClick={() => setAmount(0)}>Remove</Button></Col>
                        <Col xs={2} className="d-lg-none d-inline"><Button className="w-100 my-2 bg-transparent rounded-5  fast-chart-sm-button" active={amount === 0} disabled={disabled} onClick={() => setAmount(0)}>Del</Button></Col>
                    </Row>
                </Col>
                <Col xs={6} lg={2} className="d-lg-none d-block"><Button className="w-100 fast-chart-down-btn rounded-5 text-white border-0 py-3" disabled={disabled} onClick={() => startTrading("PUT")}>DOWN</Button></Col>
                <Col xs={6} lg={2}><Button className="w-100 fast-chart-up-btn text-white py-3 rounded-5" disabled={disabled} onClick={() => startTrading("CALL")}>UP</Button></Col>
            </Row>
        </div>
    )
}

export default CryptoPredictionChart;