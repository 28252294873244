import { CrashGameIcon, DefiIcon, HomeIcon, NFTGameIcon, TradeIcon } from "../assets";

export const Routers = [
    {
        path: '/', 
        title: "Dashboard", 
        icon: HomeIcon, 
        subMenus: []
    },
    {
        path: '/crypto_trade', 
        title: "Trade", 
        icon: TradeIcon,
        subMenus: [
        //    { path: '/crypto_trade/futures', title: "Crypto Futures" },
           { path: '/crypto_trade/prediction', title: "Crypto Prediction" },
        //    { path: '/crypto_trade/fast', title: "Fast and Furious" },
        //    { path: '/crypto_trade/nft', title: "NFT prediction" },
        ]
    },
    {path: '/original_games', title: "Original games", icon: CrashGameIcon, subMenus: []},
    // {path: '/defi', title: "Difi", icon: DefiIcon, subMenus: []},
    // {path: '/crash_game', title: "Play", icon: NFTGameIcon, subMenus: []},
    // {path: '/crash_game', title: "Marketplace", icon: NFTGameIcon, subMenus: []},
];