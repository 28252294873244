import React, { useEffect, useState } from "react";
import { Button, Container, Dropdown, Image, Offcanvas, Nav, Navbar, Row, Col, Card, Form, InputGroup, NavItem } from "react-bootstrap";
import { AvatarImage1, BoostBanner, BspIcon, CheckIcon, ClipBoardIcon, ColorLogo, DefaultAvatar, ExperienceIcon, GameCardImage1, GamefiBg1, GiftBanner, InsuranceBanner, ReferralBanner, ReferralRewardBanner, ShareIcon, SymbolLogo, USFlagIcon } from "../../assets";
import { MainButton, OutlineButton } from "../buttons/buttons";
import { DepositModal, ForgetPasswordModal, FreeSpinModal, SignInModal, SignUpModal } from "../modals/modals";
import { useMainContext } from "../../context/mainContext";
import { Line } from "rc-progress";
import { MenuChart } from "../charts/tradingCharts";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";

export const Header: React.FunctionComponent = () => {
    const { setSignupModal, balance, getBalance, setDepositModal, userSignOut, refId, token, userInfo } = useMainContext();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (!token) {
            return
        } else {
            getBalance();
            setInterval(() => {
                getBalance();
            }, 60000);
        }
    }, []);

    return (
        <Navbar collapseOnSelect expand="lg" className="Header z-2">
            <Container fluid className="flex-nowrap">
                <NavLink to="/">
                    <Image src={ColorLogo} height={40} alt="logo" className="d-none d-md-block" />
                    <Image src={SymbolLogo} height={40} alt="logo" className="d-md-none d-block" />
                </NavLink>
                {!token ? (
                    <Nav className="d-flex flex-row align-items-center d-lg-none">
                        <span onClick={() => { setSignupModal(true) }}><OutlineButton title="Sign in/up" /></span>
                    </Nav>
                ) : (
                    <>
                    <Nav className="ms-auto d-md-flex align-items-center justify-content-end w-50 d-none">
                        <NavItem><Image className="mx-2" src={GiftBanner} height={25}/></NavItem>
                        <NavItem><Image className="mx-2" src={BoostBanner} height={25}/></NavItem>
                        <NavItem><Image className="mx-2" src={InsuranceBanner} height={25}/></NavItem>
                    </Nav>
                    <Nav className="d-flex flex-row align-items-center d-lg-none">
                        <Nav.Item>
                            <div className="border border-white rounded-5 d-flex align-items-center coin-assets">
                                <span className="mx-2 text-white d-flex align-items-center">
                                    <Image src={BspIcon} width={20} className="me-2" alt="coin-icon" />
                                    <span>{parseFloat(balance.USD).toFixed(2)}</span>
                                </span>
                                <span onClick={() => setDepositModal(true)}><MainButton title="Deposit" /></span>
                            </div>
                        </Nav.Item>
                        <Nav.Item className="me-lg-5 me-2">
                            <Dropdown className="position-relative z-3">
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="p-0 bg-transparent border-0" onClick={handleShow}>
                                    <Image src={DefaultAvatar} className="rounded-circle" width={40} alt="user-avatar" />
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Nav.Item>
                        <Nav.Item className="d-lg-flex align-items-center text-white ms-lg-5 d-none">
                            <Image src={USFlagIcon} width="25" alt="flag" /><span className="mx-lg-2 d-none d-lg-inline">|</span>
                            <span className="d-none d-lg-inline">$USD</span>
                        </Nav.Item>
                    </Nav>
                    </>
                )}
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto d-none d-md-block">
                        <div className="d-flex align-items-center main-token-info">
                            <Image src={BspIcon} width={20} alt="token" className="me-2" />
                            <span>BSP {parseFloat(balance.BSP).toFixed(2)}</span>
                        </div>
                    </Nav>
                    {!token ? (
                        <Nav className="d-none d-md-block">
                            <span onClick={() => { setSignupModal(true) }}><OutlineButton title="Sign in/up" /></span>
                        </Nav>
                    ) : (
                        <Nav className="d-none d-md-inline-flex">
                            <Nav.Item>
                                <div className="border border-white rounded-5 d-flex align-items-center coin-assets">
                                    <span className="mx-2 text-white d-flex align-items-center">
                                        <Image src={BspIcon} width={20} className="me-2" alt="coin-icon" />
                                        <span>{parseFloat(balance.USD).toFixed(2)}</span>
                                    </span>
                                    <span onClick={() => setDepositModal(true)}><MainButton title="Deposit" /></span>
                                </div>
                            </Nav.Item>
                            <Nav.Item className="me-5">
                                <Dropdown className="position-relative z-3">
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="p-0 bg-transparent border-0" onClick={handleShow}>
                                        <Image src={DefaultAvatar} className="rounded-circle" width={40} alt="user-avatar" />
                                    </Dropdown.Toggle>
                                </Dropdown>
                            </Nav.Item>
                            <Nav.Item className="d-flex align-items-center text-white ms-5">
                                <Image src={USFlagIcon} width="25" alt="flag" /><span className="mx-2">|</span>
                                <span>$USD</span>
                            </Nav.Item>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Container>
            <Offcanvas show={show} onHide={handleClose} className="bg-primary-1 text-white" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="overflow-y-scroll">
                    <Container fluid>
                        <Row className="mb-3 flex-row justify-content-between">
                            <div className="d-flex align-items-center user-info">
                                <Image src={DefaultAvatar} width={50} alt="avatar" className="me-3" />
                                <div className="d-flex justify-content-around flex-column">
                                    <div className="mb-2 user-name">{userInfo?.firstname === null ? "Unkown" : userInfo?.firstname + " " + userInfo?.lastname}</div>
                                    {/* <div className="d-flex align-items-center"><span className="me-2">0x45C30...9Ee3</span><Image src={ClipBoardIcon} width={20} alt="icon" /></div> */}
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <Col>
                                <label className="mb-2">Your level progress</label>
                                <Card className="bg-transparent border-1 border-white rounded-4 DepositCard">
                                    <Card.Body>
                                        <div className="d-flex align-items-center justify-content-between user-level mb-2">
                                            <div className="level-number">Level 1 </div>
                                            <div className="level-experience d-flex align-items-center"><Image src={ExperienceIcon} width={20} alt="icon" className="me-2" /><span className="text-white">860</span></div>
                                        </div>
                                        <Line percent={86} strokeWidth={2} strokeColor="#58FF00" />
                                        <div className="d-flex align-items-center justify-content-between mt-2">
                                            <span className="text-white">LEVEL 0</span>
                                            <span className="text-white">LEVEL 1</span>
                                        </div>
                                        <div className="text-center">
                                            <NavLink to="" className="nav-link text-white text-center text-decoration-underline"><small>Read More</small></NavLink>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <div className="d-flex align-items-center mb-3">
                                <Image src={CheckIcon} width={15} alt="icon" className="me-2" />
                                <span>Wallet Verified</span>
                            </div>
                            <Col>
                                <NavLink to="/setting" onClick={handleClose} className="w-100 nav-link text-center py-2 rounded-5 bg-secondary-1 text-primary-1 border-0">Go to Profile</NavLink>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col sm={12} md={12} className="mb-3">
                                <label className="mb-2">Refer a friend</label>
                                <Card className="bg-transparent border-1 border-white rounded-4 DepositCard">
                                    <Card.Body>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Image src={ReferralBanner} height={120} />
                                            <div className="text-white text-uppercase text-end">
                                                <small>Refer a Friend And Get </small><br /><strong className="text-warning">1,000 $xBSP</strong><small> + </small><strong className="text-secondary-1">10%</strong><br /><small>Commission</small>
                                            </div>
                                        </div>
                                        <div className="text-center text-secondary-1 "><small>Invite friends, earn money.</small><Image src={ShareIcon} width={30} className="ms-3" /></div>
                                        <Form.Group className="text-end refer-friend">
                                            <InputGroup className="mt-3 border rounded-5 overflow-hidden">
                                                <Form.Control readOnly placeholder={`https://bitnswap.com/?ref_id=${refId}`} className="bg-primary-1 border-0 text-white" />
                                                <Button variant="outline-secondary" className="border-0 p-0 px-1" id="button-addon1" onClick={() => { copy("https://bitnswap.com/?ref_id=" + refId); toast.info("Referral link Copied!") }}>
                                                    <Image src={ClipBoardIcon} width={30} alt="clipboard-icon" />
                                                </Button>
                                            </InputGroup>
                                        </Form.Group>
                                        <hr className="text-white" />
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="mb-2 text-white text-uppercase">Ambassador level</label>
                                            <label className="mb-2 text-center text-secondary-1 text-uppercase fw-bold">level 1</label>
                                        </div>
                                        <Line percent={80} strokeWidth={2} strokeColor="#58FF00" />
                                        <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                                            <span className="text-white">Friend 0</span>
                                            <span className="text-white">Friend 5</span>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                                            <span className="text-white">Invite Friends</span>
                                            <label className="mb-2 text-center text-secondary-1 fw-bold">5 <small className="text-white">Friends</small></label>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="mb-2 text-white text-uppercase">Referrl rewards</label>
                                            <label className="mb-2 text-center text-secondary-1 fw-bold">4,000 <small className="text-white">xBSP</small></label>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="mb-2 text-white text-uppercase">Commission rewards</label>
                                            <label className="mb-2 text-center text-secondary-1 fw-bold">400 <small className="text-white">BSP</small></label>
                                        </div>
                                        <hr className="text-white" />
                                        <div className="text-center text-uppercase mb-2">
                                            <Image src={ReferralRewardBanner} width="50%" alt="referral-reward-banner" />
                                            <label className="mb-2 text-white text-uppercase">Ambassador Rewards</label>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="mb-2 text-white text-uppercase">Level 1</label>
                                            <label className="mb-2 text-center text-secondary-1 text-uppercase fw-bold">10% <small className="text-white text-lowercase"> 3 months</small></label>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="mb-2 text-white text-uppercase">Level 2</label>
                                            <label className="mb-2 text-center text-secondary-1 text-uppercase fw-bold">10% <small className="text-white text-lowercase"> 6 months</small></label>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="mb-2 text-white text-uppercase">Level 3</label>
                                            <label className="mb-2 text-center text-secondary-1 text-uppercase fw-bold">10% <small className="text-white text-lowercase"> 12 months</small></label>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="mb-2 text-white text-uppercase">Level 4</label>
                                            <label className="mb-2 text-center text-secondary-1 text-uppercase fw-bold">15% <small className="text-white text-lowercase"> 12 months</small></label>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="mb-2 text-white text-uppercase">Level 5</label>
                                            <label className="mb-2 text-center text-secondary-1 text-uppercase fw-bold">15% <small className="text-white text-lowercase"> 24 months</small></label>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="mb-2 text-white text-uppercase">Level 6</label>
                                            <label className="mb-2 text-center text-secondary-1 text-uppercase fw-bold">20% <small className="text-white text-lowercase"> for life</small></label>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <label>Recent Game</label>
                                <Card className="CrashGameCard border-white mx-2 my-2" style={{ backgroundImage: `url(${GamefiBg1})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                    <span></span>
                                    <Card.Body className="text-center">
                                        <Image src={GameCardImage1} width="80%" alt="game-avatar" className="mb-3" />
                                        <Card.Title className="mb-3">Dice</Card.Title>
                                        <Card.Text>BITNSWAP ORIGINALS</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col sm={12} md={12}>
                                <label>Current Trade</label>
                                <MenuChart />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col sm={12} md={6} className="mb-3"><NavLink to="" className="w-full nav-link border bg-primary-1 rounded-5 text-center py-2">Profile</NavLink></Col>
                            <Col sm={12} md={6} className="mb-3"><NavLink to="/setting" onClick={handleClose} className="w-full nav-link border bg-primary-1 rounded-5 text-center py-2">Settings</NavLink></Col>
                        </Row>
                        <Row>
                            <Col><Button className="w-100 rounded-5 bg-danger border-0" onClick={() => {
                                userSignOut();
                                handleClose();
                            }
                            }>Log Out</Button></Col>
                        </Row>
                    </Container>
                </Offcanvas.Body>
            </Offcanvas>
            <SignUpModal />
            <SignInModal />
            <ForgetPasswordModal />
            <DepositModal />
            <FreeSpinModal />
        </Navbar>
    )
}