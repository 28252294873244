import React, { useEffect, useState, useCallback } from "react";
import { Col, Container, Image, Modal, Row, Form, Tabs, Tab, Nav, Card, InputGroup, Button } from "react-bootstrap";
import { AppleIcon, BTCIcon, BspIcon, ClipBoardIcon, CloseIcon, ColorLogo, EthIcon, FacebookIcon, FreeSpinTitle, GoogleIcon, MasterCardIcon, MetamaskIcon, ModalBannerImage, NFTImage1, NFTImage2, QRCodeImage, SpinBg, UsdtIcon, VisaCardIcon, XBspIcon } from "../../assets";
import { MainButton } from "../buttons/buttons";
import { useMainContext } from "../../context/mainContext";
import Select, { OptionProps, SingleValueProps, components } from "react-select";
import { DepositCurrencyList, FreeSpinData } from "../../utils/data";
import axios from "axios";
import { toast } from "react-toastify";
import FreeSpinWheel from "../spin";
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    IResolveParams,
} from 'reactjs-social-login';
import ConnectWallet from "../wallet/walletConnect";

interface DepositCurrency {
    value: string;
    label: string;
    icon: string;
}

let previousEmail: string = "";
const REDIRECT_URI = window.location.href;

export const SignUpModal: React.FunctionComponent = () => {
    const { signupModal, setSignupModal, setSigninModal, userSocialSignIn, provider, setProvider, profile, setProfile } = useMainContext();
    const [userEmail, setUserEmail] = useState<string>('');
    const [term, setTerm] = useState<boolean>(false);
    const [age, setAge] = useState<boolean>(false);

    let linkData: any;

    let link = window.location.href;
    let url = new URL(link);
    let params = new URLSearchParams(url.search);
    let ref_id = params.get('ref_id');
    let campaignId = params.get('campaign_id');
    let param1 = params.get('param1');
    let param2 = params.get('param2');
    let param3 = params.get('param3');

    const _linkParams = JSON.parse(window.localStorage.getItem('linkParams')!);
    if (_linkParams?.ref_id !== "" && _linkParams?.ref_id !== null) {
        linkData = _linkParams;
    }



    const emailValidation = (email: string) => {
        var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return regex.test(email);
    }

    const signUp = async () => {
        if (userEmail === "") {
            toast.error("Please input your email address.")
            return;
        }
        if (emailValidation(userEmail) === false) {
            toast.error("Email address invalied.")
            return;
        }
        if (term !== true || age !== true) {
            toast.error("Please check Terms of Service and Privacy Statement.")
            return;
        }

        const headers = {
            contentType: 'application/json',
        }
        // debugger
        const body = {
            email: userEmail,
            ref_id: ref_id === null ? linkData?.ref_id : ref_id,
            campaign_id: campaignId === null ? linkData?.campaignId : campaignId,
            param1: param1 === null ? linkData?.param1 : param1,
            param2: param2 === null ? linkData?.param2 : param2,
            param3: param3 === null ? linkData?.param3 : param3,
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/client/auth/get/otp/`, body, { headers })
            .then(function (response) {
                if (response.data.error_code === 0 && response.data.new_customer === true && response.data.email_sended === true) {
                    toast.success("Please check the code sent to " + userEmail);
                    previousEmail = userEmail;
                    setSigninModal(true);
                }

                if (response.data.error_code === 0 && response.data.new_customer === false && response.data.email_sended === true) {
                    toast.warn("Your email address already existing. Please check the code sent to " + userEmail)
                    previousEmail = userEmail;
                    setSignupModal(false);
                    setSigninModal(true);
                }

                if (response.data.error_code === 1) {
                    toast.error("Email address invalied.")
                    return
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }



    const onLoginStart = useCallback(() => {
    }, [])

    const onLogoutSuccess = useCallback(() => {
        setProfile(null)
        setProvider('')
        alert('logout success')
    }, [])

    return (
        <Modal className="SignUpModal" centered show={signupModal} size="lg" onHide={() => setSignupModal(false)}>
            <Modal.Body className="border border-white rounded-3 bg-primary-1">
                <Container>
                    <Row>
                        <Col sm={12} md={6} className="border-0 border-md-end text-center position-relative">
                            <Image src={CloseIcon} height={20} alt="color-logo" onClick={() => setSignupModal(false)} className="position-absolute top-0 end-0 d-inline d-lg-none mb-3" />
                            <Image src={ColorLogo} height={60} alt="color-logo" className="mb-3" />
                            <Image src={ModalBannerImage} width="90%" alt="modal-banner" className="rounded-circle mb-5  d-none d-md-block" />
                            <Image src={ModalBannerImage} width="50%" alt="modal-banner" className="rounded-circle mb-5  d-inline d-md-none" />
                            <Modal.Title className="mb-3 d-none d-md-block">WELCOME TO BITNSWAP</Modal.Title>
                            <div className="text-white  d-none d-md-block">Start Your Game Journey Now!</div>
                        </Col>
                        <Col sm={12} md={6}>
                            <Modal.Title className="mb-3">Sign up</Modal.Title>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className="text-white">Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} className="rounded-5 border-white border bg-primary-1 text-white" />
                            </Form.Group>
                            <Form.Group className="mb-3 text-white" controlId="terms">
                                <Form.Check type="checkbox" defaultChecked={term} onClick={() => setTerm(!term)} label="I agree to the Terms of service, Privacy Statement, and API & SDK License Agreement." />
                            </Form.Group>
                            <Form.Group className="mb-5 text-white" controlId="age">
                                <Form.Check type="checkbox" defaultChecked={age} onClick={() => setAge(!age)} label="I agree to the User Agreement & confirm I am at least 18 years old." />
                            </Form.Group>
                            <span onClick={signUp}><MainButton title="sign up" /></span>
                            <div className="d-flex align-items-center justify-content-between my-3">
                                <span className="text-white">Already have an account?</span>
                                <strong className="text-white" onClick={() => { setSignupModal(false); setSigninModal(true); }}>SIGN IN</strong>
                            </div>
                            <div className="position-relative mb-4">
                                <div className="d-flex align-items-center justify-content-center text-white position-relative">
                                    <span className="bg-primary-1 position-relative z-1 px-2 mt-1">Log in directly with</span>
                                </div>
                                <hr className="text-white position-absolute w-100 top-0 " />
                            </div>
                            <Row>
                                <Col sm={12} md={10} className="mx-auto text-center">
                                    {provider && profile ? (
                                        <div className="d-flex align-items-center justify-content-center">
                                            <Image src={GoogleIcon} width={50} alt="google" className="mx-2" />
                                            <Image src={FacebookIcon} width={50} alt="apple" className="mx-2" />
                                            <Image src={MetamaskIcon} width={50} alt="metamask" className="mx-2" />
                                        </div>
                                    ) : (
                                        <div className={`d-flex flex-row align-items-center justify-content-center${provider && profile ? 'hide' : ''}`}>
                                            <LoginSocialGoogle
                                                client_id='534675856580-lr7scmo7oc8dk7bn18tjq0rr1t27klpr.apps.googleusercontent.com'
                                                onLoginStart={onLoginStart}
                                                redirect_uri={REDIRECT_URI}
                                                scope="https://www.googleapis.com/auth/userinfo.profile"
                                                discoveryDocs="claims_supported"
                                                onResolve={({ provider, data }: IResolveParams) => {
                                                    setProvider(provider);
                                                    setProfile(data);
                                                    userSocialSignIn({ provider: provider, email: data!.email, firstName: data!.given_name, lastName: data!.family_name });
                                                }}
                                                onReject={err => {
                                                    console.log(err);
                                                }}
                                            >
                                                <Image src={GoogleIcon} width={50} alt="google" className="mx-2" />
                                            </LoginSocialGoogle>
                                            <LoginSocialFacebook
                                                appId={'247017758379823' || ''}
                                                fieldsProfile={
                                                    'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                                }
                                                onLoginStart={onLoginStart}
                                                onLogoutSuccess={onLogoutSuccess}
                                                redirect_uri={REDIRECT_URI}
                                                onResolve={({ provider, data }: IResolveParams) => {
                                                    setProvider(provider);
                                                    setProfile(data);
                                                    userSocialSignIn({ provider: provider, email: data!.email, firstName: data!.first_name, lastName: data!.last_name });
                                                }}
                                                onReject={err => {
                                                    console.log(err);
                                                }}
                                            >
                                                <Image src={FacebookIcon} width={50} alt="facebook" className="mx-2" />
                                            </LoginSocialFacebook>
                                            <ConnectWallet><Image src={MetamaskIcon} width={50} alt="metamask" /></ConnectWallet>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </Modal.Body>
        </Modal>
    )
}

export const SignInModal: React.FunctionComponent = () => {
    const { signinModal, setSigninModal, setSignupModal, userSignIn, userSocialSignIn, provider, setProvider, profile, setProfile, setRefId, setBalance } = useMainContext();

    const [userEmail, setUserEmail] = useState<string>('');
    const [verifyCode, setVerifyCode] = useState<string>('');

    let linkData: any;

    let link = window.location.href;
    let url = new URL(link);
    let params = new URLSearchParams(url.search);
    let ref_id = params.get('ref_id');
    let campaignId = params.get('campaign_id');
    let param1 = params.get('param1');
    let param2 = params.get('param2');
    let param3 = params.get('param3');


    const _linkParams = JSON.parse(window.localStorage.getItem('linkParams')!);
    if (_linkParams?.ref_id !== "" && _linkParams?.ref_id !== null) {
        linkData = _linkParams;
    }

    const emailValidation = (email: string) => {
        var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return regex.test(email);
    }

    const getCode = async () => {
        if (userEmail === "") {
            toast.error("Please input your email address.")
            return;
        }
        if (emailValidation(userEmail) === false) {
            toast.error("Email address invalied.")
            return;
        }

        const headers = {
            contentType: 'application/json',
        }
        const body = {
            email: userEmail
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/client/auth/get/otp/`, body, { headers })
            .then(function (response) {

                if (response.data.error_code === 0) {
                    toast.success("Please check the code sent to " + userEmail)
                }

                if (response.data.error_code === 1) {
                    toast.error("Email address invalied.")
                    return
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const signIn = async () => {
        if (userEmail === "") {
            toast.error("Please input your email address.")
            return;
        }
        if (emailValidation(userEmail) === false) {
            toast.error("Email address invalied.")
            return;
        }

        if (verifyCode === "") {
            toast.error("Please input verify Code.")
            return;
        }

        const headers = {
            contentType: 'application/json',
        }

        const body = {
            email: userEmail,
            otp_code: verifyCode,
            ref_id: ref_id === null ? linkData?.ref_id : ref_id,
            campaign_id: campaignId === null ? linkData?.campaignId : campaignId,
            param1: param1 === null ? linkData?.param1 : param1,
            param2: param2 === null ? linkData?.param2 : param2,
            param3: param3 === null ? linkData?.param3 : param3,
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/client/auth/signin/`, body, { headers })
            .then(function (response) {
                if (response.data.error_code === 0) {
                    toast.success("Welcome to BitNswap!");
                    userSignIn(response.data.token, response.data.information);
                    setBalance(response.data.balances)
                    setRefId(response.data.information.id_for_referal);
                    setSigninModal(false);
                }
                else {
                    toast.error("Incorrect login or password, or expiry code");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onLoginStart = useCallback(() => {
    }, [])

    const onLogoutSuccess = useCallback(() => {
        setProfile(null)
        setProvider('')
        alert('logout success')
    }, [])

    useEffect(() => {
        setUserEmail(previousEmail)
    }, [previousEmail])

    return (
        <Modal className="SignUpModal" centered show={signinModal} size="lg" onHide={() => setSigninModal(false)}>
            <Modal.Body className="border border-white rounded-3 bg-primary-1">
                <Container>
                    <Row>
                        <Col sm={12} md={6} className="border-0 border-lg-end text-center position-relative">
                            <Image src={CloseIcon} height={20} onClick={() => setSigninModal(false)} alt="color-logo" className="position-absolute top-0 end-0 d-inline d-lg-none mb-3" />
                            <Image src={ColorLogo} height={60} alt="color-logo" className="mb-3" />
                            <Image src={ModalBannerImage} width="90%" alt="modal-banner" className="rounded-circle mb-5 d-none d-lg-inline" />
                            <Image src={ModalBannerImage} width="60%" alt="modal-banner" className="rounded-circle mb-5 d-inline d-lg-none" />
                            <Modal.Title className="mb-3 d-none d-lg-block">WELCOME TO BITNSWAP</Modal.Title>
                            <div className="text-white d-none d-lg-block">Start Your Game Journey Now!</div>
                        </Col>
                        <Col sm={12} md={6}>
                            <Modal.Title className="mb-3">Sign In</Modal.Title>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className="text-white">Email address</Form.Label>
                                <Form.Control type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} placeholder="Enter email" className="rounded-5 border-white border bg-primary-1 text-white" />
                            </Form.Group>
                            <Form.Group className="mb-5" controlId="formBasicEmail">
                                <Form.Label className="text-white">Verify Code</Form.Label>
                                <InputGroup className="rounded-5 border-white border overflow-hidden">
                                    <Form.Control value={verifyCode} onChange={(e) => setVerifyCode(e.target.value)} placeholder="Enter verify code" className="bg-primary-1 text-white border-0" />
                                    <Button variant="outline-secondary" onClick={getCode} className="fw-bold rounded-5" id="button-addon1">
                                        Get Code
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                            <span onClick={signIn}><MainButton title="sign in" /></span>
                            <div className="d-flex align-items-center justify-content-end my-3">
                                <strong className="text-white" onClick={() => { setSigninModal(false); setSignupModal(true); }}>SIGN UP</strong>
                            </div>
                            <div className="position-relative mb-4">
                                <div className="d-flex align-items-center justify-content-center text-white position-relative">
                                    <span className="bg-primary-1 position-relative z-1 px-2 mt-1">Log in directly with</span>
                                </div>
                                <hr className="text-white position-absolute w-100 top-0 " />
                            </div>
                            <Row>
                                <Col sm={12} md={10} className="mx-auto text-center">
                                    {provider && profile ? (
                                        <div className="d-flex align-items-center justify-content-center">
                                            <Image src={GoogleIcon} width={50} alt="google" className="mx-2" />
                                            <Image src={FacebookIcon} width={50} alt="apple" className="mx-2" />
                                            <Image src={MetamaskIcon} width={50} alt="metamask" className="mx-2" />
                                        </div>
                                    ) : (
                                        <div className={`d-flex flex-row align-items-center justify-content-center${provider && profile ? 'hide' : ''}`}>
                                            <LoginSocialGoogle
                                                client_id='https://app.dev.bitnswap.com/'
                                                onLoginStart={onLoginStart}
                                                redirect_uri={REDIRECT_URI}
                                                scope="https://www.googleapis.com/auth/userinfo.profile"
                                                discoveryDocs="claims_supported"
                                                onResolve={({ provider, data }: IResolveParams) => {
                                                    setProvider(provider);
                                                    setProfile(data);
                                                    userSocialSignIn({ provider: provider, email: data!.email, firstName: data!.given_name, lastName: data!.family_name });
                                                }}
                                                onReject={err => {
                                                    console.log(err);
                                                }}
                                            >
                                                <Image src={GoogleIcon} width={50} alt="google" className="mx-2" />
                                            </LoginSocialGoogle>
                                            <LoginSocialFacebook
                                                appId={'247017758379823' || ''}
                                                fieldsProfile={
                                                    'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                                }
                                                onLoginStart={onLoginStart}
                                                onLogoutSuccess={onLogoutSuccess}
                                                redirect_uri={REDIRECT_URI}
                                                onResolve={({ provider, data }: IResolveParams) => {
                                                    setProvider(provider);
                                                    setProfile(data);
                                                    userSocialSignIn({ provider: provider, email: data!.email, firstName: data!.first_name, lastName: data!.last_name });
                                                }}
                                                onReject={err => {
                                                    console.log(err);
                                                }}
                                            >
                                                <Image src={FacebookIcon} width={50} alt="facebook" className="mx-2" />
                                            </LoginSocialFacebook>
                                            <ConnectWallet><Image src={MetamaskIcon} width={50} alt="metamask" /></ConnectWallet>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    )
}
export const ForgetPasswordModal: React.FunctionComponent = () => {
    const { forgetPasswordModal, setSigninModal, setForgetPasswordModal, } = useMainContext();

    return (
        <Modal className="SignUpModal" centered show={forgetPasswordModal} size="lg" onHide={() => setForgetPasswordModal(false)}>
            <Modal.Body className="border border-white rounded-3 bg-primary-1">
                <Container>
                    <Row>
                        <Col sm={12} md={6} className="border-end text-center">
                            <Image src={ColorLogo} height={60} alt="color-logo" className="mb-3" />
                            <Image src={ModalBannerImage} width="90%" alt="modal-banner" className="rounded-circle mb-5" />
                            <Modal.Title className="mb-3">WELCOME TO BITNSWAP</Modal.Title>
                            <div className="text-white">Start Your Game Journey Now!</div>
                        </Col>
                        <Col sm={12} md={6} className="d-flex flex-column justify-content-center">
                            <Modal.Title className="mb-3">Forget Password</Modal.Title>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className="text-white">Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" className="rounded-5 border-white border bg-primary-1 text-white" />
                            </Form.Group>

                            <span onClick={() => { setForgetPasswordModal(false); }}><MainButton title="Reset password" /></span>
                            <div className="d-flex align-items-center justify-content-between my-3">
                                <span className="text-white"></span>
                                <strong className="text-white" onClick={() => { setSigninModal(true); setForgetPasswordModal(false) }}>SIGN IN</strong>
                            </div>
                            <div className="position-relative mb-4">
                                <div className="d-flex align-items-center justify-content-center text-white position-relative">
                                    <span className="bg-primary-1 position-relative z-1 px-2 mt-1">Log in directly with</span>
                                </div>
                                <hr className="text-white position-absolute w-100 top-0 " />
                            </div>
                            <Row>
                                <Col sm={12} md={10} className="mx-auto text-center">
                                    <Image src={AppleIcon} width={50} alt="apple" className="mx-2" />
                                    <Image src={MetamaskIcon} width={50} alt="metamask" className="mx-2" />
                                    <Image src={GoogleIcon} width={50} alt="google" className="mx-2" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

const Option: React.FC<OptionProps<DepositCurrency>> = (props) => (
    <components.Option {...props} className="country-option bg-primary-1 border rounded-5 mb-2">
        <img src={props.data.icon} alt="logo" className="country-logo me-2" />
        <span className="currency-label">{props.data.label}</span>
    </components.Option>
);

export const DepositModal: React.FunctionComponent = () => {
    const { depositModal, setDepositModal, getBalance, balance, token } = useMainContext();
    const [selectedCountry, setSelectedCountry] = useState<DepositCurrency>(DepositCurrencyList[0]);
    const [withdrawAmount, setWithdrawAmount] = useState<string>('0.00');
    // const { balance, getAccountSolde } = useWeb3AuthContext();
    const [depositAmount, setDepositAmount] = useState<string>('0.00');

    const handleChange = (value: any) => {
        setSelectedCountry(value);
    };

    const SingleValue: React.FC<SingleValueProps<DepositCurrency>> = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <img src={props.data.icon} alt="s-logo" className="selected-logo me-2" />
            <span className="currency-label">{children}</span>
        </components.SingleValue>
    );

    const withdraw = async () => {
        if (parseFloat(withdrawAmount) > parseFloat(balance.USD)) {
            toast.warning("Insufficient balance")
            return
        }
        const headers = {
            Authorization: token,
            token: token
        }

        const body = {
            amount: withdrawAmount
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/client/withdrawal/request/`, body, { headers })
            .then(function (response) {
                toast.success("You have successfully sent your withdrawal request. You will receive a confirmation email if all conditions have been complied with.")
                setDepositModal(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const deposit = async () => {
        if (parseFloat(depositAmount) <= 0) {
            toast.warn("Deposit amount must be greater than 0!");
            return;
        }
        const headers = {
            Authorization: token,
            token: token
        }

        const body = {
            amount: depositAmount,
            devise: "USD",
            success_url: "https://app.dev.bitnswap.com/",
            failed_url: "https://app.dev.bitnswap.com/"
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/client/deposit/`, body, { headers })
            .then(function (response) {
                window.open(response.data.url)
                getBalance()
                setDepositModal(false)

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <Modal className="DepositModal" show={depositModal} centered size="xl" onHide={() => setDepositModal(false)}>
            <Modal.Body className="bg-primary-1 border border-white rounded-3 text-white">
                <Tabs
                    defaultActiveKey="deposit"
                    id="uncontrolled-tab-example"
                    className="mb-3 flex-row border-0"
                    fill
                >
                    <Tab eventKey="deposit" title="DEPOSIT">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="cc">
                            <Row>
                                <Col sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="cc">By CC</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="crypto">By Crypto</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="all">By NFT(All)</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="bluechip">By NFT(Bluechip)</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="cc">
                                            <Card className="border border-white bg-transparent text-white">
                                                <Card.Body>
                                                    <Container>
                                                        <Row>
                                                            <Col sm={12} md={6}>
                                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                    <Form.Label>Deposit Currency</Form.Label>
                                                                    <Select
                                                                        className="Currency-selector"
                                                                        value={selectedCountry}
                                                                        options={DepositCurrencyList}
                                                                        onChange={(value) => { handleChange(value) }}
                                                                        styles={{
                                                                            singleValue: (base) => ({
                                                                                ...base,
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }),
                                                                        }}
                                                                        components={{
                                                                            Option,
                                                                            SingleValue,
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6}>
                                                                <Form.Group className="mb-3 text-start text-md-end" controlId="formBasicEmail">
                                                                    <Form.Label >Amount</Form.Label>
                                                                    <Form.Control className="bg-transparent text-white text-start text-md-end rounded-5" type="number" placeholder="$ 0.00" value={depositAmount} onChange={(e) => setDepositAmount(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} md={12} className="mb-3">
                                                                <Card className="bg-transparent border-1 border-white rounded-4 DepositCard">
                                                                    <Card.Body>
                                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                                            <div className="text-white text-uppercase"><small>Method</small></div>
                                                                            <div className="d-flex align-items-end">
                                                                                <Image src={VisaCardIcon} height={30} alt="provider" className="me-4" />
                                                                                <Image src={MasterCardIcon} height={30} alt="provider" className="" />
                                                                            </div>
                                                                        </div>
                                                                        <hr className="text-white" />
                                                                        <div className="text-center"><Button className="text-primary-1 bg-secondary-1 rounded-5 border-0 px-4" onClick={deposit}>Deposit</Button></div>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="crypto">
                                            <Card className="border border-white bg-transparent text-white">
                                                <Card.Body>
                                                    <Container>
                                                        <Row>
                                                            <Col sm={12} md={6}>
                                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                    <Form.Label>Deposit Currency</Form.Label>
                                                                    <Select
                                                                        className="Currency-selector"
                                                                        value={selectedCountry}
                                                                        options={DepositCurrencyList}
                                                                        onChange={(value) => { handleChange(value) }}
                                                                        styles={{
                                                                            singleValue: (base) => ({
                                                                                ...base,
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }),
                                                                        }}
                                                                        components={{
                                                                            Option,
                                                                            SingleValue,
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6}>
                                                                <Form.Group className="mb-3 text-start text-md-end" controlId="formBasicEmail">
                                                                    <Form.Label >Amount</Form.Label>
                                                                    <Form.Control className="bg-transparent text-start text-md-end rounded-5" type="number" placeholder="$ 0.00" />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} md={8} className="d-flex flex-column justify-content-around">
                                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                    <Form.Label>Popular Currency</Form.Label>
                                                                    <div className="d-flex align-items-center flex-wrap">
                                                                        <Button className="bg-transparent border border-white rounded-5 me-3 d-flex align-items-center my-2">
                                                                            <Image src={BspIcon} width={20} alt="currency" className="me-2" />
                                                                            <span>BSP</span>
                                                                        </Button>
                                                                        <Button className="bg-transparent border border-white rounded-5  me-3 d-flex align-items-center my-2">
                                                                            <Image src={BTCIcon} width={20} alt="currency" className="me-2" />
                                                                            <span>BTC</span>
                                                                        </Button>
                                                                        <Button className="bg-transparent border border-white rounded-5  me-3 d-flex align-items-cente my-2r">
                                                                            <Image src={UsdtIcon} width={20} alt="currency" className="me-2" />
                                                                            <span>USDT</span>
                                                                        </Button>
                                                                        <Button className="bg-transparent border border-white rounded-5  me-3 d-flex align-items-center my-2">
                                                                            <Image src={EthIcon} width={20} alt="currency" className="me-2" />
                                                                            <span>ETH</span>
                                                                        </Button>
                                                                    </div>
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Label>Wallet Address</Form.Label>
                                                                    <InputGroup className="mb-3 border rounded-5 overflow-hidden">
                                                                        <Form.Control
                                                                            aria-label="Example text with button addon"
                                                                            aria-describedby="basic-addon1"
                                                                            className="bg-transparent border-0"
                                                                            value="338mTLdsA8AQaLqXMVmnKeLVzEwZTozMHs"
                                                                        />
                                                                        <Button variant="outline-secondary" className="border-0 p-0 px-1" id="button-addon1">
                                                                            <Image src={ClipBoardIcon} width={30} alt="clipboard-icon" />
                                                                        </Button>
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                <MainButton title="deposit" />
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <Image src={QRCodeImage} width="100%" alt="qr" />
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="all">
                                            <Card className="border border-white bg-transparent text-white">
                                                <Card.Body>
                                                    <Container>
                                                        <Row>
                                                            <Col sm={12} md={6}>
                                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                    <Form.Label>Deposit NFT</Form.Label>
                                                                    <Select
                                                                        className="Currency-selector"
                                                                        value={selectedCountry}
                                                                        options={DepositCurrencyList}
                                                                        onChange={(value) => { handleChange(value) }}
                                                                        styles={{
                                                                            singleValue: (base) => ({
                                                                                ...base,
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }),
                                                                        }}
                                                                        components={{
                                                                            Option,
                                                                            SingleValue,
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6}>
                                                                <Form.Group className="mb-3 text-end" controlId="formBasicEmail">
                                                                    <Form.Label>Convert Currency</Form.Label>
                                                                    <Select
                                                                        className="Currency-selector"
                                                                        value={selectedCountry}
                                                                        options={DepositCurrencyList}
                                                                        onChange={(value) => { handleChange(value) }}
                                                                        styles={{
                                                                            singleValue: (base) => ({
                                                                                ...base,
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }),
                                                                        }}
                                                                        components={{
                                                                            Option,
                                                                            SingleValue,
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} md={4}>
                                                                <Image src={NFTImage2} width="100%" alt="qr" className="rounded-3 border border-white" />
                                                            </Col>
                                                            <Col sm={12} md={8} className="d-flex flex-column justify-content-around">
                                                                <Form.Group>
                                                                    <Form.Label>NFT Price</Form.Label>
                                                                    <InputGroup className="mb-3 border rounded-5 overflow-hidden">
                                                                        <InputGroup.Text id="basic-addon1" className="bg-transparent border-0 pe-0"><Image src={EthIcon} width={25} alt="currency" /></InputGroup.Text>
                                                                        <Form.Control
                                                                            aria-label="Example text with button addon"
                                                                            aria-describedby="basic-addon1"
                                                                            className="bg-transparent border-0"
                                                                            value="0.04"
                                                                        />
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Label>Convert Balance</Form.Label>
                                                                    <InputGroup className="mb-3 border rounded-5 overflow-hidden">
                                                                        <InputGroup.Text id="basic-addon1" className="bg-transparent border-0 pe-0"><Image src={XBspIcon} width={25} alt="currency" /></InputGroup.Text>
                                                                        <Form.Control
                                                                            aria-label="Example text with button addon"
                                                                            aria-describedby="basic-addon1"
                                                                            className="bg-transparent border-0"
                                                                            value="240.00"
                                                                        />
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                <MainButton title="convert" />
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="bluechip">
                                            <Card className="border border-white bg-transparent text-white">
                                                <Card.Body>
                                                    <Container>
                                                        <Row>
                                                            <Col sm={12} md={6}>
                                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                    <Form.Label>Deposit NFT</Form.Label>
                                                                    <Select
                                                                        className="Currency-selector"
                                                                        value={selectedCountry}
                                                                        options={DepositCurrencyList}
                                                                        onChange={(value) => { handleChange(value) }}
                                                                        styles={{
                                                                            singleValue: (base) => ({
                                                                                ...base,
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }),
                                                                        }}
                                                                        components={{
                                                                            Option,
                                                                            SingleValue,
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} md={6}>
                                                                <Form.Group className="mb-3 text-end" controlId="formBasicEmail">
                                                                    <Form.Label>Convert Currency</Form.Label>
                                                                    <Select
                                                                        className="Currency-selector"
                                                                        value={selectedCountry}
                                                                        options={DepositCurrencyList}
                                                                        onChange={(value) => { handleChange(value) }}
                                                                        styles={{
                                                                            singleValue: (base) => ({
                                                                                ...base,
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }),
                                                                        }}
                                                                        components={{
                                                                            Option,
                                                                            SingleValue,
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} md={4}>
                                                                <Image src={NFTImage1} width="100%" alt="qr" className="rounded-3 border border-white" />
                                                            </Col>
                                                            <Col sm={12} md={8} className="d-flex flex-column justify-content-around">
                                                                <Form.Group>
                                                                    <Form.Label>NFT Price</Form.Label>
                                                                    <InputGroup className="mb-3 border rounded-5 overflow-hidden">
                                                                        <InputGroup.Text id="basic-addon1" className="bg-transparent border-0 pe-0"><Image src={EthIcon} width={25} alt="currency" /></InputGroup.Text>
                                                                        <Form.Control
                                                                            aria-label="Example text with button addon"
                                                                            aria-describedby="basic-addon1"
                                                                            className="bg-transparent border-0"
                                                                            value="0.04"
                                                                        />
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Label>Convert Balance</Form.Label>
                                                                    <InputGroup className="mb-3 border rounded-5 overflow-hidden">
                                                                        <InputGroup.Text id="basic-addon1" className="bg-transparent border-0 pe-0"><Image src={BspIcon} width={25} alt="currency" /></InputGroup.Text>
                                                                        <Form.Control
                                                                            aria-label="Example text with button addon"
                                                                            aria-describedby="basic-addon1"
                                                                            className="bg-transparent border-0"
                                                                            value="240.00"
                                                                        />
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                <MainButton title="convert" />
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Tab>
                    <Tab eventKey="withdraw" title="WITHDRAW">
                        <Row>
                            <Col sm={12} lg={12}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <span className="d-flex align-items-center justify-content-between mb-2">
                                        <Form.Label>Withdrawal Amount</Form.Label>
                                        <Form.Label>Min: 100 BSP</Form.Label>
                                    </span>
                                    <Form.Control value={withdrawAmount} onChange={(e) => { setWithdrawAmount(e.target.value) }} className="bg-transparent text-white text-start text-md-end rounded-5" type="number" placeholder="$ 0.00" />
                                    <span className="d-flex align-items-center justify-content-between mt-2">
                                        <Form.Label className="text-secondary">Avaliable: <strong className="text-white">$ {parseFloat(balance?.USD).toFixed(2)}</strong></Form.Label>
                                        <Form.Label className="text-secondary">Locked funds: <strong className="text-white">$ 0.00</strong></Form.Label>
                                    </span>
                                </Form.Group>
                            </Col>
                            <Col sm={12} lg={12} className="mb-3">
                                <div className="text-center"><Button onClick={withdraw} className="text-primary-1 bg-secondary-1 rounded-5 border-0 px-4">Withdraw</Button></div>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>

            </Modal.Body>
        </Modal>
    )
}

export const FreeSpinModal: React.FunctionComponent = () => {
    const { freeSpinModal, setFreeSpinModal } = useMainContext();
    return (
        <Modal className="DepositModal" show={freeSpinModal} centered size="lg" onHide={() => setFreeSpinModal(false)}>
            <Modal.Body className="bg-primary-1 border border-white rounded-3 text-white text-center" style={{ backgroundImage: `url(${SpinBg})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                <Image src={FreeSpinTitle} width={350} alt="title" className="rounded-5 mb-5" />
                <FreeSpinWheel items={FreeSpinData} />
                <Modal.Title className="text-center mt-5 section-title" style={{ textShadow: `1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue` }}>Spin Now<br />You can get 5BTC</Modal.Title>
            </Modal.Body>
        </Modal>
    )
}