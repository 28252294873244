import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { MainLayout, PrivateRoute } from './components/layouts';
import HomePage from './pages/Home';
import { videoPath } from './assets';
import { MainContextProvider } from './context/mainContext';
import TradingPage from './pages/trade';
import DefiPage from './pages/defi';
import SettingPage from './pages/settings';
import NFTGamePage from './pages/nft';
import MysteryBoxPage from './pages/mystery';
import Preloader from './components/preloader/preloader';
import FastTrade from './pages/trade/fastTrade';
import CryptoPrediction from './pages/trade/cryptoPrediction';
import OriginalGamePage from './pages/games/originalGames';
import AviatorGame from './pages/games/originalGames/aviator';
import LimboGame from './pages/games/originalGames/limbo';
import NFTPrediction from './pages/trade/nftPrediction';
import DegenSlotGame from './pages/games/originalGames/degenSlot';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LamboJackpotPage from './pages/jackpots/lamboJackpot';
import DailyJackpotPage from './pages/jackpots/dailyJackpot';
import { Web3OnboardProvider, init } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import DiceGame from './pages/games/originalGames/dice';
import CryptoPredictionChart from './components/charts/cryptoPredictionChart';

const INFURA_KEY = 'https://mainnet.infura.io/v3/c0d507d7aa164e20bd2c754d5b07bef2'

const injected = injectedModule({
  custom: [
    // include custom injected wallet modules here
  ],
  filter: {
    // mapping of wallet labels to filter here
  }
});
const walletConnect = walletConnectModule({
  version: 2,
  projectId: "ea6d5dc48107c929d21ab5c0b2eb1af3",
  dappUrl: 'app.dev.bitnswap.com'
});
const wallets = [injected, walletConnect];
const chains = [
  {
    id: '0x1',
    token: 'ETH',
    label: 'Ethereum Mainnet',
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_KEY}`
  },
];

const appMetadata = {
  name: 'Connect Wallet Example',
  icon: '<svg>My App Icon</svg>',
  description: 'Example showcasing how to connect a wallet.',
  recommendedInjectedWallets: [
    { name: 'MetaMask', url: 'https://metamask.io' },
    { name: 'Coinbase', url: 'https://wallet.coinbase.com/' }
  ]
}


const web3Onboard = init({
  theme: 'dark',
  wallets,
  chains,
  appMetadata
})

function App() {
  return (
    <div className="App" data-bs-theme="dark">
      <video autoPlay muted loop className='background_video'>
        <source src={videoPath} type="video/mp4" />
      </video>
      <Web3OnboardProvider web3Onboard={web3Onboard}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <BrowserRouter>
          <MainContextProvider>
            <Preloader />
            <Routes>
              <Route path='/prediction-trading-game' element={<CryptoPredictionChart />} />
              <Route element={<MainLayout />}>
                <Route path='/' element={<HomePage />} />
                <Route path='/crypto_trade/prediction' element={<CryptoPrediction />} />
                <Route path='/mystery_box' element={<MysteryBoxPage />} />
                <Route path='/lambo_jackpot' element={<LamboJackpotPage />} />
                <Route path='/daily_jackpot' element={<DailyJackpotPage />} />
                <Route path='/crypto_trade/fast' element={<FastTrade />} />
                <Route element={<PrivateRoute />}>
                  <Route path='/crypto_trade/futures' element={<TradingPage />} />
                  <Route path='/crypto_trade/nft' element={<NFTPrediction />} />
                  <Route path='/defi' element={<DefiPage />} />
                  <Route path='/original_games' element={<OriginalGamePage />} />
                  <Route path='/original_games/aviator' element={<AviatorGame />} />
                  <Route path='/original_games/limbo' element={<LimboGame />} />
                  <Route path='/original_games/degen' element={<DegenSlotGame />} />
                  <Route path='/original_games/dice' element={<DiceGame />} />
                  <Route path='/setting' element={<SettingPage />} />
                  <Route path='/nft_game' element={<NFTGamePage />} />
                </Route>
                <Route path='*' element={<Navigate to="/" replace />} />
              </Route>
            </Routes>
          </MainContextProvider>
        </BrowserRouter>
      </Web3OnboardProvider>
    </div>
  );
}

export default App;
