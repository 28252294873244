import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from 'react-apexcharts';
import { Button, Dropdown, Image } from "react-bootstrap";
import { BTCIcon, BnbIcon, BspIcon, ChartButtonImage1, ChartButtonImage2, ChartButtonImage3, EthIcon, UsdtIcon } from "../../assets";
import axios from "axios";
import { socket } from "../../utils/socket";

interface PayoutProps {
  id: string;
  lose: string;
  win: string;
}
interface AssetListItemProps {
  assetId: string;
  assetCode: string;
  label: string;
  ico_1: string;
  ico_2: string;
  is_default: string;
  position: string;
  payouts: PayoutProps[];
}

interface DurationItemProps {
  id: number;
  label: string;
  duration: string;
  duration_minimun: string;
  is_default: string;
}

const data = {
  series: [
    {
      data: [
        {
          x: new Date(1538778600000),
          y: [6629.81, 6650.5, 6623.04, 6633.33],
        },
        {
          x: new Date(1538780400000),
          y: [6632.01, 6643.59, 6620, 6630.11],
        },
        {
          x: new Date(1538782200000),
          y: [6630.71, 6648.95, 6623.34, 6635.65],
        },
        {
          x: new Date(1538784000000),
          y: [6635.65, 6651, 6629.67, 6638.24],
        },
        {
          x: new Date(1538785800000),
          y: [6638.24, 6640, 6620, 6624.47],
        },
        {
          x: new Date(1538787600000),
          y: [6624.53, 6636.03, 6621.68, 6624.31],
        },
        {
          x: new Date(1538789400000),
          y: [6624.61, 6632.2, 6617, 6626.02],
        },
        {
          x: new Date(1538791200000),
          y: [6627, 6627.62, 6584.22, 6603.02],
        },
        {
          x: new Date(1538793000000),
          y: [6605, 6608.03, 6598.95, 6604.01],
        },
        {
          x: new Date(1538794800000),
          y: [6604.5, 6614.4, 6602.26, 6608.02],
        },
        {
          x: new Date(1538796600000),
          y: [6608.02, 6610.68, 6601.99, 6608.91],
        },
        {
          x: new Date(1538798400000),
          y: [6608.91, 6618.99, 6608.01, 6612],
        },
        {
          x: new Date(1538800200000),
          y: [6612, 6615.13, 6605.09, 6612],
        },
        {
          x: new Date(1538802000000),
          y: [6612, 6624.12, 6608.43, 6622.95],
        },
        {
          x: new Date(1538803800000),
          y: [6623.91, 6623.91, 6615, 6615.67],
        },
        {
          x: new Date(1538805600000),
          y: [6618.69, 6618.74, 6610, 6610.4],
        },
        {
          x: new Date(1538807400000),
          y: [6611, 6622.78, 6610.4, 6614.9],
        },
        {
          x: new Date(1538809200000),
          y: [6614.9, 6626.2, 6613.33, 6623.45],
        },
        {
          x: new Date(1538811000000),
          y: [6623.48, 6627, 6618.38, 6620.35],
        },
        {
          x: new Date(1538812800000),
          y: [6619.43, 6620.35, 6610.05, 6615.53],
        },
        {
          x: new Date(1538814600000),
          y: [6615.53, 6617.93, 6610, 6615.19],
        },
        {
          x: new Date(1538816400000),
          y: [6615.19, 6621.6, 6608.2, 6620],
        },
        {
          x: new Date(1538818200000),
          y: [6619.54, 6625.17, 6614.15, 6620],
        },
        {
          x: new Date(1538820000000),
          y: [6620.33, 6634.15, 6617.24, 6624.61],
        },
        {
          x: new Date(1538821800000),
          y: [6625.95, 6626, 6611.66, 6617.58],
        },
        {
          x: new Date(1538823600000),
          y: [6619, 6625.97, 6595.27, 6598.86],
        },
        {
          x: new Date(1538825400000),
          y: [6598.86, 6598.88, 6570, 6587.16],
        },
        {
          x: new Date(1538827200000),
          y: [6588.86, 6600, 6580, 6593.4],
        },
        {
          x: new Date(1538829000000),
          y: [6593.99, 6598.89, 6585, 6587.81],
        },
        {
          x: new Date(1538830800000),
          y: [6587.81, 6592.73, 6567.14, 6578],
        },
        {
          x: new Date(1538832600000),
          y: [6578.35, 6581.72, 6567.39, 6579],
        },
        {
          x: new Date(1538834400000),
          y: [6579.38, 6580.92, 6566.77, 6575.96],
        },
        {
          x: new Date(1538836200000),
          y: [6575.96, 6589, 6571.77, 6588.92],
        },
        {
          x: new Date(1538838000000),
          y: [6588.92, 6594, 6577.55, 6589.22],
        },
        {
          x: new Date(1538839800000),
          y: [6589.3, 6598.89, 6589.1, 6596.08],
        },
        {
          x: new Date(1538841600000),
          y: [6597.5, 6600, 6588.39, 6596.25],
        },
        {
          x: new Date(1538843400000),
          y: [6598.03, 6600, 6588.73, 6595.97],
        },
        {
          x: new Date(1538845200000),
          y: [6595.97, 6602.01, 6588.17, 6602],
        },
        {
          x: new Date(1538847000000),
          y: [6602, 6607, 6596.51, 6599.95],
        },
        {
          x: new Date(1538848800000),
          y: [6600.63, 6601.21, 6590.39, 6591.02],
        },
        {
          x: new Date(1538850600000),
          y: [6591.02, 6603.08, 6591, 6591],
        },
        {
          x: new Date(1538852400000),
          y: [6591, 6601.32, 6585, 6592],
        },
        {
          x: new Date(1538854200000),
          y: [6593.13, 6596.01, 6590, 6593.34],
        },
        {
          x: new Date(1538856000000),
          y: [6593.34, 6604.76, 6582.63, 6593.86],
        },
        {
          x: new Date(1538857800000),
          y: [6593.86, 6604.28, 6586.57, 6600.01],
        },
        {
          x: new Date(1538859600000),
          y: [6601.81, 6603.21, 6592.78, 6596.25],
        },
        {
          x: new Date(1538861400000),
          y: [6596.25, 6604.2, 6590, 6602.99],
        },
        {
          x: new Date(1538863200000),
          y: [6602.99, 6606, 6584.99, 6587.81],
        },
        {
          x: new Date(1538865000000),
          y: [6587.81, 6595, 6583.27, 6591.96],
        },
        {
          x: new Date(1538866800000),
          y: [6591.97, 6596.07, 6585, 6588.39],
        },
        {
          x: new Date(1538868600000),
          y: [6587.6, 6598.21, 6587.6, 6594.27],
        },
        {
          x: new Date(1538870400000),
          y: [6596.44, 6601, 6590, 6596.55],
        },
        {
          x: new Date(1538872200000),
          y: [6598.91, 6605, 6596.61, 6600.02],
        },
        {
          x: new Date(1538874000000),
          y: [6600.55, 6605, 6589.14, 6593.01],
        },
        {
          x: new Date(1538875800000),
          y: [6593.15, 6605, 6592, 6603.06],
        },
        {
          x: new Date(1538877600000),
          y: [6603.07, 6604.5, 6599.09, 6603.89],
        },
        {
          x: new Date(1538879400000),
          y: [6604.44, 6604.44, 6600, 6603.5],
        },
        {
          x: new Date(1538881200000),
          y: [6603.5, 6603.99, 6597.5, 6603.86],
        },
        {
          x: new Date(1538883000000),
          y: [6603.85, 6605, 6600, 6604.07],
        },
        {
          x: new Date(1538884800000),
          y: [6604.98, 6606, 6604.07, 6606],
        },
      ],
    },
  ],
  options: {
    chart: {
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: "zoom"
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      }
    },
    tooltip: {
      theme: 'dark'
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: 'white'
        }
      },
      tickAmount: 6,
      tickPlacement: 'between',
      show: false,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        height: 6,
        offsetX: 0,
        offsetY: 0
      },
    },
    yaxis: {
      opposite: true,
      show: true,
      showAlways: true,
      labels: {
        style: {
          colors: 'white'
        }
      },
      axisBorder: {
        show: true,
      },
    },
    grid: {
      show: true,
      borderColor: '#343753',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        }
      }
    },
  }
};
export const TradingChart: React.FunctionComponent<{ assetlist: any }> = (props) => {
  const [chartType, setChartType] = useState<"area" | "line" | "bar" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "candlestick" | "boxPlot" | "radar" | "polarArea" | "rangeBar" | "rangeArea" | "treemap" | undefined>('candlestick');
  const [selectAsset, setSelectAsset] = useState<AssetListItemProps | null>(null);
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const [durationList, setDurationList] = useState<DurationItemProps[] | null>(null);
  const [assetLastValue, setAssetLastValue] = useState<any | null>(null);
  const chartRef = useRef<any>(null);
  const token = window.localStorage.getItem('token');

  interface DataItem {
    h: string;
    t: string;
    v: string;
  }

  interface SeriesData {
    x: Date;
    y: any;
  }

  interface ChartData {
    series: { data: SeriesData[] }[];
  }

  const getDurationList = async () => {
    const headers = {
      contentType: 'application/json',
      Authorization: token,
      token: token
    }

    await axios.get(`${process.env.REACT_APP_API_URL}/trade/durations/list/`, { headers })
      .then(function (response) {
        setDurationList(response.data.records);
        console.log("duration--------------------", response.data.records);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleImageError = (event: any) => {
    event.target.src = 'https://api.dev.bitnswap.com/design/img/generic.svg';
  };

  const selectAssetList = (assetId: string, assetCode: string, label: string, ico_1: string, ico_2: string, is_default: string, payouts: PayoutProps[], position: string) => {
    setSelectAsset({ assetId: assetId, assetCode: assetCode, label: label, ico_1: ico_1, ico_2: ico_2, is_default: is_default, payouts: payouts, position: position });
    console.log("start", { assetId: assetId, assetCode: assetCode, label: label, ico_1: ico_1, ico_2: ico_2, is_default: is_default, payouts: payouts, position: position })
    socket.emit("subscribe-asset", { assetId: assetId, assetCode: assetCode });
    if (chartType === "candlestick") {
      console.log("chartType", chartType)
      socket.on("asset-history-candles", (response: any) => {
        console.log("asset-history-candles", response)
        if (response.duration === 5) {
          const chartData: ChartData = {
            series: [{
              data: response.datas.map((row: any): SeriesData => ({
                x: new Date(parseInt(row[0])), // Convert Unix timestamp to milliseconds
                y: [parseFloat(row[1]), parseFloat(row[2]), parseFloat(row[3]), parseFloat(row[4])] // Parse value to float

              }))
            }]
          };
          setChartData(chartData);
          return
        }
      })
      socket.on("asset-live-candles", (response: any) => {
        if (response.duration === 5) {
          console.log("asset-live-candles", response)
          setAssetLastValue(response);
          return
        }
      })
    } else {
      console.log("chartType", chartType)
      socket.on("asset-history", (response: any) => {
        const chartData: ChartData = {
          series: [{
            data: response.datas.map((row: DataItem): SeriesData => ({
              x: new Date(parseInt(row.t) * 1000), // Convert Unix timestamp to milliseconds
              y: parseFloat(row.v) // Parse value to float
            }))
          }]
        };
        setChartData(chartData);
        console.log("asset-history", chartData)
      })
      socket.on("asset-history-last-value", (response: any) => {
        setAssetLastValue(response)
        console.log("asset-history-last-value", response);
      })

      return () => {
        socket.off("asset-history", (response: any) => {
            console.log("off-asset-history", response);
        });
        socket.off("asset-history-last-value", (response: any) => {
            console.log("off-asset-history", response);
        });
    };
    }
  }

  useEffect(() => {
    if (!assetLastValue) return; // Ensure assetLastValue is not null or undefined
    let lastValue: any;
    if (chartType === "candlestick") {
      lastValue = {
        x: new Date(parseInt(assetLastValue.graph[0])), // Convert Unix timestamp to milliseconds
        y: [parseFloat(assetLastValue.graph[1]), parseFloat(assetLastValue.graph[2]), parseFloat(assetLastValue.graph[3]), parseFloat(assetLastValue.graph[4])]// Parse value to float
      };

      console.log("lastValue", lastValue)
    } else {
      const { t, v } = assetLastValue.graph;
      lastValue = {
        x: new Date(parseInt(t) * 1000), // Convert Unix timestamp to milliseconds
        y: parseFloat(v) // Parse value to float
      };
    }

    setChartData(prevChartData => {
      let newData;
      if (!prevChartData || prevChartData.series[0].data.length === 0) {
        newData = [lastValue];
      } else {
        const currentData = prevChartData.series[0].data;
        // newData = currentData.slice(1); // Remove the first element
        // newData.push(lastValue); // Append lastValue

        const newDataLength = currentData.length + 1; // Length after adding lastValue
        const startIndex = newDataLength > 100 ? newDataLength - 5 : 0; // Calculate start index for slicing
        newData = currentData.slice(startIndex).concat(lastValue);
      }

      return { series: [{ data: newData }] };
    });
  }, [assetLastValue, chartType]);

  useEffect(() => {
    getDurationList();
    selectAssetList("201", "btcusd", "BTC/USD", "BTC.svg", "USD.svg", "1", [{ id: '2105', win: '75', lose: '5' }], "1");
  }, []);


  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
        <div className="">
          <Dropdown className="token_trade_dropdown mb-2">
            {selectAsset === null ? (
              <Dropdown.Toggle variant="success" id="dropdown-basic" className="d-flex align-items-center p-0 bg-transparent border border-white rounded-5 pt-1 ps-3 pe-2 fs-4">Select Asset</Dropdown.Toggle>
            ) : (
              <Dropdown.Toggle variant="success" id="dropdown-basic" className="d-flex align-items-center p-0 bg-transparent border border-white rounded-5 py-0 px-2">
                <div className="me-2">
                  <Image src={`https://api.dev.bitnswap.com/design/img/${selectAsset?.ico_1 === "" ? "generic.svg" : ("/assets/" + selectAsset?.ico_1)}`} width={30} height={30} alt="coin" className="me-2" onError={handleImageError} />
                  <Image src={`https://api.dev.bitnswap.com/design/img/${selectAsset?.ico_2 === "" ? "generic.svg" : ("/assets/" + selectAsset?.ico_2)}`} width={30} height={30} alt="coin" className="me-2" onError={handleImageError} />
                </div>
                <div className="me-2 text-start">
                  <div className="token_name">{selectAsset?.label}</div>
                  <div className="token_price"><span className="text-danger">{selectAsset?.payouts.length === 0 ? "0" : selectAsset?.payouts[0]?.lose}</span> / <span className="text-success">{selectAsset?.payouts.length === 0 ? "0" : selectAsset?.payouts[0]?.win}</span></div>
                </div>
              </Dropdown.Toggle>
            )}

            <Dropdown.Menu className="bg-primary-1 border-white">
              {Object.entries(props.assetlist).map((item: any) => {
                return (
                  <Dropdown.Item className="d-flex align-items-center" onClick={() => selectAssetList(item[1].assetId, item[1].assetCode, item[1].label, item[1].ico1, item[1].ico2, item[1].isDefault, item[1].payouts, item[1].position,)}>
                    <div className="me-2">
                      <Image src={`https://api.dev.bitnswap.com/design/img/${item[1].ico1 === "" ? "generic.svg" : ("/assets/" + item[1].ico1)}`} width={30} height={30} alt="coin" className="me-2" onError={handleImageError} />
                      <Image src={`https://api.dev.bitnswap.com/design/img/${item[1].ico2 === "" ? "generic.svg" : ("/assets/" + item[1].ico2)}`} width={30} height={30} alt="coin" className="me-2" onError={handleImageError} />
                    </div>
                    <div className="me-2 text-start">
                      <div className="token_name">{item[1].label}</div>
                      <div className="token_price"><span className="text-danger">{item[1].payouts.length === 0 ? "0" : item[1].payouts[0]?.lose}</span> / <span className="text-success">{item[1].payouts.length === 0 ? "0" : item[1].payouts[0]?.win}</span></div>
                    </div>
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="d-flex mb-2">
          <Button className="bg-transparent border-0 p-0 me-2" onClick={() => setChartType('area')}><Image src={ChartButtonImage1} width={40} alt="chart" /></Button>
          <Button className="bg-transparent border-0 p-0 me-2" onClick={() => setChartType('line')}><Image src={ChartButtonImage2} width={40} alt="chart" /></Button>
          <Button className="bg-transparent border-0 p-0 me-2" onClick={() => setChartType('candlestick')}><Image src={ChartButtonImage3} width={40} alt="chart" /></Button>
          <Dropdown className="chart_option">
            <Dropdown.Toggle variant="success" id="dropdown-basic" className="border-white rounded-5 bg-transparent">
              INDICATORS
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-2">1 Min</Dropdown.Item>
              <Dropdown.Item href="#/action-2"> Min</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <ReactApexChart
        ref={chartRef}
        options={data.options as any}
        series={chartData === null ? data.series : chartData.series as any}
        type={chartType}
        height={350}
      />
    </>
  )
}

const data1 = {
  series: [
    {
      data: [
        {
          x: new Date(1538778600000),
          y: [6629.81, 6650.5, 6623.04, 6633.33],
        },
        {
          x: new Date(1538780400000),
          y: [6632.01, 6643.59, 6620, 6630.11],
        },
        {
          x: new Date(1538782200000),
          y: [6630.71, 6648.95, 6623.34, 6635.65],
        },
        {
          x: new Date(1538784000000),
          y: [6635.65, 6651, 6629.67, 6638.24],
        },
        {
          x: new Date(1538785800000),
          y: [6638.24, 6640, 6620, 6624.47],
        },
        {
          x: new Date(1538787600000),
          y: [6624.53, 6636.03, 6621.68, 6624.31],
        },
        {
          x: new Date(1538789400000),
          y: [6624.61, 6632.2, 6617, 6626.02],
        },
        {
          x: new Date(1538791200000),
          y: [6627, 6627.62, 6584.22, 6603.02],
        },
        {
          x: new Date(1538793000000),
          y: [6605, 6608.03, 6598.95, 6604.01],
        },
        {
          x: new Date(1538794800000),
          y: [6604.5, 6614.4, 6602.26, 6608.02],
        },
        {
          x: new Date(1538796600000),
          y: [6608.02, 6610.68, 6601.99, 6608.91],
        },
        {
          x: new Date(1538798400000),
          y: [6608.91, 6618.99, 6608.01, 6612],
        },
        {
          x: new Date(1538800200000),
          y: [6612, 6615.13, 6605.09, 6612],
        },
        {
          x: new Date(1538802000000),
          y: [6612, 6624.12, 6608.43, 6622.95],
        },
        {
          x: new Date(1538803800000),
          y: [6623.91, 6623.91, 6615, 6615.67],
        },
        {
          x: new Date(1538805600000),
          y: [6618.69, 6618.74, 6610, 6610.4],
        },
        {
          x: new Date(1538807400000),
          y: [6611, 6622.78, 6610.4, 6614.9],
        },
        {
          x: new Date(1538809200000),
          y: [6614.9, 6626.2, 6613.33, 6623.45],
        },
        {
          x: new Date(1538811000000),
          y: [6623.48, 6627, 6618.38, 6620.35],
        },
        {
          x: new Date(1538812800000),
          y: [6619.43, 6620.35, 6610.05, 6615.53],
        },
        {
          x: new Date(1538814600000),
          y: [6615.53, 6617.93, 6610, 6615.19],
        },
        {
          x: new Date(1538816400000),
          y: [6615.19, 6621.6, 6608.2, 6620],
        },
        {
          x: new Date(1538818200000),
          y: [6619.54, 6625.17, 6614.15, 6620],
        },
        {
          x: new Date(1538820000000),
          y: [6620.33, 6634.15, 6617.24, 6624.61],
        },
        {
          x: new Date(1538821800000),
          y: [6625.95, 6626, 6611.66, 6617.58],
        },
        {
          x: new Date(1538823600000),
          y: [6619, 6625.97, 6595.27, 6598.86],
        },
        {
          x: new Date(1538825400000),
          y: [6598.86, 6598.88, 6570, 6587.16],
        },
        {
          x: new Date(1538827200000),
          y: [6588.86, 6600, 6580, 6593.4],
        },
        {
          x: new Date(1538829000000),
          y: [6593.99, 6598.89, 6585, 6587.81],
        },
        {
          x: new Date(1538830800000),
          y: [6587.81, 6592.73, 6567.14, 6578],
        },
        {
          x: new Date(1538832600000),
          y: [6578.35, 6581.72, 6567.39, 6579],
        },
        {
          x: new Date(1538834400000),
          y: [6579.38, 6580.92, 6566.77, 6575.96],
        },
        {
          x: new Date(1538836200000),
          y: [6575.96, 6589, 6571.77, 6588.92],
        },
        {
          x: new Date(1538838000000),
          y: [6588.92, 6594, 6577.55, 6589.22],
        },
        {
          x: new Date(1538839800000),
          y: [6589.3, 6598.89, 6589.1, 6596.08],
        },
        {
          x: new Date(1538841600000),
          y: [6597.5, 6600, 6588.39, 6596.25],
        },
        {
          x: new Date(1538843400000),
          y: [6598.03, 6600, 6588.73, 6595.97],
        },
        {
          x: new Date(1538845200000),
          y: [6595.97, 6602.01, 6588.17, 6602],
        },
        {
          x: new Date(1538847000000),
          y: [6602, 6607, 6596.51, 6599.95],
        },
        {
          x: new Date(1538848800000),
          y: [6600.63, 6601.21, 6590.39, 6591.02],
        },
        {
          x: new Date(1538850600000),
          y: [6591.02, 6603.08, 6591, 6591],
        },
        {
          x: new Date(1538852400000),
          y: [6591, 6601.32, 6585, 6592],
        },
        {
          x: new Date(1538854200000),
          y: [6593.13, 6596.01, 6590, 6593.34],
        },
        {
          x: new Date(1538856000000),
          y: [6593.34, 6604.76, 6582.63, 6593.86],
        },
        {
          x: new Date(1538857800000),
          y: [6593.86, 6604.28, 6586.57, 6600.01],
        },
        {
          x: new Date(1538859600000),
          y: [6601.81, 6603.21, 6592.78, 6596.25],
        },
        {
          x: new Date(1538861400000),
          y: [6596.25, 6604.2, 6590, 6602.99],
        },
        {
          x: new Date(1538863200000),
          y: [6602.99, 6606, 6584.99, 6587.81],
        },
        {
          x: new Date(1538865000000),
          y: [6587.81, 6595, 6583.27, 6591.96],
        },
        {
          x: new Date(1538866800000),
          y: [6591.97, 6596.07, 6585, 6588.39],
        },
        {
          x: new Date(1538868600000),
          y: [6587.6, 6598.21, 6587.6, 6594.27],
        },
        {
          x: new Date(1538870400000),
          y: [6596.44, 6601, 6590, 6596.55],
        },
        {
          x: new Date(1538872200000),
          y: [6598.91, 6605, 6596.61, 6600.02],
        },
        {
          x: new Date(1538874000000),
          y: [6600.55, 6605, 6589.14, 6593.01],
        },
        {
          x: new Date(1538875800000),
          y: [6593.15, 6605, 6592, 6603.06],
        },
        {
          x: new Date(1538877600000),
          y: [6603.07, 6604.5, 6599.09, 6603.89],
        },
        {
          x: new Date(1538879400000),
          y: [6604.44, 6604.44, 6600, 6603.5],
        },
        {
          x: new Date(1538881200000),
          y: [6603.5, 6603.99, 6597.5, 6603.86],
        },
        {
          x: new Date(1538883000000),
          y: [6603.85, 6605, 6600, 6604.07],
        },
        {
          x: new Date(1538884800000),
          y: [6604.98, 6606, 6604.07, 6606],
        },
      ],
    },
  ],
  options: {
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      autoSelected: 'pan'
    },
    tooltip: {
      enabled: false
    },
    colors: ['#FF8A00', 'yellow', '#546E7A', '#E91E63', '#FF9800'],
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    xaxis: {
      axisBorder: {
        show: false,
        color: 'red'
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
        color: 'red',
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false
      },
    },
    grid: {
      show: true,
      borderColor: 'white',
      strokeDashArray: 1,
      position: 'back',
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },
  }
};
export const MenuChart: React.FunctionComponent = () => {
  return (
    <ReactApexChart
      options={data1.options as any}
      series={data1.series}
      type="area"
      height={150}
    />
  )
}