import React, { useState } from "react";
import { Card, Container, Row, Col, Image, InputGroup, Form, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { DownIcon, NFTImage1, UpIcon } from "../../assets";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ReactApexChart from 'react-apexcharts';
import { NFTTradeTable } from "../../components/tables/tables";
import { NavLink } from "react-router-dom";

const NFTPrediction: React.FunctionComponent = () => {
    const [rangeValue, setRangeValue] = useState<number>(50);
    const renderTime = ({ remainingTime }: { remainingTime: number }) => {
        if (remainingTime === 0) {
            return <div className="timer">Too lale...</div>;
        }

        return (
            <div className="timer text-center">
                <div className="value">{Math.floor(remainingTime / 60)}</div>
                <div className="text">Minutes</div>
            </div>
        );
    };

    const data = {
        series: [
            {
                data: [
                    {
                        x: new Date(1538778600000),
                        y: [6629.81, 6650.5, 6623.04, 6633.33],
                    },
                    {
                        x: new Date(1538780400000),
                        y: [6632.01, 6643.59, 6620, 6630.11],
                    },
                    {
                        x: new Date(1538782200000),
                        y: [6630.71, 6648.95, 6623.34, 6635.65],
                    },
                    {
                        x: new Date(1538784000000),
                        y: [6635.65, 6651, 6629.67, 6638.24],
                    },
                    {
                        x: new Date(1538785800000),
                        y: [6638.24, 6640, 6620, 6624.47],
                    },
                    {
                        x: new Date(1538787600000),
                        y: [6624.53, 6636.03, 6621.68, 6624.31],
                    },
                    {
                        x: new Date(1538789400000),
                        y: [6624.61, 6632.2, 6617, 6626.02],
                    },
                    {
                        x: new Date(1538791200000),
                        y: [6627, 6627.62, 6584.22, 6603.02],
                    },
                    {
                        x: new Date(1538793000000),
                        y: [6605, 6608.03, 6598.95, 6604.01],
                    },
                    {
                        x: new Date(1538794800000),
                        y: [6604.5, 6614.4, 6602.26, 6608.02],
                    },
                    {
                        x: new Date(1538796600000),
                        y: [6608.02, 6610.68, 6601.99, 6608.91],
                    },
                    {
                        x: new Date(1538798400000),
                        y: [6608.91, 6618.99, 6608.01, 6612],
                    },
                    {
                        x: new Date(1538800200000),
                        y: [6612, 6615.13, 6605.09, 6612],
                    },
                    {
                        x: new Date(1538802000000),
                        y: [6612, 6624.12, 6608.43, 6622.95],
                    },
                    {
                        x: new Date(1538803800000),
                        y: [6623.91, 6623.91, 6615, 6615.67],
                    },
                    {
                        x: new Date(1538805600000),
                        y: [6618.69, 6618.74, 6610, 6610.4],
                    },
                    {
                        x: new Date(1538807400000),
                        y: [6611, 6622.78, 6610.4, 6614.9],
                    },
                    {
                        x: new Date(1538809200000),
                        y: [6614.9, 6626.2, 6613.33, 6623.45],
                    },
                    {
                        x: new Date(1538811000000),
                        y: [6623.48, 6627, 6618.38, 6620.35],
                    },
                    {
                        x: new Date(1538812800000),
                        y: [6619.43, 6620.35, 6610.05, 6615.53],
                    },
                    {
                        x: new Date(1538814600000),
                        y: [6615.53, 6617.93, 6610, 6615.19],
                    },
                    {
                        x: new Date(1538816400000),
                        y: [6615.19, 6621.6, 6608.2, 6620],
                    },
                    {
                        x: new Date(1538818200000),
                        y: [6619.54, 6625.17, 6614.15, 6620],
                    },
                    {
                        x: new Date(1538820000000),
                        y: [6620.33, 6634.15, 6617.24, 6624.61],
                    },
                    {
                        x: new Date(1538821800000),
                        y: [6625.95, 6626, 6611.66, 6617.58],
                    },
                    {
                        x: new Date(1538823600000),
                        y: [6619, 6625.97, 6595.27, 6598.86],
                    },
                    {
                        x: new Date(1538825400000),
                        y: [6598.86, 6598.88, 6570, 6587.16],
                    },
                    {
                        x: new Date(1538827200000),
                        y: [6588.86, 6600, 6580, 6593.4],
                    },
                    {
                        x: new Date(1538829000000),
                        y: [6593.99, 6598.89, 6585, 6587.81],
                    },
                    {
                        x: new Date(1538830800000),
                        y: [6587.81, 6592.73, 6567.14, 6578],
                    },
                    {
                        x: new Date(1538832600000),
                        y: [6578.35, 6581.72, 6567.39, 6579],
                    },
                    {
                        x: new Date(1538834400000),
                        y: [6579.38, 6580.92, 6566.77, 6575.96],
                    },
                    {
                        x: new Date(1538836200000),
                        y: [6575.96, 6589, 6571.77, 6588.92],
                    },
                    {
                        x: new Date(1538838000000),
                        y: [6588.92, 6594, 6577.55, 6589.22],
                    },
                    {
                        x: new Date(1538839800000),
                        y: [6589.3, 6598.89, 6589.1, 6596.08],
                    },
                    {
                        x: new Date(1538841600000),
                        y: [6597.5, 6600, 6588.39, 6596.25],
                    },
                    {
                        x: new Date(1538843400000),
                        y: [6598.03, 6600, 6588.73, 6595.97],
                    },
                    {
                        x: new Date(1538845200000),
                        y: [6595.97, 6602.01, 6588.17, 6602],
                    },
                    {
                        x: new Date(1538847000000),
                        y: [6602, 6607, 6596.51, 6599.95],
                    },
                    {
                        x: new Date(1538848800000),
                        y: [6600.63, 6601.21, 6590.39, 6591.02],
                    },
                    {
                        x: new Date(1538850600000),
                        y: [6591.02, 6603.08, 6591, 6591],
                    },
                    {
                        x: new Date(1538852400000),
                        y: [6591, 6601.32, 6585, 6592],
                    },
                    {
                        x: new Date(1538854200000),
                        y: [6593.13, 6596.01, 6590, 6593.34],
                    },
                    {
                        x: new Date(1538856000000),
                        y: [6593.34, 6604.76, 6582.63, 6593.86],
                    },
                    {
                        x: new Date(1538857800000),
                        y: [6593.86, 6604.28, 6586.57, 6600.01],
                    },
                    {
                        x: new Date(1538859600000),
                        y: [6601.81, 6603.21, 6592.78, 6596.25],
                    },
                    {
                        x: new Date(1538861400000),
                        y: [6596.25, 6604.2, 6590, 6602.99],
                    },
                    {
                        x: new Date(1538863200000),
                        y: [6602.99, 6606, 6584.99, 6587.81],
                    },
                    {
                        x: new Date(1538865000000),
                        y: [6587.81, 6595, 6583.27, 6591.96],
                    },
                    {
                        x: new Date(1538866800000),
                        y: [6591.97, 6596.07, 6585, 6588.39],
                    },
                    {
                        x: new Date(1538868600000),
                        y: [6587.6, 6598.21, 6587.6, 6594.27],
                    },
                    {
                        x: new Date(1538870400000),
                        y: [6596.44, 6601, 6590, 6596.55],
                    },
                    {
                        x: new Date(1538872200000),
                        y: [6598.91, 6605, 6596.61, 6600.02],
                    },
                    {
                        x: new Date(1538874000000),
                        y: [6600.55, 6605, 6589.14, 6593.01],
                    },
                    {
                        x: new Date(1538875800000),
                        y: [6593.15, 6605, 6592, 6603.06],
                    },
                    {
                        x: new Date(1538877600000),
                        y: [6603.07, 6604.5, 6599.09, 6603.89],
                    },
                    {
                        x: new Date(1538879400000),
                        y: [6604.44, 6604.44, 6600, 6603.5],
                    },
                    {
                        x: new Date(1538881200000),
                        y: [6603.5, 6603.99, 6597.5, 6603.86],
                    },
                    {
                        x: new Date(1538883000000),
                        y: [6603.85, 6605, 6600, 6604.07],
                    },
                    {
                        x: new Date(1538884800000),
                        y: [6604.98, 6606, 6604.07, 6606],
                    },
                ],
            },
        ],
        options: {
            chart: {
                type: "line",
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
                autoSelected: 'pan',

            },
            annotations: {
                yaxis: [{
                    y: 0,
                    y2: 6600.24,
                    borderColor: '#C2060D',
                    fillColor:'#C2060D',
                    opacity: 0.3
                },
                {
                    y: 6600.24,
                    y2: 8000.00,
                    borderColor: '#58FF00',
                    fillColor:'#58FF00',
                    opacity: 0.3
                },
            ]
            },
            tooltip: {
                theme: 'dark'
            },
            colors: ['#FF8A00', 'yellow', '#546E7A', '#E91E63', '#FF9800'],
            dataLabels: {
                enabled: false
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 0,
                    opacityFrom: 0.9,
                    opacityTo: 0,
                    stops: [10, 100]
                }
            },
            xaxis: {
                axisBorder: {
                    show: false,
                    color: 'red'
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false
                },
            },
            yaxis: {
                axisBorder: {
                    show: false,
                    color: 'red',
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false
                },
            },
            grid: {
                show: true,
                borderColor: 'white',
                strokeDashArray: 1,
                position: 'back',
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: -10,
                    left: -10
                },
            },
        }
    };

    const popover = (
        <Popover id="popover-basic" data-bs-theme="dark" className="bg-primary-1">
            <Popover.Body>
                <NavLink target="_blank" to="https://polygonscan.com/tx/0xe3d0b5ae9f69063253d98d32a55cf9d041162f0530fc72d0c653290f94e77bfc" className="nav-link bg-secondary-1 border-secondary-2 text-primary-1 rounded-5 border-2 px-3 py-2 border rounded-2 mb-2 fw-bold fs-5">Click Hash</NavLink>
                <div className="text-center">
                    <small>Start Rate</small>
                    <div className="fw-bold fs-5">42,951.1240</div>
                </div>
                <hr className=""/>
                <div className="text-center">
                    <small>Start Rate</small>
                    <div className="fw-bold fs-5">42,951.1240</div>
                </div>
            </Popover.Body>
        </Popover>
    );

    return (
        <div className="NFTPrediction">
            <Container fluid>
                <Card className="border-white bg-primary-2 mb-3">
                    <Card.Body>
                        <Row>
                            <Col sm={12} lg={8} className="mb-3 align-items-center">
                                <Card className="border border-white bg-primary-2">
                                    <Card.Body className="position-relative">
                                        <ReactApexChart
                                            options={data.options as any}
                                            series={data.series}
                                            type="area"
                                            height={700}
                                        />
                                        <div className="nft-trading-transaction d-flex flex-warp justify-content-between">
                                            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                                <Button className="rounded-2 bg-transparent border-2 p-0 mx-2 border-secondary-2 border p-2 mb-2"><Image src={UpIcon} width={30} alt="icon" /></Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                                <Button className="rounded-2 bg-transparent border-2 p-0 mx-2 border-secondary-2 border p-2 mb-2"><Image src={DownIcon} width={30} alt="icon" /></Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                                <Button className="rounded-2 bg-transparent border-2 p-0 mx-2 border-secondary-2 border p-2 mb-2"><Image src={UpIcon} width={30} alt="icon" /></Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                                <Button className="rounded-2 bg-transparent border-2 p-0 mx-2 border-secondary-2 border p-2 mb-2"><Image src={DownIcon} width={30} alt="icon" /></Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                                <Button className="rounded-2 bg-transparent border-2 p-0 mx-2 border-secondary-2 border p-2 mb-2"><Image src={DownIcon} width={30} alt="icon" /></Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                                <Button className="rounded-2 bg-transparent border-2 p-0 mx-2 border-secondary-2 border p-2 mb-2"><Image src={UpIcon} width={30} alt="icon" /></Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                                <Button className="rounded-2 bg-transparent border-2 p-0 mx-2 border-secondary-2 border p-2 mb-2"><Image src={DownIcon} width={30} alt="icon" /></Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                                <Button className="rounded-2 bg-transparent border-2 p-0 mx-2 border-secondary-2 border p-2 mb-2"><Image src={UpIcon} width={30} alt="icon" /></Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                                <Button className="rounded-2 bg-transparent border-2 p-0 mx-2 border-secondary-2 border p-2 mb-2"><Image src={UpIcon} width={30} alt="icon" /></Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                                <Button className="rounded-2 bg-transparent border-2 p-0 mx-2 border-secondary-2 border p-2 mb-2"><Image src={UpIcon} width={30} alt="icon" /></Button>
                                            </OverlayTrigger>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12} lg={4} className="">
                                <Image src={NFTImage1} width="100%" alt="NFT" className="border border-white rounded-4 mb-3" />
                                <div className="nfttrade-info d-flex align-items-center justify-content-between mb-3 text-start">
                                    <div className="title">CURRENT PRICE:</div>
                                    <div className="price">1,913.12</div>
                                </div>
                                <Row>
                                    <Col sm={12} lg={6} className="mb-3 d-flex align-items-center justify-content-center">
                                        <CountdownCircleTimer isPlaying duration={900} colors={["#F24F09", "#F24F09", "#F24F09", "#F24F09"] as any} onComplete={() => ({ shouldRepeat: true, delay: 1 })}>
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <div className="group-title mb-2">payout multiplier</div>
                                        <Row className="mb-2">
                                            <Col sm={6} lg={6}>
                                                <InputGroup className="border rounded-5 border-white">
                                                    <InputGroup.Text id="basic-addon1" className="bg-transparent border-0">x</InputGroup.Text>
                                                    <Form.Control
                                                        className="bg-transparent border-0 text-center"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        value={rangeValue}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col sm={6} lg={6} className="bust_price">
                                                <div className="text-end">BUST PRICE:</div>
                                                <div className="text-end">{(rangeValue * 2248.94).toFixed(2)}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12} className="mb-3 payout_range_body">
                                                <input type="range" min="1" max="100" step={1} value={rangeValue} onChange={(e) => setRangeValue(parseFloat(e.target.value))} className="payout_range" id="myRange"></input>
                                                <div className="d-flex align-items-center justify-content-between mt-1">
                                                    <span>x1 ∙ <strong className="safe_color">SAFE</strong></span>
                                                    <span><strong className="wild_color">WILD</strong> ∙ x1000</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="group-title mb-2">will the price go up or down?</div>
                                        <Row>
                                            <Col sm={6} md={6}>
                                                <Button className="up-btn rounded-5 w-100 mb-2">UP</Button>
                                            </Col>
                                            <Col sm={6} md={6}>
                                                <Button className="down-btn rounded-5 w-100">down</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row cla>
                            <Col sm={12} md={12}>
                                <NFTTradeTable />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default NFTPrediction;