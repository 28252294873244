import React, { FunctionComponent, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { ColorLogo } from "../../assets";
import { useMainContext } from "../../context/mainContext";

interface PreloaderProps {
    isShow: 'none' | 'flex';
}

const Preloader: FunctionComponent = () => {
    const { isLoading, setIsLoading } = useMainContext();
    useEffect(() => {
        setTimeout(() => {
            setIsLoading('none')
        }, 3000);
    }, [])
    return (
        <section id="loader" style={{ display: isLoading }}>
            <Image src={ColorLogo} width={300} alt="logo" className="position-absolute" />
            <div id="preload">
                {[...Array(20)].map((_, index) => (
                    <span key={index} style={{ "--i": index + 1 } as React.CSSProperties}></span>
                ))}
            </div>
        </section>
    );
};

export default Preloader;