import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { ActiveTradeTable } from "../../components/tables/tables";
import CryptoPredictionChart, { ActiveTradeListItemProps } from "../../components/charts/cryptoPredictionChart";
import { socket } from "../../utils/socket";
import PredictionTradingChart from "../../components/charts/predictionTradingChart";

export interface ActiveTradeListProps {
    "900" : any;
    "300" : any;
    "120" : any;
    "60" : any;
}

const CryptoPrediction: React.FunctionComponent = () => {
    const [activeTradeTableData, setActiveTradeTableData] = useState<ActiveTradeListProps | []>([]);

    const handleActiveTradeTableData = (data: any) => {
        setActiveTradeTableData(data);
    };

    console.log("table-data", activeTradeTableData)

   
    return (
        <div className="TradingPage pb-5 mb-5">
            <PredictionTradingChart onData={handleActiveTradeTableData}/>            
        </div>
    );
};

// Chart component moved outside of the main component function
const Chart: React.FunctionComponent<{ handleActiveTradeTableData: (data: ActiveTradeListItemProps[]) => void; }> = ({ handleActiveTradeTableData }) => {
    // return <CryptoPredictionChart handleActiveTradeTableData={handleActiveTradeTableData} />;
    return <CryptoPredictionChart/>;
};

export default CryptoPrediction;
