import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { ActiveTradeTable } from "../../components/tables/tables";
import CryptoPredictionChart, { ActiveTradeListItemProps } from "../../components/charts/cryptoPredictionChart";
import { socket } from "../../utils/socket";
import FastAndFuriousChart from "../../components/charts/fastAndFuriousChart";

const FastTrade: React.FunctionComponent = () => {
    const [assetList, setAssetList] = useState<{ [key: string]: string }>({});
    const [activeTradeTableData, setActiveTradeTableData] = useState<ActiveTradeListItemProps[] | null>(null);

    const handleActiveTradeTableData = (data: ActiveTradeListItemProps[]) => {
        setActiveTradeTableData(data);
    };

    useEffect(() => {
        const handleAssetList = (data: any) => {

            setAssetList(data);
        };

        socket.on("assets-live-list-change", handleAssetList);

        return () => {
            socket.off("assets-live-list-change", handleAssetList);
        };
    }, []);

    return (
        <div className="TradingPage">
            <Container fluid>
                <Card className="border-white bg-primary-2 mb-3">
                    <Card.Body>
                        <Row>
                            <Col>
                                {/* <Chart assetList={assetList} handleActiveTradeTableData={handleActiveTradeTableData} /> */}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Tabs
                    defaultActiveKey="active_trade"
                    id="uncontrolled-tab-example"
                    className="mb-3 border-0"
                >
                    <Tab eventKey="active_trade" title="ACTIVE Trade">
                        <ActiveTradeTable data={activeTradeTableData} />
                    </Tab>
                    <Tab eventKey="closed_trade" title="CLOSED Trade">
                        {/* <ActiveTradeTable /> */}
                    </Tab>
                    <Tab eventKey="leaderboard" title="LEADERBOARD">
                        {/* <ActiveTradeTable /> */}
                    </Tab>
                </Tabs>
            </Container>
        </div>
    );
};

// Chart component moved outside of the main component function
const Chart: React.FunctionComponent<{ assetList: { [key: string]: string }; handleActiveTradeTableData: (data: ActiveTradeListItemProps[]) => void; }> = ({ assetList, handleActiveTradeTableData }) => {
    return <FastAndFuriousChart assetlist={assetList} handleActiveTradeTableData={handleActiveTradeTableData} />;
};

export default FastTrade;
