import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Dropdown, Image, Row } from "react-bootstrap";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import axios from "axios";
import { socket } from "../../utils/socket";
import { useMainContext } from "../../context/mainContext";
import { toast } from "react-toastify";
import { any } from "@amcharts/amcharts5/.internal/core/util/Array";

export interface ActiveTradeListItemProps {
    account_type: string;
    amount_bet: number;
    assetCode: string;
    asset_id: number;
    begin_rate: number;
    choice: string;
    client_firstname: string;
    client_id: number;
    client_lastname: string;
    currency: string;
    dashboard: number;
    date: string
    dateTimestamp: number;
    date_close: string;
    duration: number;
    id: number;
    info?: string;
    label: string;
    leverage: number;
    maxSecondsEnd: number;
    multiplication: number;
    origin: string;
    payout_lose: number;
    payout_win: number;
    pnl_ac: number;
    profitLossPts?: any;
    risk_applied: number;
    status: string;
    stopLossRate?: any;
    stopLoss_rate?: any;
    swap: number;
    takeProfit_rate?: any;
    volume: number;
}

interface DurationItemProps {
    id: number;
    label: string;
    duration: string;
    duration_minimun: string;
    is_default: string;
}

interface ChartDataItem {
    date: number;
    value: number;
    bullet?: boolean;
}

interface PayoutProps {
    id: string;
    lose: string;
    win: string;
}

interface AssetListItemProps {
    assetId: string;
    assetCode: string;
    label: string;
    ico_1: string;
    ico_2: string;
    is_default: string;
    position: string;
    payouts: PayoutProps[];
}

interface DataItem {
    h: string;
    t: string;
    v: string;
}

const API_URL = process.env.REACT_APP_API_URL;


const FastAndFuriousChart: React.FunctionComponent<{ assetlist: any, handleActiveTradeTableData: (data: ActiveTradeListItemProps[]) => void }> = (props) => {
    const [activeTradeList, setActiveTradeList] = useState<ActiveTradeListItemProps[] | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const { token, userInfo } = useMainContext();
    const [durationList, setDurationList] = useState<DurationItemProps[] | null>(null);
    const [endTradeTime, setEndendTradeTime] = useState<any | null>(null);
    const [selectDuration, setSelectDuration] = useState<DurationItemProps | null>(null);
    const [chartData, setChartData] = useState<any | null>(null);
    const [selectAsset, setSelectAsset] = useState<AssetListItemProps | null>(null);
    const [assetLastValue, setAssetLastValue] = useState<any | null>(null);
    const [assetsLiveRate, setAssetsLiveRate] = useState<{ [key: string]: string }>({});
    const [amount, setAmount] = useState<number>(0);

    // const fetchData = useCallback(async () => {
    //     try {
    //         const headers = { contentType: 'application/json', Authorization: token, token: token };
    //         const [durationResponse, endTradeResponse] = await Promise.all([
    //             axios.get(`${API_URL}/trade/durations/list/`, { headers }),
    //             axios.get(`${API_URL}/trade/get-datetime-endtrade/`, { headers })
    //         ]);
    //         setDurationList(durationResponse.data.records);
    //         console.log("lkjsdlfkjsdlfkjsdflksdjflsdf", endTradeResponse)
    //         setEndendTradeTime(endTradeResponse);
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }
    // }, []);
    const fetchData = async () => {
        try {
            const headers = { contentType: 'application/json', Authorization: token, token: token };
            await axios.get(`${process.env.REACT_APP_API_URL}/trade/durations/list/`, { headers })
                .then(function (response) {
                    console.log("-----------------", response)
                })
                .catch(function (error) {
                    console.log(error);
                });
            await axios.get(`${process.env.REACT_APP_API_URL}/trade/get-datetime-endtrade/`, { headers })
                .then(function (response) {
                    setEndendTradeTime(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {

        }
    };

    const renderTime = ({ remainingTime }: { remainingTime: number }) => {
        if (remainingTime <= parseInt(selectDuration?.duration_minimun as string)) {
            setDisabled(true);
            return (
                <div className="timer text-center duration-time">
                    <div className="value">{Math.floor(remainingTime)}</div>
                    <div className="text">Sec</div>
                </div>
            )
        }

        setDisabled(false);
        return (
            <div className="timer text-center">
                <div className="value">{Math.floor(remainingTime)}</div>
                <div className="text">Sec</div>
            </div>
        );
    };

    const selectAssetList = (assetId: string, assetCode: string, label: string, ico_1: string, ico_2: string, is_default: string, payouts: PayoutProps[], position: string) => {
        setSelectAsset({ assetId, assetCode, label, ico_1, ico_2, is_default, payouts, position });
        socket.emit("subscribe-asset", { assetId, assetCode });
        socket.on("asset-history", (response: any) => {
            const chartData: any =

                response.datas.map((row: DataItem): ChartDataItem => ({
                    date: parseInt(row.t) * 1000, // Convert Unix timestamp to milliseconds
                    value: parseFloat(row.v) // Parse value to float
                }));

            const _data: any = chartData.slice(-150);
            setChartData(_data as any);
        });

        return () => {
            socket.off("asset-history", (response: any) => {
                console.log("off-asset-history", response);
            });
        };
    };

    const handleImageError = (event: any) => {
        event.target.src = 'https://api.dev.bitnswap.com/design/img/generic.svg';
    };

    const startTrading = (type: string) => {
        if (!token) {
            toast.error("You can trade after logging into this platform");
            return
        }
        const params = {
            asset_id: parseInt(selectAsset?.assetId as string),
            client_id: parseInt(userInfo.id as string),
            amount: parseFloat(amount as any),
            currentRate: parseFloat(assetsLiveRate[selectAsset?.assetCode as string] as any),
            choice: type,
            duration: parseInt(selectDuration?.duration as any),
            payout_id: parseInt(selectAsset?.payouts[0].id as any),
            currency: "USD"
        };

        socket.emit("take-trade-binary", params);
    };

    useEffect(() => {
        fetchData();
        selectAssetList("201", "btcusd", "BTC/USD", "BTC.svg", "USD.svg", "1", [{ id: '2105', win: '75', lose: '5' }], "1");
        setSelectDuration({ id: 1, label: "1min", duration: "60", duration_minimun: "30", is_default: "1" });
    }, []);

    useEffect(() => {
        let root = am5.Root.new("chartdiv");
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let value = 100;

        function generateChartData(): ChartDataItem[] {
            let chartData: ChartDataItem[] = [];
            let firstDate = new Date();
            firstDate.setDate(firstDate.getDate() - 1000);
            firstDate.setHours(0, 0, 0, 0);

            for (let i = 0; i < 50; i++) {
                let newDate = new Date(firstDate);
                newDate.setSeconds(newDate.getSeconds() + i);

                value += (Math.random() < 0.5 ? 1 : -1) * Math.random() * 10;

                chartData.push({
                    date: newDate.getTime(),
                    value: value,
                });
            }
            // chartData[chartData.length - 2].bullet = false;
            // chartData[chartData.length - 1].bullet = true; // Set bullet property for the last data item
            return chartData;
        }

        let data = generateChartData();


        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            focusable: true,
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: false,
        }));

        let easing = am5.ease.linear;

        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.5,
            groupData: false,
            extraMax: 0.1,
            extraMin: -0.3,
            baseInterval: {
                timeUnit: "second",
                count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
                cellStartLocation: 0.2,
                cellEndLocation: 0.8
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                extraTooltipPrecision: 1,
                renderer: am5xy.AxisRendererY.new(root, {
                    minGridDistance: 30
                })
            })
        );

        let series = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
            name: "Series 1",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{valueY}"
            })
        }));



        series.strokes.template.setAll({
            strokeWidth: 2,
            stroke: am5.color(0xffffff),
            shadowColor: am5.color(0x000000),
            shadowBlur: 10,
            shadowOffsetX: 10,
            shadowOffsetY: 10,
            shadowOpacity: 0.7
        });

        series.fills.template.setAll({
            visible: true,
            fillOpacity: 0.2
        });

        series.data.setAll(chartData === null ? data : chartData as any);

        xAxis.get("renderer").labels.template.setAll({
            fill: root.interfaceColors.get("alternativeText")
        });
        yAxis.get("renderer").labels.template.setAll({
            fill: root.interfaceColors.get("alternativeText")
        });
        root.interfaceColors.set("grid", am5.color(0xffffff));


        series.bullets.push(function (root, series, dataItem: any) {
            if (dataItem.dataContext.bullet) {
                let container = am5.Container.new(root, {});
                let circle0 = container.children.push(am5.Circle.new(root, {
                    radius: 5,
                    fill: am5.color(0xffffff)
                }));
                let circle1 = container.children.push(am5.Circle.new(root, {
                    radius: 5,
                    fill: am5.color(0xffffff)
                }));

                circle1.animate({
                    key: "radius",
                    to: 20,
                    duration: 1000,
                    easing: am5.ease.out(am5.ease.cubic),
                    loops: Infinity
                });
                circle1.animate({
                    key: "opacity",
                    to: 0,
                    from: 1,
                    duration: 500,
                    easing: am5.ease.out(am5.ease.cubic),
                    loops: Infinity
                });

                return am5.Bullet.new(root, {
                    locationX: undefined,
                    sprite: container
                })
            }
        })

        let rangeDataItem: any = yAxis.makeDataItem({
            // value:67800,
            // endValue:67800
        });
        yAxis.createAxisRange(rangeDataItem);

        rangeDataItem.get("grid").setAll({
            stroke: am5.color(0xffffff),
            strokeOpacity: 0.8,
            strokeDasharray: [5]
        });

        rangeDataItem.get("axisFill").setAll({
            fill: am5.color(0x58FF00),
            fillOpacity: 0.1,
            visible: true
        });

        if (endTradeTime) {
            // console.log("start-time:", endTradeTime?.datetimeEndTrade.timestamp["60"] - 30, "-------   end-Time: ", endTradeTime?.datetimeEndTrade.timestamp["60"] )
            let xRangeDataItem: any = xAxis.makeDataItem({
                // value: endTradeTime?.datetimeEndTrade.timestamp["60"] - 30,
                // endValue: endTradeTime?.datetimeEndTrade.timestamp["60"]
                // value:1710512530,

                // endValue:0
            });

            xAxis.createAxisRange(xRangeDataItem);

            // console.log(xRangeDataItem)



            xRangeDataItem.get("grid").setAll({
                stroke: am5.color(0x00ff33),
                strokeOpacity: 0.7,
                strokeDasharray: [2]
            });

            xRangeDataItem.get("axisFill").setAll({
                fill: am5.color(0xffffff),
                fillOpacity: 0.1,
                visible: true
            });

        }

        ////////////////////////////////////////////////
        let container = am5.Container.new(root, {
            centerY: am5.p50,
            draggable: false,
        })

        // restrict from being dragged vertically
        container.adapters.add("x", function () {
            return Math.max(0, Math.min(chart.plotContainer.width(), chart.plotContainer.width() - 200));
        });

        // restrict from being dragged outside of plot
        container.adapters.add("y", function () {
            let lastDataItem: any = series.dataItems[series.dataItems.length - 1];
            let lastValue = lastDataItem.get("valueY");
            return 300;
        });

        yAxis.topGridContainer.children.push(container);

        rangeDataItem.set("bullet", am5xy.AxisBullet.new(root, {
            sprite: container
        }));


        let label = container.children.push(am5.Label.new(root, {}))

        function updateLabel(value?: any) {
            let y = container.y();
            let position = yAxis.toAxisPosition(y / chart.plotContainer.height());

            if (value == null) {
                value = yAxis.positionToValue(position);
            }
            label.set("html", "<div class=\"chart-badge\"><div class=\"image\"><img src=\"https://api.dev.bitnswap.com/design/img/" + (selectAsset?.ico_1 === "" ? "generic.svg" : "/assets/" + selectAsset?.ico_1) + "\" /></div><div class=\"content\"><div class=\"name\"><strong>" + selectAsset?.label + "</strong></div><div class=\"value\">" + root.numberFormatter.format(value, "###,###.00") + "</div></div></div>");

            rangeDataItem.set("value", value);
        }

        series.events.on("datavalidated", () => {
            let lastDataItem: any = series.dataItems[series.dataItems.length - 1];
            let lastValue = lastDataItem.get("valueY");
            rangeDataItem.set("value", lastValue);
            updateLabel(lastValue);
        })

        ///////////////////////////////////////////////


        let range: any = series.createAxisRange(rangeDataItem);

        range.strokes.template.setAll({
            stroke: am5.color(0x58FF00),
            strokeWidth: 3
        });


        range.fills.template.setAll({
            fill: am5.color(0x58FF00),
            fillOpacity: 0.5,
            visible: true
        });

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis
        }));
        cursor.lineY.set("visible", true);
        // console.log("chart--------", chartData)

        addData();
        gridHistory();

        function addData() {
            socket.on("asset-history-last-value", (response: any) => {
                console.log("asset-history-last-value", response)
                let lastDataItem: any = series.dataItems[series.dataItems.length - 1];
                let lastValue = lastDataItem.get("valueY");
                let lastDate = new Date(lastDataItem.get("valueX") as any);
                let time = am5.time.add(new Date(lastDate), "second", 1).getTime();

                series.data.pop(); // Remove the last element
                series.data.shift(); // Remove the first element

                series.data.push(
                    {
                        date: time,
                        value: lastValue,
                        bullet: false // Set bullet property to false for the new data item
                    }
                );
                series.data.push(
                    {
                        date: parseInt(response.graph.t) * 1000,
                        value: response.graph.v,
                        bullet: true // Set bullet property to true for the new data item
                    }
                );


                let newDataItem: any = series.dataItems[series.dataItems.length - 1];
                newDataItem.animate({
                    key: "valueYWorking",
                    to: response.graph.v,
                    from: lastValue,
                    duration: 300,
                    easing: easing
                });

                let animation = newDataItem.animate({
                    key: "locationX",
                    to: 0.5,
                    from: -0.5,
                    duration: 500
                });
                if (animation) {
                    let tooltip = xAxis.get("tooltip");
                    if (tooltip && !tooltip.isHidden()) {
                        animation.events.on("stopped", function () {
                            xAxis.updateTooltip();
                        })
                    }
                }


            });

            return () => {
                socket.off("asset-history-last-value", (response: any) => {
                    console.log("asset-history-last-value", response);
                });
            };
        }

        function gridHistory() {
            socket.on("open-positions-change", (data: any) => {
                console.log("open-positions-change", data)
            });
        }



        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [chartData, endTradeTime, props.assetlist]);

    return (
        <div className="FastAndFuriousChart">
            <Row>
                <Col xs={4} lg={4}>
                    <Row>
                        <Col sm={6} className="d-none d-lg-block">
                            <div className="fast-chart-info mb-3">
                                <label>YOUR INVESTMENT</label>
                                <div className="fast-chart-info-value">{amount}</div>
                            </div>
                            <div className="fast-chart-info mb-3">
                                <label>POTENTIAL RETURN</label>
                                <div className="fast-chart-info-value">190.0</div>
                            </div>

                        </Col>
                        <Col sm={6}>
                            <div className="fast-chart-info mb-3">
                                <label>DOWN POOL PAYOUT</label>
                                <div className="fast-chart-info-down-payout">190%</div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={4} lg={4} className="position-relative">
                    <div className="mx-auto mb-2">
                        <Dropdown data-bs-theme="dark" className="text-center fast-chart-coin-dropdown d-flex align-items-center justify-content-center">
                            {selectAsset === null ? (
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="d-flex align-items-center p-0 bg-transparent border border-white rounded-5 pt-1 ps-3 pe-2 fs-4">Select Asset</Dropdown.Toggle>
                            ) : (
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="d-flex align-items-center p-0 bg-transparent border border-white rounded-5 py-2 px-2">
                                    <div className="me-2">
                                        <Image src={`https://api.dev.bitnswap.com/design/img/${selectAsset?.ico_1 === "" ? "generic.svg" : ("/assets/" + selectAsset?.ico_1)}`} width={30} height={30} alt="coin" className="me-2" onError={handleImageError} />
                                        <Image src={`https://api.dev.bitnswap.com/design/img/${selectAsset?.ico_2 === "" ? "generic.svg" : ("/assets/" + selectAsset?.ico_2)}`} width={30} height={30} alt="coin" className="me-2" onError={handleImageError} />
                                    </div>
                                    <div className="me-2 text-start">
                                        <div className="token_name fw-bold">{selectAsset?.label}</div>
                                    </div>
                                </Dropdown.Toggle>
                            )}

                            <Dropdown.Menu className="bg-primary-1">
                                {Object.entries(props.assetlist).map((item: any, index: any) => {
                                    return (
                                        <Dropdown.Item className="d-flex align-items-center" key={index} onClick={() => selectAssetList(item[1].assetId, item[1].assetCode, item[1].label, item[1].ico1, item[1].ico2, item[1].isDefault, item[1].payouts, item[1].position,)}>
                                            <div className="me-2">
                                                <Image src={`https://api.dev.bitnswap.com/design/img/${item[1].ico1 === "" ? "generic.svg" : ("/assets/" + item[1].ico1)}`} width={30} height={30} alt="coin" className="me-2" onError={handleImageError} />
                                                <Image src={`https://api.dev.bitnswap.com/design/img/${item[1].ico2 === "" ? "generic.svg" : ("/assets/" + item[1].ico2)}`} width={30} height={30} alt="coin" className="me-2" onError={handleImageError} />
                                            </div>
                                            <div className="text-start">
                                                <div className="token_name">{item[1].label}</div>
                                            </div>
                                        </Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="position-absolute fast-chart-count-down">
                        <div className="bg-primary-1 rounded-circle">
                            <CountdownCircleTimer isPlaying={true} duration={parseInt(selectDuration?.duration as string)} colors={["#F24F09", "#F24F09", "#F24F09", "#F24F09"] as any} onComplete={() => ({ shouldRepeat: true, delay: parseInt(selectDuration?.duration_minimun as string) })}>
                                {renderTime}
                            </CountdownCircleTimer>
                        </div>
                    </div>
                </Col>
                <Col xs={4} lg={4}>
                    <Row>
                        <Col sm={6}>
                            <div className="fast-chart-info mb-3 text-end">
                                <label>UP POOL PAYOUT</label>
                                <div className="fast-chart-info-up-payout">190%</div>
                            </div>
                        </Col>
                        <Col sm={6} className="d-none d-lg-block">
                            <div className="fast-chart-info mb-3 text-end">
                                <label>YOUR INVESTMENT</label>
                                <div className="fast-chart-info-value">{amount}</div>
                            </div>
                            <div className="fast-chart-info mb-3 text-end">
                                <label>POTENTIAL RETURN</label>
                                <div className="fast-chart-info-value">190.0</div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12} className="mb-3">
                    <div id="chartdiv" style={{ width: "100%", height: "350px" }} className="fast-chart border border-white rounded-3 p-0"></div>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col xs={6} lg={2} className="d-none d-lg-block"><Button className="w-100 fast-chart-down-btn text-white border-0 py-3 rounded-5" disabled={disabled} onClick={() => startTrading("PUT")}>DOWN</Button></Col>
                <Col xs={12} lg={8} className="mb-2">
                    <Row>
                        <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 5} disabled={disabled} onClick={() => setAmount(5)}>5</Button></Col>
                        <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 15} disabled={disabled} onClick={() => setAmount(15)}>15</Button></Col>
                        <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 25} disabled={disabled} onClick={() => setAmount(25)}>25</Button></Col>
                        <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 50} disabled={disabled} onClick={() => setAmount(50)}>50</Button></Col>
                        <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 75} disabled={disabled} onClick={() => setAmount(75)}>75</Button></Col>
                        <Col xs={2}><Button className="w-100 my-2 bg-transparent rounded-5 fast-chart-sm-button" active={amount === 100} disabled={disabled} onClick={() => setAmount(100)}>100</Button></Col>
                    </Row>
                </Col>
                <Col xs={6} lg={2} className="d-lg-none d-block"><Button className="w-100 fast-chart-down-btn rounded-5 text-white border-0 py-3" disabled={disabled} onClick={() => startTrading("PUT")}>DOWN</Button></Col>
                <Col xs={6} lg={2}><Button className="w-100 fast-chart-up-btn text-white py-3 rounded-5" disabled={disabled} onClick={() => startTrading("CALL")}>UP</Button></Col>
            </Row>
        </div>
    )
}

export default FastAndFuriousChart;