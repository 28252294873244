import { AvatarImage1, AvatarImage10, AvatarImage2, AvatarImage3, AvatarImage4, AvatarImage5, AvatarImage6, AvatarImage7, AvatarImage8, AvatarImage9, BTCIcon, BnbIcon, BspIcon, DaiIcon, DegenSlotNFT1, DegenSlotNFT10, DegenSlotNFT2, DegenSlotNFT3, DegenSlotNFT4, DegenSlotNFT5, DegenSlotNFT6, DegenSlotNFT7, DegenSlotNFT8, DegenSlotNFT9, EURIcon, EthIcon, FOUNDIcon, GameCardImage1, GameCardImage2, GameCardImage3, GameCardImage4, GameCardImage5, GameCardImage6, GameCardImage7, GamefiBg1, GamefiBg2, GamefiBg3, GamefiBg4, GamefiBg5, GamefiBg6, MaticTIcon, NUsdcIcon, USDIcon, UsdtIcon, WENIcon } from "../assets";

export const MonthTopPlayerData = [
    {
        player: {
            avatar: AvatarImage1,
            name: "Player"
        },
        amount: {
            value: 2.5,
            coin: BspIcon
        }
    },
    {
        player: {
            avatar: AvatarImage2,
            name: "Player"
        },
        amount: {
            value: 2.5,
            coin: BspIcon
        }
    },
    {
        player: {
            avatar: AvatarImage3,
            name: "Player"
        },
        amount: {
            value: 2.5,
            coin: BspIcon
        }
    },
    {
        player: {
            avatar: AvatarImage4,
            name: "Player"
        },
        amount: {
            value: 2.5,
            coin: BspIcon
        }
    },
    {
        player: {
            avatar: AvatarImage5,
            name: "Player"
        },
        amount: {
            value: 2.5,
            coin: BspIcon
        }
    },
    {
        player: {
            avatar: AvatarImage6,
            name: "Player"
        },
        amount: {
            value: 2.5,
            coin: BspIcon
        }
    },
    {
        player: {
            avatar: AvatarImage7,
            name: "Player"
        },
        amount: {
            value: 2.5,
            coin: BspIcon
        }
    },
    {
        player: {
            avatar: AvatarImage8,
            name: "Player"
        },
        amount: {
            value: 2.5,
            coin: BspIcon
        }
    },
    {
        player: {
            avatar: AvatarImage9,
            name: "Player"
        },
        amount: {
            value: 2.5,
            coin: BspIcon
        }
    },
    {
        player: {
            avatar: AvatarImage10,
            name: "Player"
        },
        amount: {
            value: 2.5,
            coin: BspIcon
        }
    },
];

export const CrashGameCardData = [
    { bg: GamefiBg1, title: "Dice", image: GameCardImage1, path: "/original_games/dice" },
    { bg: GamefiBg2, title: "Mine", image: GameCardImage2, path: "/original_games/mine" },
    { bg: GamefiBg3, title: "Snake", image: GameCardImage3, path: "/original_games/snake" },
    { bg: GamefiBg4, title: "Tetris", image: GameCardImage4, path: "/original_games/tetris" },
    { bg: GamefiBg5, title: "Limbo", image: GameCardImage5, path: "/original_games/limbo" },
    { bg: GamefiBg6, title: "Aviator", image: GameCardImage6, path: "/original_games/aviator" },
    { bg: GamefiBg3, title: "Degen", image: GameCardImage7, path: "/original_games/degen" },
];

export const LatestBetData = [
    {
        player: {
            avatar: AvatarImage1,
            name: "Player"
        },
        game: "Aviator",
        time: "11:31 AM",
        amount: 2046.427,
        multiplier: 10,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage2,
            name: "Player"
        },
        game: "Aviator",
        time: "11:31 AM",
        amount: 2046.427,
        multiplier: 10,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage3,
            name: "Player"
        },
        game: "Limbo",
        time: "11:31 AM",
        amount: 2046.427,
        multiplier: 10,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage4,
            name: "Player"
        },
        game: "Dice",
        time: "11:31 AM",
        amount: 2046.427,
        multiplier: 10,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage5,
            name: "Player"
        },
        game: "Limbo",
        time: "11:31 AM",
        amount: 2046.427,
        multiplier: 10,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage6,
            name: "Player"
        },
        game: "Tetris",
        time: "11:31 AM",
        amount: 2046.427,
        multiplier: 10,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage7,
            name: "Player"
        },
        game: "Snake",
        time: "11:31 AM",
        amount: 2046.427,
        multiplier: 10,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage8,
            name: "Player"
        },
        game: "Mine",
        time: "11:31 AM",
        amount: 2046.427,
        multiplier: 10,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage9,
            name: "Player"
        },
        game: "Dice",
        time: "11:31 AM",
        amount: 2046.427,
        multiplier: 10,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage10,
            name: "Player"
        },
        game: "Aviator",
        time: "11:31 AM",
        amount: 2046.427,
        multiplier: 10,
        roi: 24.05
    },
];

export const DepositCurrencyList = [
    { value: "USD", label: "USD", icon: USDIcon },
    { value: "EUR", label: "EUR", icon: EURIcon },
    { value: "WEN", label: "WEN", icon: WENIcon },
];

export const ActiveBetsData = [
    {
        player: {
            avatar: AvatarImage1,
            name: "Player"
        },
        bet: {
            coin_icon: BspIcon,
            status: "UP"
        },
        entry_price: 2048.890,
        wager: 20,
        bust_price: 2046.427,
        multiplier: 10,
        exit_price: 2046.427,
        pl: 4.81,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage2,
            name: "Player"
        },
        bet: {
            coin_icon: BspIcon,
            status: "UP"
        },
        entry_price: 2048.890,
        wager: 20,
        bust_price: 2046.427,
        multiplier: 10,
        exit_price: 2046.427,
        pl: 4.81,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage3,
            name: "Player"
        },
        bet: {
            coin_icon: BspIcon,
            status: "UP"
        },
        entry_price: 2048.890,
        wager: 20,
        bust_price: 2046.427,
        multiplier: 10,
        exit_price: 2046.427,
        pl: 4.81,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage4,
            name: "Player"
        },
        bet: {
            coin_icon: BspIcon,
            status: "UP"
        },
        entry_price: 2048.890,
        wager: 20,
        bust_price: 2046.427,
        multiplier: 10,
        exit_price: 2046.427,
        pl: 4.81,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage5,
            name: "Player"
        },
        bet: {
            coin_icon: BspIcon,
            status: "UP"
        },
        entry_price: 2048.890,
        wager: 20,
        bust_price: 2046.427,
        multiplier: 10,
        exit_price: 2046.427,
        pl: 4.81,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage6,
            name: "Player"
        },
        bet: {
            coin_icon: BspIcon,
            status: "UP"
        },
        entry_price: 2048.890,
        wager: 20,
        bust_price: 2046.427,
        multiplier: 10,
        exit_price: 2046.427,
        pl: 4.81,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage7,
            name: "Player"
        },
        bet: {
            coin_icon: BspIcon,
            status: "UP"
        },
        entry_price: 2048.890,
        wager: 20,
        bust_price: 2046.427,
        multiplier: 10,
        exit_price: 2046.427,
        pl: 4.81,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage8,
            name: "Player"
        },
        bet: {
            coin_icon: BspIcon,
            status: "UP"
        },
        entry_price: 2048.890,
        wager: 20,
        bust_price: 2046.427,
        multiplier: 10,
        exit_price: 2046.427,
        pl: 4.81,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage9,
            name: "Player"
        },
        bet: {
            coin_icon: BspIcon,
            status: "UP"
        },
        entry_price: 2048.890,
        wager: 20,
        bust_price: 2046.427,
        multiplier: 10,
        exit_price: 2046.427,
        pl: 4.81,
        roi: 24.05
    },
    {
        player: {
            avatar: AvatarImage10,
            name: "Player"
        },
        bet: {
            coin_icon: BspIcon,
            status: "UP"
        },
        entry_price: 2048.890,
        wager: 20,
        bust_price: 2046.427,
        multiplier: 10,
        exit_price: 2046.427,
        pl: 4.81,
        roi: 24.05
    },
];

export const TradingData = {
    series: [{
        data: [{
            x: new Date(1538778600000),
            y: [6629.81, 6650.5, 6623.04, 6633.33]
        },
        {
            x: new Date(1538780400000),
            y: [6632.01, 6643.59, 6620, 6630.11]
        },
        {
            x: new Date(1538782200000),
            y: [6630.71, 6648.95, 6623.34, 6635.65]
        },
        {
            x: new Date(1538784000000),
            y: [6635.65, 6651, 6629.67, 6638.24]
        },
        {
            x: new Date(1538785800000),
            y: [6638.24, 6640, 6620, 6624.47]
        },
        {
            x: new Date(1538787600000),
            y: [6624.53, 6636.03, 6621.68, 6624.31]
        },
        {
            x: new Date(1538789400000),
            y: [6624.61, 6632.2, 6617, 6626.02]
        },
        {
            x: new Date(1538791200000),
            y: [6627, 6627.62, 6584.22, 6603.02]
        },
        {
            x: new Date(1538793000000),
            y: [6605, 6608.03, 6598.95, 6604.01]
        },
        {
            x: new Date(1538794800000),
            y: [6604.5, 6614.4, 6602.26, 6608.02]
        },
        {
            x: new Date(1538796600000),
            y: [6608.02, 6610.68, 6601.99, 6608.91]
        },
        {
            x: new Date(1538798400000),
            y: [6608.91, 6618.99, 6608.01, 6612]
        },
        {
            x: new Date(1538800200000),
            y: [6612, 6615.13, 6605.09, 6612]
        },
        {
            x: new Date(1538802000000),
            y: [6612, 6624.12, 6608.43, 6622.95]
        },
        {
            x: new Date(1538803800000),
            y: [6623.91, 6623.91, 6615, 6615.67]
        },
        {
            x: new Date(1538805600000),
            y: [6618.69, 6618.74, 6610, 6610.4]
        },
        {
            x: new Date(1538807400000),
            y: [6611, 6622.78, 6610.4, 6614.9]
        },
        {
            x: new Date(1538809200000),
            y: [6614.9, 6626.2, 6613.33, 6623.45]
        },
        {
            x: new Date(1538811000000),
            y: [6623.48, 6627, 6618.38, 6620.35]
        },
        {
            x: new Date(1538812800000),
            y: [6619.43, 6620.35, 6610.05, 6615.53]
        },
        {
            x: new Date(1538814600000),
            y: [6615.53, 6617.93, 6610, 6615.19]
        },
        {
            x: new Date(1538816400000),
            y: [6615.19, 6621.6, 6608.2, 6620]
        },
        {
            x: new Date(1538818200000),
            y: [6619.54, 6625.17, 6614.15, 6620]
        },
        {
            x: new Date(1538820000000),
            y: [6620.33, 6634.15, 6617.24, 6624.61]
        },
        {
            x: new Date(1538821800000),
            y: [6625.95, 6626, 6611.66, 6617.58]
        },
        {
            x: new Date(1538823600000),
            y: [6619, 6625.97, 6595.27, 6598.86]
        },
        {
            x: new Date(1538825400000),
            y: [6598.86, 6598.88, 6570, 6587.16]
        },
        {
            x: new Date(1538827200000),
            y: [6588.86, 6600, 6580, 6593.4]
        },
        {
            x: new Date(1538829000000),
            y: [6593.99, 6598.89, 6585, 6587.81]
        },
        {
            x: new Date(1538830800000),
            y: [6587.81, 6592.73, 6567.14, 6578]
        },
        {
            x: new Date(1538832600000),
            y: [6578.35, 6581.72, 6567.39, 6579]
        },
        {
            x: new Date(1538834400000),
            y: [6579.38, 6580.92, 6566.77, 6575.96]
        },
        {
            x: new Date(1538836200000),
            y: [6575.96, 6589, 6571.77, 6588.92]
        },
        {
            x: new Date(1538838000000),
            y: [6588.92, 6594, 6577.55, 6589.22]
        },
        {
            x: new Date(1538839800000),
            y: [6589.3, 6598.89, 6589.1, 6596.08]
        },
        {
            x: new Date(1538841600000),
            y: [6597.5, 6600, 6588.39, 6596.25]
        },
        {
            x: new Date(1538843400000),
            y: [6598.03, 6600, 6588.73, 6595.97]
        },
        {
            x: new Date(1538845200000),
            y: [6595.97, 6602.01, 6588.17, 6602]
        },
        {
            x: new Date(1538847000000),
            y: [6602, 6607, 6596.51, 6599.95]
        },
        {
            x: new Date(1538848800000),
            y: [6600.63, 6601.21, 6590.39, 6591.02]
        },
        {
            x: new Date(1538850600000),
            y: [6591.02, 6603.08, 6591, 6591]
        },
        {
            x: new Date(1538852400000),
            y: [6591, 6601.32, 6585, 6592]
        },
        {
            x: new Date(1538854200000),
            y: [6593.13, 6596.01, 6590, 6593.34]
        },
        {
            x: new Date(1538856000000),
            y: [6593.34, 6604.76, 6582.63, 6593.86]
        },
        {
            x: new Date(1538857800000),
            y: [6593.86, 6604.28, 6586.57, 6600.01]
        },
        {
            x: new Date(1538859600000),
            y: [6601.81, 6603.21, 6592.78, 6596.25]
        },
        {
            x: new Date(1538861400000),
            y: [6596.25, 6604.2, 6590, 6602.99]
        },
        {
            x: new Date(1538863200000),
            y: [6602.99, 6606, 6584.99, 6587.81]
        },
        {
            x: new Date(1538865000000),
            y: [6587.81, 6595, 6583.27, 6591.96]
        },
        {
            x: new Date(1538866800000),
            y: [6591.97, 6596.07, 6585, 6588.39]
        },
        {
            x: new Date(1538868600000),
            y: [6587.6, 6598.21, 6587.6, 6594.27]
        },
        {
            x: new Date(1538870400000),
            y: [6596.44, 6601, 6590, 6596.55]
        },
        {
            x: new Date(1538872200000),
            y: [6598.91, 6605, 6596.61, 6600.02]
        },
        {
            x: new Date(1538874000000),
            y: [6600.55, 6605, 6589.14, 6593.01]
        },
        {
            x: new Date(1538875800000),
            y: [6593.15, 6605, 6592, 6603.06]
        },
        {
            x: new Date(1538877600000),
            y: [6603.07, 6604.5, 6599.09, 6603.89]
        },
        {
            x: new Date(1538879400000),
            y: [6604.44, 6604.44, 6600, 6603.5]
        },
        {
            x: new Date(1538881200000),
            y: [6603.5, 6603.99, 6597.5, 6603.86]
        },
        {
            x: new Date(1538883000000),
            y: [6603.85, 6605, 6600, 6604.07]
        },
        {
            x: new Date(1538884800000),
            y: [6604.98, 6606, 6604.07, 6606]
        },
        ]
    }],
    options: {
        chart: {
            type: 'candlestick',
            height: 350
        },
        title: {
            text: 'CandleStick Chart',
            align: 'left'
        },
        xaxis: {
            type: 'datetime'
        },
        yaxis: {
            tooltip: {
                enabled: true
            }
        }
    },


};

export const CountryList = [
    { label: 'Afghanistan', value: 'AF' },
    { label: 'Åland Islands', value: 'AX' },
    { label: 'Albania', value: 'AL' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'AndorrA', value: 'AD' },
    { label: 'Angola', value: 'AO' },
    { label: 'Anguilla', value: 'AI' },
    { label: 'Antarctica', value: 'AQ' },
    { label: 'Antigua and Barbuda', value: 'AG' },
    { label: 'Argentina', value: 'AR' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Aruba', value: 'AW' },
    { label: 'Australia', value: 'AU' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaijan', value: 'AZ' },
    { label: 'Bahamas', value: 'BS' },
    { label: 'Bahrain', value: 'BH' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Barbados', value: 'BB' },
    { label: 'Belarus', value: 'BY' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Belize', value: 'BZ' },
    { label: 'Benin', value: 'BJ' },
    { label: 'Bermuda', value: 'BM' },
    { label: 'Bhutan', value: 'BT' },
    { label: 'Bolivia', value: 'BO' },
    { label: 'Bosnia and Herzegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bouvet Island', value: 'BV' },
    { label: 'Brazil', value: 'BR' },
    { label: 'British Indian Ocean Territory', value: 'IO' },
    { label: 'Brunei Darussalam', value: 'BN' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Burkina Faso', value: 'BF' },
    { label: 'Burundi', value: 'BI' },
    { label: 'Cambodia', value: 'KH' },
    { label: 'Cameroon', value: 'CM' },
    { label: 'Canada', value: 'CA' },
    { label: 'Cape Verde', value: 'CV' },
    { label: 'Cayman Islands', value: 'KY' },
    { label: 'Central African Republic', value: 'CF' },
    { label: 'Chad', value: 'TD' },
    { label: 'Chile', value: 'CL' },
    { label: 'China', value: 'CN' },
    { label: 'Christmas Island', value: 'CX' },
    { label: 'Cocos (Keeling) Islands', value: 'CC' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Comoros', value: 'KM' },
    { label: 'Congo', value: 'CG' },
    { label: 'Congo, The Democratic Republic of the', value: 'CD' },
    { label: 'Cook Islands', value: 'CK' },
    { label: 'Costa Rica', value: 'CR' },
    { label: 'Cote D\'Ivoire', value: 'CI' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cuba', value: 'CU' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czech Republic', value: 'CZ' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Dominica', value: 'DM' },
    { label: 'Dominican Republic', value: 'DO' },
    { label: 'Ecuador', value: 'EC' },
    { label: 'Egypt', value: 'EG' },
    { label: 'El Salvador', value: 'SV' },
    { label: 'Equatorial Guinea', value: 'GQ' },
    { label: 'Eritrea', value: 'ER' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Ethiopia', value: 'ET' },
    { label: 'Falkland Islands (Malvinas)', value: 'FK' },
    { label: 'Faroe Islands', value: 'FO' },
    { label: 'Fiji', value: 'FJ' },
    { label: 'Finland', value: 'FI' },
    { label: 'France', value: 'FR' },
    { label: 'French Guiana', value: 'GF' },
    { label: 'French Polynesia', value: 'PF' },
    { label: 'French Southern Territories', value: 'TF' },
    { label: 'Gabon', value: 'GA' },
    { label: 'Gambia', value: 'GM' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Germany', value: 'DE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Gibraltar', value: 'GI' },
    { label: 'Greece', value: 'GR' },
    { label: 'Greenland', value: 'GL' },
    { label: 'Grenada', value: 'GD' },
    { label: 'Guadeloupe', value: 'GP' },
    { label: 'Guam', value: 'GU' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Guernsey', value: 'GG' },
    { label: 'Guinea', value: 'GN' },
    { label: 'Guinea-Bissau', value: 'GW' },
    { label: 'Guyana', value: 'GY' },
    { label: 'Haiti', value: 'HT' },
    { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
    { label: 'Holy See (Vatican City State)', value: 'VA' },
    { label: 'Honduras', value: 'HN' },
    { label: 'Hong Kong', value: 'HK' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'India', value: 'IN' },
    { label: 'Indonesia', value: 'ID' },
    { label: 'Iran, Islamic Republic Of', value: 'IR' },
    { label: 'Iraq', value: 'IQ' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Isle of Man', value: 'IM' },
    { label: 'Israel', value: 'IL' },
    { label: 'Italy', value: 'IT' },
    { label: 'Jamaica', value: 'JM' },
    { label: 'Japan', value: 'JP' },
    { label: 'Jersey', value: 'JE' },
    { label: 'Jordan', value: 'JO' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Kenya', value: 'KE' },
    { label: 'Kiribati', value: 'KI' },
    { label: 'Korea, Democratic People\'S Republic of', value: 'KP' },
    { label: 'Korea, Republic of', value: 'KR' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kyrgyzstan', value: 'KG' },
    { label: 'Lao People\'S Democratic Republic', value: 'LA' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Lebanon', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Liberia', value: 'LR' },
    { label: 'Libyan Arab Jamahiriya', value: 'LY' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Macao', value: 'MO' },
    { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
    { label: 'Madagascar', value: 'MG' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Malaysia', value: 'MY' },
    { label: 'Maldives', value: 'MV' },
    { label: 'Mali', value: 'ML' },
    { label: 'Malta', value: 'MT' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Martinique', value: 'MQ' },
    { label: 'Mauritania', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Mayotte', value: 'YT' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Micronesia, Federated States of', value: 'FM' },
    { label: 'Moldova, Republic of', value: 'MD' },
    { label: 'Monaco', value: 'MC' },
    { label: 'Mongolia', value: 'MN' },
    { label: 'Montserrat', value: 'MS' },
    { label: 'Morocco', value: 'MA' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Myanmar', value: 'MM' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Nauru', value: 'NR' },
    { label: 'Nepal', value: 'NP' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'Netherlands Antilles', value: 'AN' },
    { label: 'New Caledonia', value: 'NC' },
    { label: 'New Zealand', value: 'NZ' },
    { label: 'Nicaragua', value: 'NI' },
    { label: 'Niger', value: 'NE' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Niue', value: 'NU' },
    { label: 'Norfolk Island', value: 'NF' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Norway', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'Pakistan', value: 'PK' },
    { label: 'Palau', value: 'PW' },
    { label: 'Palestinian Territory, Occupied', value: 'PS' },
    { label: 'Panama', value: 'PA' },
    { label: 'Papua New Guinea', value: 'PG' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Philippines', value: 'PH' },
    { label: 'Pitcairn', value: 'PN' },
    { label: 'Poland', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Reunion', value: 'RE' },
    { label: 'Romania', value: 'RO' },
    { label: 'Russian Federation', value: 'RU' },
    { label: 'RWANDA', value: 'RW' },
    { label: 'Saint Helena', value: 'SH' },
    { label: 'Saint Kitts and Nevis', value: 'KN' },
    { label: 'Saint Lucia', value: 'LC' },
    { label: 'Saint Pierre and Miquelon', value: 'PM' },
    { label: 'Saint Vincent and the Grenadines', value: 'VC' },
    { label: 'Samoa', value: 'WS' },
    { label: 'San Marino', value: 'SM' },
    { label: 'Sao Tome and Principe', value: 'ST' },
    { label: 'Saudi Arabia', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbia and Montenegro', value: 'CS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Sierra Leone', value: 'SL' },
    { label: 'Singapore', value: 'SG' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Solomon Islands', value: 'SB' },
    { label: 'Somalia', value: 'SO' },
    { label: 'South Africa', value: 'ZA' },
    { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sri Lanka', value: 'LK' },
    { label: 'Sudan', value: 'SD' },
    { label: 'Suriname', value: 'SR' },
    { label: 'Svalbard and Jan Mayen', value: 'SJ' },
    { label: 'Swaziland', value: 'SZ' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Syrian Arab Republic', value: 'SY' },
    { label: 'Taiwan, Province of China', value: 'TW' },
    { label: 'Tajikistan', value: 'TJ' },
    { label: 'Tanzania, United Republic of', value: 'TZ' },
    { label: 'Thailand', value: 'TH' },
    { label: 'Timor-Leste', value: 'TL' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tokelau', value: 'TK' },
    { label: 'Tonga', value: 'TO' },
    { label: 'Trinidad and Tobago', value: 'TT' },
    { label: 'Tunisia', value: 'TN' },
    { label: 'Turkey', value: 'TR' },
    { label: 'Turkmenistan', value: 'TM' },
    { label: 'Turks and Caicos Islands', value: 'TC' },
    { label: 'Tuvalu', value: 'TV' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'United States', value: 'US' },
    { label: 'United States Minor Outlying Islands', value: 'UM' },
    { label: 'Uruguay', value: 'UY' },
    { label: 'Uzbekistan', value: 'UZ' },
    { label: 'Vanuatu', value: 'VU' },
    { label: 'Venezuela', value: 'VE' },
    { label: 'Viet Nam', value: 'VN' },
    { label: 'Virgin Islands, British', value: 'VG' },
    { label: 'Virgin Islands, U.S.', value: 'VI' },
    { label: 'Wallis and Futuna', value: 'WF' },
    { label: 'Western Sahara', value: 'EH' },
    { label: 'Yemen', value: 'YE' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' }
];

export const TransactionDataList = [
    {
        type: "Deposit",
        amount: {
            icon: BspIcon,
            value: 20.0000
        },
        time: "2023-12-1 11:30:56",
        status: "success"
    },
    {
        type: "Deposit",
        amount: {
            icon: BspIcon,
            value: 20.0000
        },
        time: "2023-12-1 11:30:56",
        status: "success"
    },
    {
        type: "Deposit",
        amount: {
            icon: BspIcon,
            value: 20.0000
        },
        time: "2023-12-1 11:30:56",
        status: "success"
    },
    {
        type: "Deposit",
        amount: {
            icon: BspIcon,
            value: 20.0000
        },
        time: "2023-12-1 11:30:56",
        status: "success"
    },
    {
        type: "Deposit",
        amount: {
            icon: BspIcon,
            value: 20.0000
        },
        time: "2023-12-1 11:30:56",
        status: "success"
    },
    {
        type: "Deposit",
        amount: {
            icon: BspIcon,
            value: 20.0000
        },
        time: "2023-12-1 11:30:56",
        status: "success"
    },
    {
        type: "Deposit",
        amount: {
            icon: BspIcon,
            value: 20.0000
        },
        time: "2023-12-1 11:30:56",
        status: "success"
    },
    {
        type: "Deposit",
        amount: {
            icon: BspIcon,
            value: 20.0000
        },
        time: "2023-12-1 11:30:56",
        status: "success"
    },
    {
        type: "Deposit",
        amount: {
            icon: BspIcon,
            value: 20.0000
        },
        time: "2023-12-1 11:30:56",
        status: "success"
    },
    {
        type: "Deposit",
        amount: {
            icon: BspIcon,
            value: 20.0000
        },
        time: "2023-12-1 11:30:56",
        status: "success"
    },
];

export const DegenSlotData = [
    {
        image: DegenSlotNFT1,
        content: "NFT1"
    },
    {
        image: DegenSlotNFT2,
        content: "NFT2"
    },
    {
        image: DegenSlotNFT3,
        content: "NFT3"
    },
    {
        image: DegenSlotNFT4,
        content: "NFT4"
    },
    {
        image: DegenSlotNFT5,
        content: "NFT5"
    },
    {
        image: DegenSlotNFT6,
        content: "NFT6"
    },
    {
        image: DegenSlotNFT7,
        content: "NFT7"
    },
    {
        image: DegenSlotNFT8,
        content: "NFT8"
    },
    {
        image: DegenSlotNFT9,
        content: "NFT9"
    },
    {
        image: DegenSlotNFT10,
        content: "NFT10"
    },
];

export const FreeSpinData = [
    { value: '3.30000', icon: BnbIcon },
    { value: '5.00000', icon: BTCIcon },
    { value: '96.7000', icon: DaiIcon },
    { value: '0.06470', icon: EthIcon },
    { value: '0.73100', icon: FOUNDIcon },
    { value: '1100000', icon: MaticTIcon },
    { value: '1.96000', icon: NUsdcIcon },
    { value: '170.000', icon: USDIcon },
    { value: '397.000', icon: UsdtIcon },
    { value: '181.000', icon: BspIcon },
];
