import React, { useCallback, useState } from "react";
import { Card, Col, Container, Image, Row, Table } from "react-bootstrap";
import { AppleIcon, FacebookIcon, GoogleIcon, HomeMainCardBg, MetamaskIcon, WhiteLogo } from "../assets";
import { MainButton } from "../components/buttons/buttons";
import { MonthTopPlayerData } from "../utils/data";
import { CrashGameCardCarousel, MysteryBoxCarousel } from "../components/carousels/multiItemsCarousel";
import { LatestBetTable } from "../components/tables/tables";
import Preloader from "../components/preloader/preloader";
import ConnectWallet from "../components/wallet/walletConnect";
import { LoginSocialFacebook, LoginSocialGoogle, IResolveParams } from "reactjs-social-login";
import { useMainContext } from "../context/mainContext";

const REDIRECT_URI = window.location.href;

const HomePage: React.FunctionComponent = () => {
    const { token, userSocialSignIn, setSignupModal, provider, setProvider, profile, setProfile } = useMainContext();

    const onLoginStart = useCallback(() => {
    }, [])

    const onLogoutSuccess = useCallback(() => {
        setProfile(null)
        setProvider('')
        alert('logout success')
    }, [])

    return (
        <div className="HomePage">
            <Container fluid>
                <section>
                    <Row>
                        <Col sm={12} md={9} className="mb-3">
                            <Card className="home-main-card border-white" style={{ backgroundImage: `url(${HomeMainCardBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                <Card.Body className="text-center py-4">
                                    <Card.Title className="mb-5">New Innovative Platform</Card.Title>
                                    <div className="mb-5"><Image src={WhiteLogo} height={160} width="100%" alt="logo" /></div>
                                    {!token ? (
                                        <div>
                                            <span onClick={() => setSignupModal(true)}><MainButton title="join now" /></span>
                                            <Row>
                                                <Col sm={6} md={6} className="mx-auto mt-5">
                                                    {provider && profile ? (
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Image src={GoogleIcon} width={50} alt="google" className="mx-2" />
                                                            <Image src={FacebookIcon} width={50} alt="apple" className="mx-2" />
                                                            <Image src={MetamaskIcon} width={50} alt="metamask" className="mx-2" />
                                                        </div>
                                                    ) : (
                                                        <div className={`d-flex flex-row align-items-center justify-content-center${provider && profile ? 'hide' : ''}`}>
                                                            <LoginSocialGoogle
                                                                client_id='534675856580-ad2dfm2eqmeah815vfurt1b00hjg8363.apps.googleusercontent.com'
                                                                onLoginStart={onLoginStart}
                                                                redirect_uri={REDIRECT_URI}
                                                                scope="https://www.googleapis.com/auth/userinfo.profile"
                                                                discoveryDocs="claims_supported"
                                                                onResolve={({ provider, data }: IResolveParams) => {
                                                                    setProvider(provider);
                                                                    setProfile(data);
                                                                    userSocialSignIn({ provider: provider, email: data!.email, firstName: data!.given_name, lastName: data!.family_name });
                                                                }}
                                                                onReject={err => {
                                                                    console.log(err);
                                                                }}
                                                            >
                                                                <Image src={GoogleIcon} width={50} alt="google" className="mx-2" />
                                                            </LoginSocialGoogle>
                                                            <LoginSocialFacebook
                                                                appId={'247017758379823' || ''}
                                                                fieldsProfile={
                                                                    'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                                                }
                                                                onLoginStart={onLoginStart}
                                                                onLogoutSuccess={onLogoutSuccess}
                                                                redirect_uri={REDIRECT_URI}
                                                                onResolve={({ provider, data }: IResolveParams) => {
                                                                    setProvider(provider);
                                                                    setProfile(data);
                                                                    userSocialSignIn({ provider: provider, email: data!.email, firstName: data!.first_name, lastName: data!.last_name });
                                                                }}
                                                                onReject={err => {
                                                                    console.log(err);
                                                                }}
                                                            >
                                                                <Image src={FacebookIcon} width={50} alt="facebook" className="mx-2" />
                                                            </LoginSocialFacebook>
                                                            <ConnectWallet><Image src={MetamaskIcon} width={50} alt="metamask" /></ConnectWallet>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    ) : (
                                        <Card.Title className="py-5">Welcome to bitNswap !</Card.Title>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={3} className="mb-3">
                            <Card className="border-white bg-primary-2 month-top-players">
                                <Card.Body className="py-3">
                                    <Card.Title className="text-center">Winner This Month</Card.Title>
                                    <hr className="gradient-hr" />
                                    <Table className="mb-0">
                                        <thead>
                                            <tr>
                                                <th className='text-start bg-transparent border-0 py-1'>User</th>
                                                <th className='text-end bg-transparent border-0 py-1'>Win Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {MonthTopPlayerData.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="bg-transparent border-0 py-1">
                                                        <span className='d-flex align-items-center'>
                                                            <Image src={item.player.avatar} width={30} alt="avatar" />
                                                            <span className="ms-2">{item.player.name}</span>
                                                        </span>
                                                    </td>
                                                    <td className="bg-transparent border-0 py-1">
                                                        <span className='d-flex align-items-center justify-content-end'>
                                                            <span className="me-2">{item.amount.value}</span>
                                                            <Image src={item.amount.coin} width={30} alt="avatar" />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </section>
                <section>
                    <div className="section-title py-3 text-center text-md-start">Mystery box</div>
                    <MysteryBoxCarousel />
                </section>
                <section>
                    <div className="section-title py-3 text-center text-md-start">Crash Games</div>
                    <CrashGameCardCarousel />
                </section>
                <section className="mb-5">
                    <div className="section-title py-3 text-center text-md-start">Game History</div>
                    <LatestBetTable />
                </section>
            </Container>
        </div>
    )
}

export default HomePage;