import React, { useState } from "react";
import { Accordion, Card, Col, Container, Image, Navbar, Offcanvas, Row, useAccordionButton, Button } from "react-bootstrap";
import { DailyJackpotBanner, HomeIcon, LamboJackpotImage1, MenuIcon, ProfileIcon, SpintBanner, WhiteLogo, } from "../../assets";
import { NavLink } from "react-router-dom";
import { Routers } from "../../routes/routers";
import { useMainContext } from "../../context/mainContext";

export const Footer: React.FunctionComponent = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { setFreeSpinModal } = useMainContext();

    const AccordionToggle = ({ children, eventKey }: { children: React.ReactNode, eventKey: string }) => {
        const decoratedOnClick = useAccordionButton(eventKey);
        return (
            <Button className="bg-transparent border-0 w-100 p-0" onClick={decoratedOnClick}>
                {children}
            </Button>
        )
    }

    return (
        <Navbar collapseOnSelect expand="lg" className="Footer d-md-none d-block" fixed="bottom">
            <div className="w-100 px-3 d-flex align-items-center justify-content-between">
                <NavLink to="/" className="text-white d-flex flex-column align-items-center nav-link">
                    <Image src={HomeIcon} width={30} className="mb-1" />
                    <small>Home</small>
                </NavLink>
                <div className="footer-center-btn position-relative">
                    <div className="text-white d-flex flex-column align-items-center position-relative z-3" onClick={handleShow}>
                        <Image src={MenuIcon} width={30} className="mb-1" />
                        <small>Menu</small>
                    </div>
                </div>
                <NavLink to="/setting" className="text-white d-flex flex-column align-items-center nav-link">
                    <Image src={ProfileIcon} width={30} className="mb-1" />
                    <small>Profile</small>
                </NavLink>

            </div>
            <Offcanvas show={show} onHide={handleClose} className="bg-primary-1">
                <Offcanvas.Header closeButton className="">
                    <Image src={WhiteLogo} height={50} alt="logo"/>
                </Offcanvas.Header>
                <div className="Sidebar border-end-0 overflow-x-auto" >
                    <Container fluid className="py-5">
                        <Card className="rounded-4 mb-3 jackpot">
                            <NavLink to="/lambo_jackpot">
                                <Image src={LamboJackpotImage1} width="100%" alt="banner" />
                            </NavLink>
                        </Card>
                        <Row className="mb-3">
                            <Col xs={6} lg={6}> <NavLink to="/daily_jackpot"><Image src={DailyJackpotBanner} width="100%" alt="banner" className="border border-1 border-white rounded-3 sidebar-image-icon" /></NavLink></Col>
                            <Col xs={6} lg={6}><Image src={SpintBanner} width="100%" alt="banner" className="border border-1 border-white rounded-3 sidebar-image-icon" /></Col>
                        </Row>
                        <Accordion defaultActiveKey="0">
                            {Routers.map((item, index) => (
                                <Card className="bg-transparent p-0 border-0" key={index}>
                                    <Card.Header className="bg-transparent p-0 border-0 mb-2">
                                        <AccordionToggle eventKey={`${index}`}>
                                            {item.subMenus.length === 0 ? (
                                                <NavLink key={index} to={item.path} className="nav-link text-white d-flex align-items-center px-2 mb-0" onClick={handleClose}>
                                                    <Image src={item.icon} width={20} alt="nav-icon" className="me-2" /><span className="text-white">| {item.title}</span>
                                                </NavLink>
                                            ) : (
                                                <Card className="w-100 text-start nav-link px-2 mb-0 bg-transparent d-flex align-items-center flex-row border-0">
                                                    <Image src={item.icon} width={20} alt="nav-icon" className="me-2" /><span className="text-white">| {item.title}</span>
                                                </Card>
                                            )}
                                        </AccordionToggle>
                                    </Card.Header>
                                    {item.subMenus?.map((menu, j) => (
                                        <Accordion.Collapse eventKey={`${index}`} key={j}>
                                            <NavLink key={j} to={menu.path} onClick={handleClose} className="nav-link text-white d-flex align-items-center px-2 ms-3 mb-2">
                                                <span>{menu.title}</span>
                                            </NavLink>
                                        </Accordion.Collapse>
                                    ))}
                                </Card>
                            ))}
                        </Accordion>
                    </Container>
                </div>
            </Offcanvas>
        </Navbar>
    )
}