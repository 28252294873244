import React, { ReactNode } from "react";
import { Accordion, Button, Card, Col, Container, Image, Nav, Row, useAccordionButton } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Routers } from "../../routes/routers";
import { BankRollImg, DailyJackpotBanner, DefiIcon, JackPortBanner, LamboJackpotImage1, NFTGameIcon, SpintBanner } from "../../assets";
import { useMainContext } from "../../context/mainContext";
import { toast } from "react-toastify";

export const Sidebar: React.FunctionComponent = () => {
    const { setFreeSpinModal } = useMainContext();

    const AccordionToggle = ({ children, eventKey }: { children: ReactNode, eventKey: string }) => {
        const decoratedOnClick = useAccordionButton(eventKey);
        return (
            <Button className="bg-transparent border-0 w-100 p-0" onClick={decoratedOnClick}>
                {children}
            </Button>
        )
    }

    return (
        <div className="Sidebar d-none d-md-block overflow-x-auto" style={{ width: "15rem" }}>
            <Container fluid className="pb-5 bt-4">
                <Row className="mb-3">
                    <Col className="text-center">
                        <Image src={BankRollImg} width="100%"/>
                        <div className="text-white bankroll">TOTAL Amount:<br/>BSP 999,999.00</div>
                    </Col>
                </Row>
                <hr className="text-white section-title"/>
                <Card className="rounded-4 mb-3 jackpot">
                    <NavLink to="/lambo_jackpot">
                        <Image src={LamboJackpotImage1} width="100%" alt="banner" />
                    </NavLink>
                </Card>
                <Row className="mb-3">
                    <Col xs={6} lg={6}> <NavLink to="/daily_jackpot"><Image src={DailyJackpotBanner} width="100%" alt="banner" className="border border-1 border-white rounded-3 sidebar-image-icon" /></NavLink></Col>
                    <Col xs={6} lg={6}><Image src={SpintBanner} width="100%" alt="banner" className="border border-1 border-white rounded-3 sidebar-image-icon" onClick={() => setFreeSpinModal(true)} /></Col>
                </Row>
                <Accordion defaultActiveKey="0">
                    {Routers.map((item, index) => (
                        <Card className="bg-transparent p-0 border-0" key={index}>
                            <Card.Header className="bg-transparent p-0 border-0 mb-2">
                                <AccordionToggle eventKey={`${index}`}>
                                    {item.subMenus.length === 0 ? (
                                        <NavLink key={index} to={item.path} className="nav-link text-white d-flex align-items-center px-2 mb-0">
                                            <Image src={item.icon} width={20} alt="nav-icon" className="me-2" /><span>| {item.title}</span>
                                        </NavLink>
                                    ) : (
                                        <Card className="w-100 text-start nav-link px-2 mb-0 bg-transparent d-flex align-items-center flex-row border-0">
                                            <Image src={item.icon} width={20} alt="nav-icon" className="me-2" /><span>| {item.title}</span>
                                        </Card>
                                    )}
                                </AccordionToggle>
                            </Card.Header>
                            {item.subMenus?.map((menu, j) => (
                                <Accordion.Collapse eventKey={`${index}`} key={j}>
                                    <NavLink key={j} to={menu.path} className="nav-link text-white d-flex align-items-center px-2 ms-3 mb-2">
                                        <span>{menu.title}</span>
                                    </NavLink>
                                </Accordion.Collapse>
                            ))}
                        </Card>
                    ))}
                    <Card className="bg-transparent p-0 border-0">
                        <Card.Header className="bg-transparent p-0 border-0 mb-2" onClick={() =>  toast.info("COMING SOON")}>
                            <AccordionToggle eventKey="0">
                                <Nav.Link className="nav-link text-white d-flex align-items-center px-2 mb-0">
                                    <Image src={DefiIcon} width={20} alt="nav-icon" className="me-2" /><span>| DEFI</span>
                                </Nav.Link>
                            </AccordionToggle>
                        </Card.Header>
                    </Card>
                    <Card className="bg-transparent p-0 border-0">
                        <Card.Header className="bg-transparent p-0 border-0 mb-2" onClick={() =>  toast.info("COMING SOON")}>
                            <AccordionToggle eventKey="0">
                                <Nav.Link className="nav-link text-white d-flex align-items-center px-2 mb-0">
                                    <Image src={NFTGameIcon} width={20} alt="nav-icon" className="me-2" /><span>| Play</span>
                                </Nav.Link>
                            </AccordionToggle>
                        </Card.Header>
                    </Card>
                    <Card className="bg-transparent p-0 border-0">
                        <Card.Header className="bg-transparent p-0 border-0 mb-2" onClick={() =>  toast.info("COMING SOON")}>
                            <AccordionToggle eventKey="0">
                                <Nav.Link className="nav-link text-white d-flex align-items-center px-2 mb-0">
                                    <Image src={NFTGameIcon} width={20} alt="nav-icon" className="me-2" /><span>| Marketplace</span>
                                </Nav.Link>
                            </AccordionToggle>
                        </Card.Header>
                    </Card>
                </Accordion>
            </Container>
        </div>
    )
}