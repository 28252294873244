import { Card, Col, Container, Image, Row, Table } from "react-bootstrap";
import moment from 'moment';
import { useEffect, useState } from "react";
import { DailyJackpotSymbol } from "../../assets";

const DailyJackpotPage: React.FunctionComponent = () => {

    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [seconds, setSeconds] = useState<number>(0);
    const [showSemicolon, setShowSemicolon] = useState<boolean>(false);

    useEffect(() => {
        setInterval(() => {
            const now = moment().utc();
            const tomorrow = moment().add(1, 'days').startOf('day').utc();
            const countdown = moment.duration(tomorrow.diff(now));
            setHours(countdown.hours());
            setMinutes(countdown.minutes());
            setSeconds(countdown.seconds());
        }, 1000);
    }, []);

    return (
        <div className="DailyJackpotPage">
            <Container fluid>
                <Card className="bg-primary-2 border-white mb-3">
                    <Card.Body>
                        <Row>
                            <Col xs={12} lg={6} className="mb-3">
                                <Row>
                                    <Col xs={12} lg={6} className="mb-2">
                                        <Image src={DailyJackpotSymbol} height={100} alt="banner" />
                                    </Col>
                                    <Col xs={12} lg={6} className="mb-2">
                                        <Card.Title className="section-title fs-2 text-center">Win Amount <br /> #000000</Card.Title>
                                    </Col>
                                </Row>
                                <div className="d-flex align-items-center justify-content-between">
                                    <Card.Subtitle className="fs-5">Amount: </Card.Subtitle>
                                    <Card.Title className="section-title fs-5 mb-2">$ 500,000,000.00</Card.Title>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <Card.Subtitle className="fs-5">Your Wager: </Card.Subtitle>
                                    <Card.Title className="section-title fs-5 mb-2">$ 500,000,000.00</Card.Title>
                                </div>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Card.Title className="section-title fs-2 text-center mb-3">Wind Daily Jackpot</Card.Title>
                                <Card.Subtitle className="text-uppercase text-center">highest daily wagerer / bettor will win the jackpot!</Card.Subtitle>
                                <div className="timer-container">
                                    {showSemicolon ? <div className="semicolon">:</div> : null}
                                    <div className="timer">
                                        {hours}
                                        <span className="text-white text-uppercase">hour</span>
                                    </div>
                                    {showSemicolon ? <div className="semicolon">:</div> : null}
                                    <div className="timer">
                                        {minutes}
                                        <span className="text-white text-uppercase">minute</span>
                                    </div>
                                    {showSemicolon ? <div className="semicolon">:</div> : null}
                                    <div className="timer">
                                        {seconds}
                                        <span className="text-white text-uppercase">second</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Row>
                    <Col xs={12} lg={6}>
                        <Card className="bg-primary-2 border-white mb-3">
                            <Card.Body>
                                <Card.Title className="section-title fs-2 text-center mb-3">LEADERBOARD</Card.Title>
                                <hr />
                                <div className="border border-white rounded-3 bg-primary-2">
                                    <Table className="mb-0" responsive>
                                        <thead>
                                            <tr>
                                                <th className="bg-transparent border-white text-center">RANK</th>
                                                <th className="bg-transparent border-white text-center">USER ID</th>
                                                <th className="bg-transparent border-white text-center">WAGERED</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">1</td>
                                                <td className="bg-transparent border-white text-center">39928742</td>
                                                <td className="bg-transparent border-white text-center">0.24559000</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">2</td>
                                                <td className="bg-transparent border-white text-center">39928742</td>
                                                <td className="bg-transparent border-white text-center">0.24559000</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">3</td>
                                                <td className="bg-transparent border-white text-center">39928742</td>
                                                <td className="bg-transparent border-white text-center">0.24559000</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">4</td>
                                                <td className="bg-transparent border-white text-center">39928742</td>
                                                <td className="bg-transparent border-white text-center">0.24559000</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">5</td>
                                                <td className="bg-transparent border-white text-center">39928742</td>
                                                <td className="bg-transparent border-white text-center">0.24559000</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">6</td>
                                                <td className="bg-transparent border-white text-center">39928742</td>
                                                <td className="bg-transparent border-white text-center">0.24559000</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">7</td>
                                                <td className="bg-transparent border-white text-center">39928742</td>
                                                <td className="bg-transparent border-white text-center">0.24559000</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">9</td>
                                                <td className="bg-transparent border-white text-center">39928742</td>
                                                <td className="bg-transparent border-white text-center">0.24559000</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">10</td>
                                                <td className="bg-transparent border-white text-center">39928742</td>
                                                <td className="bg-transparent border-white text-center">0.24559000</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Card className="bg-primary-2 border-white mb-3">
                            <Card.Body>
                                <Card.Title className="section-title fs-2 text-center mb-3">WINNERS</Card.Title>
                                <hr />
                                <div className="border border-white rounded-3 bg-primary-2">
                                    <Table className="mb-0" responsive>
                                        <thead>
                                            <tr>
                                                <th className="bg-transparent border-white text-center">DATE</th>
                                                <th className="bg-transparent border-white text-center">USER ID</th>
                                                <th className="bg-transparent border-white text-center">AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">2024-02-13</td>
                                                <td className="bg-transparent border-white text-center">37419370</td>
                                                <td className="bg-transparent border-white text-center">0.01960605</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">2024-02-13</td>
                                                <td className="bg-transparent border-white text-center">37419370</td>
                                                <td className="bg-transparent border-white text-center">0.01960605</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">2024-02-13</td>
                                                <td className="bg-transparent border-white text-center">37419370</td>
                                                <td className="bg-transparent border-white text-center">0.01960605</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">2024-02-13</td>
                                                <td className="bg-transparent border-white text-center">37419370</td>
                                                <td className="bg-transparent border-white text-center">0.01960605</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">2024-02-13</td>
                                                <td className="bg-transparent border-white text-center">37419370</td>
                                                <td className="bg-transparent border-white text-center">0.01960605</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">2024-02-13</td>
                                                <td className="bg-transparent border-white text-center">37419370</td>
                                                <td className="bg-transparent border-white text-center">0.01960605</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">2024-02-13</td>
                                                <td className="bg-transparent border-white text-center">37419370</td>
                                                <td className="bg-transparent border-white text-center">0.01960605</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">2024-02-13</td>
                                                <td className="bg-transparent border-white text-center">37419370</td>
                                                <td className="bg-transparent border-white text-center">0.01960605</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-transparent border-white text-center">2024-02-13</td>
                                                <td className="bg-transparent border-white text-center">37419370</td>
                                                <td className="bg-transparent border-white text-center">0.01960605</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default DailyJackpotPage;