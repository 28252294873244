import React from "react";
import { Image, Table } from "react-bootstrap";
import { ActiveBetsData, LatestBetData } from "../../utils/data";
import { BspIcon, DownIcon, UpIcon } from "../../assets";
import { ActiveTradeListItemProps } from "../charts/cryptoPredictionChart";

export const LatestBetTable: React.FunctionComponent = () => {
    return (
        <div className="LatestBetTable border border-white rounded-3 bg-primary-2">
            <Table className="mb-0" responsive>
                <thead>
                    <tr>
                        <th className="bg-transparent border-white">Player</th>
                        <th className="bg-transparent border-white">Game</th>
                        <th className="bg-transparent border-white d-none d-md-table-cell">Time</th>
                        <th className="bg-transparent border-white d-none d-md-table-cell">Bet Amount</th>
                        <th className="bg-transparent border-white d-none d-md-table-cell">Multiplier</th>
                        <th className="bg-transparent border-white">ROI</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        LatestBetData.map((item, index) => (
                            <tr key={index}>
                                <td className="bg-transparent border-white">
                                    <span className="d-flex align-items-center">
                                        <Image src={item.player.avatar} width={35} alt="avatar" />
                                        <span className="ms-2">{item.player.name}</span>
                                    </span>
                                </td>
                                <td className="bg-transparent border-white">{item.game}</td>
                                <td className="bg-transparent border-white d-none d-md-table-cell">{item.time}</td>
                                <td className="bg-transparent border-white d-none d-md-table-cell">
                                    <span className="d-flex align-items-center">
                                        <Image src={BspIcon} width={30} alt="avatar" />
                                        <span className="ms-2">{item.amount}</span>
                                    </span>
                                </td>
                                <td className="bg-transparent border-white d-none d-md-table-cell">x{item.multiplier}</td>
                                <td className="bg-transparent border-white">
                                    <span className="d-flex align-items-center">
                                        <Image src={BspIcon} width={30} alt="avatar" />
                                        <span className="ms-2">{item.roi}</span>
                                    </span>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    )
}

export const ActiveTradeTable: React.FunctionComponent<{ data?: any | null}> = (props) => {
    console.log("table------------------------===================",props.data)
    return (
        <div className="border border-white rounded-3 bg-primary-2 overflow-hidden">
            <Table responsive className="mb-0 bg-primary-2">
                <thead>
                    <tr>
                        <th className="bg-transparent text-uppercase text-center align-middle border-white">No</th>
                        <th className="bg-transparent text-uppercase text-center align-middle border-white">PLAYER</th>
                        <th className="bg-transparent text-uppercase text-center align-middle border-white">BET</th>
                        <th className="bg-transparent text-uppercase text-center align-middle border-white ">Choice</th>
                        <th className="bg-transparent text-uppercase text-center align-middle border-white">ENTRY PRICE</th>
                        <th className="bg-transparent text-uppercase text-center align-middle border-white d-none d-lg-table-cell">Duration</th>
                        <th className="bg-transparent text-uppercase text-center align-middle border-white d-none d-lg-table-cell">Status</th>
                    </tr>
                </thead>
                {
                    (props.data === null || props.data === undefined) ? (
                        <tbody>
                            <tr>
                                <td>No Trade List</td>
                            </tr>
                        </tbody>
                    ) : (

                        <tbody>
                            {props.data?.map(({row, index}: {row: any, index: number}) => {
                                console.log("row", row)
                                return(
                                <tr key={index}>
                                    <td className="bg-transparent border-white align-middle text-center">{index + 1}</td>
                                    <td className="bg-transparent border-white align-middle text-center">
                                        <span className="">{row?.client_firstname + " " + row?.client_lastname}</span>
                                    </td>
                                    <td className="bg-transparent border-white text-center">
                                        <span className="">{row?.label}</span>
                                    </td>
                                    <td className="bg-transparent border-white text-center  align-middle">{row?.choice === "PUT" ? (<Image src={DownIcon} height={15} />) : (<Image src={UpIcon} height={15} />)}</td>
                                    <td className="bg-transparent border-white text-center align-middle">{row?.amount_bet}</td>
                                    <td className="bg-transparent border-white text-center align-middle d-none d-lg-table-cell">{row?.duration / 60} Min</td>
                                    <td className="bg-transparent border-white text-center align-middle d-none d-lg-table-cell">{row?.status}</td>
                                </tr>
                            )})}
                        </tbody>
                    )
                }
            </Table>
        </div>
    )
}

export const NFTTradeTable: React.FunctionComponent = () => {
    return (
        <div className="border border-white rounded-3 bg-primary-2 overflow-hidden">
            <Table responsive className="mb-0 bg-primary-2">
                <thead>
                    <tr>
                        <th className="bg-transparent border-white">PLAYER</th>
                        <th className="bg-transparent border-white">BET</th>
                        <th className="bg-transparent border-white">WAGER</th>
                    </tr>
                </thead>
                <tbody>
                    {ActiveBetsData.map((row, index) => (
                        <tr>
                            <td className="bg-transparent border-white">
                                <span className="d-flex align-items-center">
                                    <Image src={row.player.avatar} width={35} alt="avatar" />
                                    <span className="ms-2">{row.player.name}</span>
                                </span>
                            </td>
                            <td className="bg-transparent border-white">
                                <span className="d-flex align-items-center">
                                    <Image src={row.bet.coin_icon} width={30} alt="avatar" />
                                    <span className="ms-2">{row.bet.status}</span>
                                </span>
                            </td>
                            <td className="bg-transparent border-white">{row.wager}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}