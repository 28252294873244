import React, { useState, useEffect, useRef } from 'react';
import './index.css';

interface IncrementalCounterProps {
  value: number;
  digits?: number;
}

const IncrementalCounter: React.FC<IncrementalCounterProps> = ({ value, digits = 4 }) => {
  const [currentValue, setCurrentValue] = useState(0);
  const numsRef = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    const pad = (n: number, width: number, z: string = '0'): string => {
      let nStr = n.toString();
      return nStr.length >= width ? nStr : new Array(width - nStr.length + 1).join(z) + nStr;
    };

    const start = (current: number, end: number) => {
      let currentSpeed = 35;

      if (end - current < 5) {
        currentSpeed = 35;
        setCurrentValue(current + 1);
      } else if (end - current < 15) {
        currentSpeed = 25;
        setCurrentValue(current + 1);
      } else if (end - current < 50) {
        currentSpeed = 25;
        setCurrentValue(current + 3);
      } else {
        currentSpeed = 35;
        setCurrentValue(current + (end - current) / 24);
      }

      if (currentSpeed) {
        setTimeout(() => {
          display(current + 1);
        }, currentSpeed);
      } else {
        display(current + 1);
      }
    };

    const display = (value: number) => {
      const padedNumber = pad(value, digits);
      const exp = padedNumber.split('');
      exp.forEach((e, i) => {
        if (numsRef.current[i]) {
          numsRef.current[i]!.innerText = e; // Use optional chaining
        }
      });
    };

    const endValue = value;
    const defaultDigits = digits;

    numsRef.current = new Array(defaultDigits).fill(null).map(() => document.createElement('div'));
    const nums = numsRef.current;

    if (nums.length > 0) {
      nums.forEach(num => {
        if (num) num.className = 'num';
      });
    }

    display(currentValue);

    start(currentValue, endValue);
  }, [value, digits, currentValue]);

  return (
    <div className='flipper d-flex align-items-center justify-content-center w-100'>
        <div className="num">$</div>
      {numsRef.current.map((num, index) => (
        <div key={index} ref={(el) => (numsRef.current[index] = el)} className="num">0</div>
      ))}
    </div>
  );
};

export default IncrementalCounter;
