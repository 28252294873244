import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Tab, Tabs, Form, Table, Image, Button } from "react-bootstrap";
import Select from 'react-select'
import { CountryList } from "../../utils/data";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { MainButton } from "../../components/buttons/buttons";
import { BspIcon, XBspIcon } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { useMainContext } from "../../context/mainContext";
import { useNavigate } from "react-router-dom";

interface DepositHistoryProps {
    id: string;
    amount: string;
    date: string;
    payment_type: string;
    status: string;
}

interface WithdrawHistoryProps {
    id: string;
    client_id: string;
    amount: string;
    currency: string;
    date: string;
    status: string
}

const SettingPage: React.FunctionComponent<{}> = () => {
    const { token, balance } = useMainContext();
    const [countryCode, setCountryCode] = useState<{ label: string; value: string; } | null>(null);
    const [userEmail, setUserEmail] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [birth, setBirth] = useState('');
    const [city, setCity] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [postal, setPostal] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [language, setLanguage] = useState<string>('EN');
    const [currency, setCurrency] = useState<string>('USD');
    const [transactionType, setTransactionType] = useState<string>('deposit');

    const [withdrawHistory, setWithdrawHistory] = useState<WithdrawHistoryProps[] | null>(null);
    const [depositHistory, setDepositHistory] = useState<DepositHistoryProps[] | null>(null);

    const navigate = useNavigate();


    const getInformation = async () => {
        const headers = {
            contentType: 'application/json',
            Authorization: token,
            token: token
        }

        await axios.get(`${process.env.REACT_APP_API_URL}/client/informations/`, { headers })
            .then(function (response) {
                setUserEmail(response.data.email);
                setUserName(response.data.username);
                setFirstName(response.data.firstname);
                setLastName(response.data.lastname)
                setBirth(response.data.date_of_birth)
                setCity(response.data.city)
                setAddress(response.data.address)
                setPostal(response.data.postal_code);
                setPhone(response.data.phone)
                setCountryCode(JSON.parse(response.data.country))
                setLanguage(response.data.language);
                setCurrency(response.data.currency)

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const updateProfile = async () => {
        if (userEmail === '' || userName === '' || firstName === "" || lastName === '' || countryCode === null || city === '' || address === '' || postal === '' || phone === '') {
            toast.error("Please enter Information")
            return;
        }

        const headers = {
            contentType: 'application/json',
            Authorization: token,
            token: token
        }
        const body = {
            email: userEmail,
            username: userName,
            firstname: firstName,
            lastname: lastName,
            country: JSON.stringify(countryCode),
            city: city,
            address: address,
            postal_code: postal,
            phone: phone,
            date_of_birth: birth,
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/client/set-profile/`, body, { headers })
            .then(function (response) {
                if (response.data.error_code === 0) {
                    toast.success("Your profile information has been updated.")
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const updateInfo = async () => {
        const headers = {
            contentType: 'application/json',
            Authorization: token,
            token: token
        }
        const body = {
            email: userEmail,
            language: language,
            currency: currency,
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/client/set-others-infos/`, body, { headers })
            .then(function (response) {
                if (response.data.error_code === 0) {
                    toast.success("Your information has been updated.")
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getWithdrawHistory = async () => {
        const headers = {
            contentType: 'application/json',
            Authorization: token,
            token: token
        }

        await axios.get(`${process.env.REACT_APP_API_URL}/client/withdrawal/history/`, { headers })
            .then(function (response) {
                if (response.data.error_code === 0) {
                    setWithdrawHistory(response.data.list);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getDepositHistory = async () => {
        const headers = {
            contentType: 'application/json',
            Authorization: token,
            token: token
        }

        await axios.get(`${process.env.REACT_APP_API_URL}/client/deposit/history/`, { headers })
            .then(function (response) {
                if (response.data.error_code === 0) {
                    setDepositHistory(response.data.list);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        if (!token) {
            navigate('/');
            return
        }
        getInformation();
        getWithdrawHistory();
        getDepositHistory();
    }, []);

    useEffect(() => {
        getWithdrawHistory();
        getDepositHistory();
    }, [transactionType]);
    return (
        <div className="SettingPage">
            <Container fluid>
                <Card className="bg-primary-2 border-white">
                    <Card.Body>
                        <Tabs
                            defaultActiveKey="setting"
                            id="uncontrolled-tab-example"
                            className="mb-3 border-0"
                        >
                            <Tab eventKey="setting" title="settings">
                                <Row>
                                    <Col sm={12} md={4} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} placeholder="Enter Your Email Address" className="bg-primary-1 border-white rounded-5" />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>User Name</Form.Label>
                                            <Form.Control type="text" value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="Enter Your User Name" className="bg-primary-1 border-white rounded-5" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col sm={12} md={4} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Enter Your First Name" className="bg-primary-1 border-white rounded-5" />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Enter Your Last Name" className="bg-primary-1 border-white rounded-5" />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Date of Birth</Form.Label>
                                            <Form.Control type="date" value={birth} onChange={(e) => setBirth(e.target.value)} className="bg-primary-1 border-white rounded-5" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col sm={12} md={3} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Country</Form.Label>
                                            <Select options={CountryList} className="country-selector" value={countryCode} onChange={(e) => setCountryCode(e)} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={3} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>City</Form.Label>
                                            <Form.Control type="text" value={city} onChange={(e) => setCity(e.target.value)} placeholder="Enter Your City" className="bg-primary-1 border-white rounded-5" />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={3} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Enter Your Address" className="bg-primary-1 border-white rounded-5" />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={3} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Postal Code</Form.Label>
                                            <Form.Control type="text" value={postal} onChange={(e) => setPostal(e.target.value)} placeholder="Enter Your Postal Code" className="bg-primary-1 border-white rounded-5" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col sm={12} md={4} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Phone Number</Form.Label>
                                            <PhoneInput
                                                country={'us'}
                                                value={phone}
                                                onChange={(phone) => setPhone(phone)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={3} className="my-3">
                                        <span onClick={updateProfile}><MainButton title="save" /></span>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="account" title="account info">
                                <Row className="align-items-end">
                                    <Col sm={12} md={4} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Language</Form.Label>
                                            <Form.Select aria-label="Default select example" value={language} onChange={(e) => setLanguage(e.target.value)} className="rounded-5 bg-primary-1">
                                                <option value="EN">English</option>
                                                <option value="FR">French</option>
                                                <option value="RU">Russia</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Preferred Display Currency</Form.Label>
                                            <Form.Select aria-label="Default select example" value={currency} onChange={(e) => setCurrency(e.target.value)} className="rounded-5 bg-primary-1">
                                                <option value="USD">USD</option>
                                                <option value="EUR">EUR</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4} className="mb-3">
                                        <span onClick={updateInfo}><MainButton title="Save changes" /></span>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="balance" title="balances">
                                <div className="border border-white rounded-3 bg-primary-2 overflow-hidden">
                                    <Table className="mb-0 bg-primary-2">
                                        <thead>
                                            <tr>
                                                <th className="bg-transparent border-white">Currency</th>
                                                <th className="bg-transparent border-white">Total Balace</th>
                                                <th className="bg-transparent border-white">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td width="30%" className="bg-transparent border-white align-middle">
                                                    <span className="d-flex align-items-center">
                                                        <Image src={BspIcon} width={30} className="me-2" alt="coin" />
                                                        <span>BSP</span>
                                                    </span>
                                                </td>
                                                <td width="30%" className="bg-transparent border-white align-middle">{balance.USD}</td>
                                                <td width="10%" className="bg-transparent border-white align-middle">
                                                    <span className="d-flex">
                                                        <Button className="deposit-btn rounded-5 bg-warning-1 border-0 me-2">Deposit</Button>
                                                        <Button className="withdraw-btn rounded-5 bg-secondary-1 border-0 me-2">Withdraw</Button>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%" className="bg-transparent border-white align-middle">
                                                    <span className="d-flex align-items-center">
                                                        <Image src={XBspIcon} width={30} className="me-2" alt="coin" />
                                                        <span>xBSP</span>
                                                    </span>
                                                </td>
                                                <td width="30%" className="bg-transparent border-white align-middle">{balance.xBSP}</td>
                                                <td width="10%" className="bg-transparent border-white align-middle">
                                                    <span className="d-flex">
                                                        <Button className="deposit-btn rounded-5 bg-warning-1 border-0 me-2">Deposit</Button>
                                                        <Button className="withdraw-btn rounded-5 bg-secondary-1 border-0 me-2">Withdraw</Button>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Tab>
                            <Tab eventKey="transaction" title="transactions">
                                <Row>
                                    <Col sm={12} md={4} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Type</Form.Label>
                                            <Form.Select aria-label="Default select example" className="rounded-5 bg-primary-1 border-white" value={transactionType} onChange={(e) => { setTransactionType(e.target.value) }}>
                                                <option value="deposit">Deposit</option>
                                                <option value="withdraw">Withdraw</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={12} className="mb-3">
                                        <div className="border border-1 border-white rounded-3 bg-primary-2 overflow-hidden">
                                            <Table className="TransactionTable mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="bg-transparent border-white">Type</th>
                                                        <th className="bg-transparent border-white">Amount</th>
                                                        <th className="bg-transparent border-white">time</th>
                                                        <th className="bg-transparent border-white">Status</th>
                                                    </tr>
                                                </thead>
                                                {transactionType === 'deposit' && (
                                                    <tbody>
                                                        {depositHistory?.map((row, index) => (
                                                            <tr key={index}>
                                                                <td className="bg-transparent border-white align-middle">deposit</td>
                                                                <td className="bg-transparent border-white align-middle">
                                                                    <span className="d-flex align-items-center">
                                                                        {/* <Image src={row.amount.icon} width={30} className="me-2" alt="coin" /> */}
                                                                        <span>{row.amount}</span>
                                                                    </span>
                                                                </td>
                                                                <td className="bg-transparent border-white align-middle">{row.date}</td>
                                                                <td className="bg-transparent border-white align-middle">
                                                                    {row.status === '0' ? (
                                                                        <span className="d-flex align-items-center">
                                                                            <span className="text-secondary-1 me-2">🔴</span>
                                                                            <span>Failed</span>
                                                                        </span>
                                                                    ) : (
                                                                        <span className="d-flex align-items-center">
                                                                            <span className="text-secondary-1 me-2">🟢</span>
                                                                            <span>Success</span>
                                                                        </span>
                                                                    )}

                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                )}
                                                {transactionType === 'withdraw' && (
                                                    <tbody>
                                                        {withdrawHistory?.map((row, index) => (
                                                            <tr key={index}>
                                                                <td className="bg-transparent border-white align-middle">withdraw</td>
                                                                <td className="bg-transparent border-white align-middle">
                                                                    <span className="d-flex align-items-center">
                                                                        {/* <Image src={row.amount.icon} width={30} className="me-2" alt="coin" /> */}
                                                                        <span>{row.amount}</span>
                                                                    </span>
                                                                </td>
                                                                <td className="bg-transparent border-white align-middle">{row.date}</td>
                                                                <td className="bg-transparent border-white align-middle">
                                                                    {(row.status === 'TO PAID' || row.status === 'TO VALIDATE')  && (
                                                                        <span className="d-flex align-items-center">
                                                                            <span className="text-secondary-1 me-2">🟡</span>
                                                                            <span>Processing</span>
                                                                        </span>
                                                                    )}
                                                                    {row.status === 'PAID' && (
                                                                        <span className="d-flex align-items-center">
                                                                            <span className="text-secondary-1 me-2">🟢</span>
                                                                            <span>Success</span>
                                                                        </span>
                                                                    )}
                                                                    {row.status === 'SUSPEND' && (
                                                                        <span className="d-flex align-items-center">
                                                                            <span className="text-secondary-1 me-2">🔴</span>
                                                                            <span>Failed</span>
                                                                        </span>
                                                                    )}

                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                )}
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default SettingPage;