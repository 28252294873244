import React from "react";
import { Card, Container, Tabs, Tab, Image, InputGroup, Form } from "react-bootstrap";
import { LatestBetTable } from "../../../components/tables/tables";
import { CrashGameCardCarousel } from "../../../components/carousels/multiItemsCarousel";
import { GameIcon, SearchIcon } from "../../../assets";

const OriginalGamePage: React.FunctionComponent = () => {
    return (
        <div className="GamePage">
            <Container fluid>
                <Card className="border-white bg-primary-2 mb-3">
                    <Card.Body>
                        <InputGroup className="mb-3 rounded-5 border border-white overflow-hidden">
                            <InputGroup.Text className="bg-primary-1 border-0"><Image src={SearchIcon} width={20} alt="icon"/><span className="ms-3">|</span></InputGroup.Text>
                            <Form.Control
                                className="bg-primary-1 text-white border-0"
                                placeholder="Search your game"
                            />
                        </InputGroup>
                        <div className="d-flex align-items-center mb-3 section-sub-title">
                            <Image src={GameIcon} width={25} className="me-2"/><span>Bitnswap originals</span>
                        </div>
                        <CrashGameCardCarousel />
                    </Card.Body>
                </Card>
                <Tabs
                    defaultActiveKey="active_trade"
                    id="uncontrolled-tab-example"
                    className="mb-3 border-0"
                >
                    <Tab eventKey="active_trade" title="BET HISTORY">
                        <LatestBetTable />
                    </Tab>
                </Tabs>
            </Container>
        </div>
    )
}

export default OriginalGamePage;