import React from "react";
import { Card, Container } from "react-bootstrap";

const LimboGame: React.FunctionComponent = () => {

    

    return (
        <div className="AviatorGame">
            <Container fluid>
                <Card className="bg-primary-2 border-white">
                    <Card.Body>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default LimboGame;