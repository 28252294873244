import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, InputGroup, Row } from "react-bootstrap";
import './style.css';
import TopLayout from '../../../../assets/images/games/degen/top.svg';
import SettingBtn from '../../../../assets/images/games/degen/setting-btn.svg';
import MusicBtn from '../../../../assets/images/games/degen/music-btn.svg';
import InfoBtn from '../../../../assets/images/games/degen/info-btn.svg';
import SpinBtn from '../../../../assets/images/games/degen/spin-btn.svg';
import MinusnBtn from '../../../../assets/images/games/degen/minus-btn.svg';
import PlusBtn from '../../../../assets/images/games/degen/plus-btn.svg';
import { DegenSlotData } from "../../../../utils/data";
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';


const DegenSlotGame: React.FunctionComponent = () => {

    const [bet, setBet] = useState<number>(1);
    const [coin, setCoin] = useState<number>(1);
    const [balance, setBalance] = useState<number>(100);
    const [winAmount, setWinAmount] = useState<number>(0);

    const items = gsap.utils.toArray('.item');
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const random1 = gsap.utils.random(-360, 360, 36);
        const random2 = gsap.utils.random(-360, 360, 36);
        const random3 = gsap.utils.random(-360, 360, 36);
        const random4 = gsap.utils.random(-360, 360, 36);
        const random5 = gsap.utils.random(-360, 360, 36);

        gsap
            .timeline({ onComplete: finishScroll })
            .set('.ring', { rotationX: -90 })
            .set('.item', {
                rotateX: (i) => i * -36,
                transformOrigin: '50% 50% -220px',
                z: 220,
            })
            .to('#ring1', { rotationX: random1, duration: 1, ease: 'power3' }, '<=')
            .to('#ring2', { rotationX: random2, duration: 1.5, ease: 'power3' }, '<=')
            .to('#ring3', { rotationX: random3, duration: 2, ease: 'power3' }, '<=')
            .to('#ring4', { rotationX: random4, duration: 2.5, ease: 'power3' }, '<=')
            .to('#ring5', { rotationX: random5, duration: 3, ease: 'power3' }, '<=')

        finishScroll();
    }, []);



    function finishScroll() {
        items.forEach((item, i) => {
            const trigger = item as gsap.DOMTarget;
            ScrollTrigger.create({
                trigger: trigger,
                scroller: '.console-outer',
                start: 'top center-=59px',
                end: 'bottom center-=59px',
                toggleClass: 'active',
            });
        });

        const activeitem1 = document.getElementById('col1')?.querySelector('.item.active')?.getAttribute('data-content');
        const activeitem2 = document.getElementById('col2')?.querySelector('.item.active')?.getAttribute('data-content');
        const activeitem3 = document.getElementById('col3')?.querySelector('.item.active')?.getAttribute('data-content');
        const activeitem4 = document.getElementById('col4')?.querySelector('.item.active')?.getAttribute('data-content');
        const activeitem5 = document.getElementById('col5')?.querySelector('.item.active')?.getAttribute('data-content');

        if (document.querySelector('.notstarted')) {
            // Do nothing
        } else {
            if (activeitem1 === activeitem2 && activeitem2 === activeitem3 && activeitem3 === activeitem4 && activeitem4 === activeitem5) {
                alert(`You won, woohoo! Everyone gets ${activeitem1}s!`);
            } else if (activeitem1 !== activeitem2 && activeitem2 !== activeitem3 && activeitem1 !== activeitem3) {
                alert(`Bad luck, you lost`);
            } else if (activeitem1 === activeitem2 && activeitem1 !== activeitem3 && activeitem2 !== activeitem3) {
                alert(`Close but no ${activeitem1}s for you. Why not try again?`);
            } else if (activeitem1 === activeitem3 && activeitem1 !== activeitem2 && activeitem3 !== activeitem2) {
                alert(`Close but no ${activeitem1}s for you. Why not try again?`);
            } else if (activeitem2 === activeitem3 && activeitem1 !== activeitem3 && activeitem1 !== activeitem2) {
                alert(`Close but no ${activeitem3}s for you. Why not try again?`);
            }
        }
    }

    const play = () => {
        setBalance(balance - coin);
        if (bet > 1) {
            setBet(bet - 1);
        }
        const random1 = gsap.utils.random(-1440, 1440, 36);
        const random2 = gsap.utils.random(-1440, 1440, 36);
        const random3 = gsap.utils.random(-1440, 1440, 36);
        const random4 = gsap.utils.random(-1440, 1440, 36);
        const random5 = gsap.utils.random(-1440, 1440, 36);

        const scrollcells = gsap.timeline({ paused: true, onComplete: finishScroll });

        scrollcells
            .to('#ring1:not(.held)', { rotationX: random1, duration: 2, ease: 'power3' }, '<')
            .to('#ring2:not(.held)', { rotationX: random2, duration: 3, ease: 'power3' }, '<')
            .to('#ring3:not(.held)', { rotationX: random3, duration: 4, ease: 'power3' }, '<')
            .to('#ring4:not(.held)', { rotationX: random4, duration: 4, ease: 'power3' }, '<')
            .to('#ring5:not(.held)', { rotationX: random5, duration: 4, ease: 'power3' }, '<')

        scrollcells.play();
    }

    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

    async function adonis(count: number, ms: number) {
        for (let i = 0; i < count; i++) {
            await delay(ms);
            play()
        }
    }

    const spin = async () => {
        if ((bet * coin) > balance) {
            alert("Don't enough balance");
            return
        }

        if (bet > 1) {
            await adonis(bet, 2000);
        }
        else {
            await adonis(bet, 0);
        }


    }

    const plusBtn = (type: string) => {
        // debugger
        if (type === "BET") {
            setBet(bet + 1)
            return
        }

        if (type === "COIN") {
            setCoin(coin + 1)
            return
        }

    }

    const minuseBtn = (type: string) => {
        if (type === "BET") {
            setBet(bet - 1)
            return
        }

        if (type === "COIN") {
            setCoin(coin - 1)
            return
        }
    }

    return (
        <div className="DegenSlotGame">
            <Container fluid>
                <Card className="bg-primary-2 border-white">
                    <Card.Body>
                        <Card className="game">
                            <div className="game-body text-center">
                                <Row>
                                    <Col xs={12} md={8} className="mb-3 mx-auto text-center">
                                        <Image src={TopLayout} width="60%" alt="top" className="mb-3" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={2} className="mb-3">
                                        <Row>
                                            <Col xs={4} md={12}><Button className="bg-transparent border-0 mb-md-3 "><Image src={SettingBtn} width={60} alt="btn" /></Button></Col>
                                            <Col xs={4} md={12}><Button className="bg-transparent border-0 mb-md-3 "><Image src={MusicBtn} width={60} alt="btn" /></Button></Col>
                                            <Col xs={4} md={12}><Button className="bg-transparent border-0 mb-md-3 "><Image src={InfoBtn} width={60} alt="btn" /></Button></Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={8} className="mb-5 text-center">
                                        <Card className="mx-2 slot-body">
                                            <div className="stage notstarted">
                                                <div className="row console">
                                                    <div className="console-outer m-auto">
                                                        <div id="col1" className="col third wheel">
                                                            <div className="container">
                                                                <ul id="ring1" className="ring">
                                                                    {DegenSlotData.map((item, index) => (
                                                                        <li key={index} data-content={item.content} className="item border-white border-2 rounded-3 text-center"><Image src={item.image} width="100%" alt="nft" className="object-fit-cover" /></li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div id="col2" className="col third wheel">
                                                            <div className="container">
                                                                <ul id="ring2" className="ring">
                                                                    {DegenSlotData.map((item, index) => (
                                                                        <li key={index} data-content={item.content} className="item border-white border-2 rounded-3 text-center"><Image src={item.image} width="100%" alt="nft" className="object-fit-cover" /></li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div id="col3" className="col third wheel">
                                                            <div className="container">
                                                                <ul id="ring3" className="ring">
                                                                    {DegenSlotData.map((item, index) => (
                                                                        <li key={index} data-content={item.content} className="item border-white border-2 rounded-3 text-center"><Image src={item.image} width="100%" alt="nft" className="object-fit-cover" /></li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div id="col4" className="col third wheel">
                                                            <div className="container">
                                                                <ul id="ring4" className="ring">
                                                                    {DegenSlotData.map((item, index) => (
                                                                        <li key={index} data-content={item.content} className="item border-white border-2 rounded-3 text-center"><Image src={item.image} width="100%" alt="nft" className="object-fit-cover" /></li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div id="col5" className="col third wheel">
                                                            <div className="container">
                                                                <ul id="ring5" className="ring">
                                                                    {DegenSlotData.map((item, index) => (
                                                                        <li key={index} data-content={item.content} className="item border-white border-2 rounded-3 text-center"><Image src={item.image} width="100%" alt="nft" className="object-fit-cover" /></li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                                <div className="controllers">
                                    <Row className="align-items-end">
                                        <Col xs={12} lg={2} className="py-2 balance-info">
                                            <div className="text-md-start text-center mb-3 px-3">WIN :</div>
                                            <div className="text-md-start text-center mb-3 px-3">{winAmount}</div>
                                        </Col>
                                        <Col xs={12} lg={3} className="py-2">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>BET</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Button className="border-0 bg-transparent" onClick={() => minuseBtn('BET')}>
                                                        <Image src={MinusnBtn} width={50} alt="btn" />
                                                    </Button>
                                                    <Form.Control
                                                        className="rounded-4 border-white text-center"
                                                        type="number"
                                                        value={bet}
                                                        onChange={(e) => setBet(parseInt(e.target.value))}
                                                    />
                                                    <Button className="border-0 bg-transparent" onClick={() => plusBtn('BET')}>
                                                        <Image src={PlusBtn} width={50} alt="btn" />
                                                    </Button>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={2} className="py-2"><Button className="bg-transparent border-0" onClick={spin}><Image src={SpinBtn} width={120} alt="spin" /></Button></Col>
                                        <Col xs={12} lg={3} className="py-2">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>COIN</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Button className="border-0 bg-transparent" onClick={() => minuseBtn('COIN')}>
                                                        <Image src={MinusnBtn} width={50} alt="btn" />
                                                    </Button>
                                                    <Form.Control
                                                        className="rounded-4 border-white text-center"
                                                        type="number"
                                                        value={coin}
                                                        onChange={(e) => setCoin(parseInt(e.target.value))}
                                                    />
                                                    <Button className="border-0 bg-transparent" onClick={() => setCoin(coin + 1)}>
                                                        <Image src={PlusBtn} width={50} alt="btn" />
                                                    </Button>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={2} className="py-2 balance-info">
                                            <div className="text-md-end text-center mb-3 px-3">BALANCE :</div>
                                            <div className="text-md-end text-center mb-3 px-3">{balance}</div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default DegenSlotGame;