import React, { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { socket } from "../utils/socket";
import { useConnectWallet } from "@web3-onboard/react";

interface MainContextProps {
    signupModal: boolean;
    signinModal: boolean;
    forgetPasswordModal: boolean;
    freeSpinModal: boolean;
    depositModal: boolean;
    isLoading: "flex" | "none";
    balance: {
        USD: string;
        xBSP: string;
        BSP:string;
    };
    provider: string;
    profile: any;
    refId: string;
    userInfo: any;
    setSignupModal: Dispatch<SetStateAction<boolean>>;
    setSigninModal: Dispatch<SetStateAction<boolean>>;
    setForgetPasswordModal: Dispatch<SetStateAction<boolean>>;
    setFreeSpinModal: Dispatch<SetStateAction<boolean>>;
    setDepositModal: Dispatch<SetStateAction<boolean>>;
    setBalance: Dispatch<SetStateAction<{"USD":string,"xBSP":string, "BSP":string}>>;
    setProvider: Dispatch<SetStateAction<string>>;
    setProfile: Dispatch<SetStateAction<any>>;
    setRefId: Dispatch<SetStateAction<string>>;
    setUserInfo: Dispatch<SetStateAction<any>>;
    setIsLoading: Dispatch<SetStateAction<"flex" | "none">>;
    userSignIn: Function;
    userSignOut: Function;
    getBalance: Function;
    userSocialSignIn: Function;
    token: string;
    setToken: Dispatch<SetStateAction<string>>;
}


const MainContext = createContext<MainContextProps | undefined>(undefined);

export const MainContextProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const [signupModal, setSignupModal] = useState<boolean>(false);
    const [signinModal, setSigninModal] = useState<boolean>(false);
    const [forgetPasswordModal, setForgetPasswordModal] = useState<boolean>(false);
    const [freeSpinModal, setFreeSpinModal] = useState<boolean>(false);
    const [depositModal, setDepositModal] = useState<boolean>(false);
    const [balance, setBalance] = useState<{"USD":string,"xBSP":string, "BSP":string}>({"USD":"0.00","xBSP":"0.00", "BSP":"0.00"});
    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState<any>();
    const [refId, setRefId] = useState<string | "">("");
    const [isLoading, setIsLoading] = useState<"flex" | "none">("flex");
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

    const navigate = useNavigate();

    const [token, setToken] = useState<string>(window.localStorage.getItem("token") as string);
    const [userInfo, setUserInfo] = useState<any>(JSON.parse(window.localStorage.getItem("user") as any));

    let linkData: any;

    let link = window.location.href;
    let url = new URL(link);
    let params = new URLSearchParams(url.search);
    let ref_id = params.get('ref_id');
    let campaignId = params.get('campaign_id');
    let param1 = params.get('param1');
    let param2 = params.get('param2');
    let param3 = params.get('param3');

    if (ref_id !== null || campaignId !== null) {
        const linkParams = {
            ref_id: ref_id,
            campaignId: campaignId,
            param1: param1,
            param2: param2,
            param3: param3
        }

        window.localStorage.setItem('linkParams', JSON.stringify(linkParams))
        linkData = linkParams;
    }

    const _linkParams = JSON.parse(window.localStorage.getItem('linkParams')!);
    if (_linkParams?.ref_id !== "" && _linkParams?.ref_id !== null) {
        linkData = _linkParams;
    }


    useEffect(() => {
        // if (!token) {
        //     navigate("/");
        //     return
        // }

        socket.on('show-error-message', function(data) {
            console.log('show-error-message');
            toast.warn(data.error_message);
        });

        socket.on('load-balances', function(data: any) {
            console.log('load-balances', data);
        });

        return () => {
            socket.off("show-error-message");
            socket.off("load-balances");
        };

    }, []);

    const userSignIn = (token: string, information: any) => {
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('user', JSON.stringify(information))
        setToken(token);
        setUserInfo(information);
        window.location.reload();
    }

    const userSignOut = async () => {
        console.log(wallet, "------", connecting)
        if(!wallet) {
            console.log(wallet, "------", connecting)            
        }
        const headers = {
            Authorization: token,
            token: token
        }

        const body = {}

        await axios.post(`${process.env.REACT_APP_API_URL}/client/auth/logout/`, body, { headers })
            .then(function (response) {
                if (response.data.error_code === 0 && response.data.logout === true) {
                    toast.success("Thank you for using our platform.");
                    window.localStorage.removeItem('token');
                    window.localStorage.removeItem('user');
                    setProfile(null);
                    setProvider('');
                    setUserInfo(null);
                    setToken("");                    
                    navigate("/");
                    window.location.reload();
                }
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const getBalance = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'token': token,
            'Authorization': token
        }
        await axios.get(`${process.env.REACT_APP_API_URL}/client/balance/`, { headers })
            .then(function (response) {
                if (response.data.error_code === 5004) {
                    window.localStorage.removeItem('token');
                    window.localStorage.removeItem('user');
                    setProfile(null);
                    setProvider('');
                    setUserInfo(null);
                    setToken("");                    
                    return;
                }
                console.log("setBalance(response.data.balances)", response.data.balances)
                setBalance(response.data.balances)
            })
            .catch(function (error) {
                console.log("get account solde", error);
            });
    }

    const userSocialSignIn = async ({ provider, email, firstName, lastName }: { provider: string, email: string, firstName: string, lastName: string }) => {
        const body = {
            provider: provider,
            email: email,
            firstName: firstName,
            lastName: lastName,
            ref_id: ref_id === null ? linkData?.ref_id : ref_id,
            campaign_id: campaignId === null ? linkData?.campaignId : campaignId,
            param1: param1 === null ? linkData?.param1 : param1,
            param2: param2 === null ? linkData?.param2 : param2,
            param3: param3 === null ? linkData?.param3 : param3,
        }


        await axios.post(`${process.env.REACT_APP_API_URL}/client/social-auth/`, body)
            .then(function (response) {
                if (response.data.error_code === 0) {
                    userSignIn(response.data.token, response.data.information);
                    setBalance(response.data.balances.USD);
                    setRefId(response.data.information.id_for_referal);
                    setSigninModal(false);
                    setSignupModal(false)
                    toast.success("Welcome to BitNSwap!")
                }
                else {
                    toast.error(response.data.error_message)
                    return
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const contextValue: MainContextProps = {
        signupModal,
        signinModal,
        forgetPasswordModal,
        freeSpinModal,
        balance,
        provider,
        profile,
        refId,
        userInfo,
        isLoading,
        setSignupModal,
        setSigninModal,
        setForgetPasswordModal,
        setFreeSpinModal,
        setProvider,
        setProfile,
        setRefId,
        setUserInfo,
        setIsLoading,
        depositModal,
        userSocialSignIn,
        setBalance,
        setDepositModal,
        userSignIn,
        userSignOut,
        getBalance,
        token,
        setToken
    }

    return <MainContext.Provider value={contextValue}>{children}</MainContext.Provider>
}

export const useMainContext = () => {
    const context = useContext(MainContext);
    if (!context) {
        throw new Error('MainContext must be used within a MainContextProvider');
    }
    return context;
}